import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { BehaviorSubject, Subscription } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { FormControl } from "@angular/forms";
import { E2eLocators } from "@onsip/e2e/configs/locators";
import { views } from "@onsip/web/app/phone/views";

export interface ShellNavigation {
  name: string;
  url?: string;
  nestedUrls?: Array<ShellNavigationWithLocator>;
}

export interface ShellNavigationWithLocator extends ShellNavigation {
  cyLocator?: E2eLocators;
  nestedUrls?: Array<ShellNavigationWithLocator>;
}

@Component({
  selector: "onsip-shell-navigations",
  templateUrl: "./shell-navigations.component.html",
  styleUrls: ["./shell-navigations.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ShellNavigationsComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private cdRef: ChangeDetectorRef
  ) {}
  @Input() shellNavigations!: Array<ShellNavigationWithLocator>;
  @Input() useMobileView = false;
  @Input() horizontalNav = false;
  E2eLocators = E2eLocators;
  views = views;
  inkBarTop = "translateY(0px)";
  currentView = new FormControl("", { nonNullable: true });
  isMobile = false;
  rowMap = new Map();
  /** we are navigating outside of the possible navigation set in the shell */
  outsideNav = new BehaviorSubject<boolean>(false);
  /** list of possible navigation routes */
  currentNavs!: Array<string>;
  private unsubscriber = new Subscription();

  linkStyle = {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%"
  };

  ngOnInit(): void {
    this.currentNavs = this.shellNavigations.filter(nav => nav.url).map(nav => nav.url as string);

    this.unsubscriber.add(
      this.breakpointObserver.observe(Breakpoints.XSmall).subscribe(result => {
        this.isMobile = result.matches;
        this.cdRef.markForCheck();
        setTimeout(() => {
          this.checkActiveView();
        }, 200);
      })
    );
    this.checkActiveView();
  }

  ngAfterViewInit(): void {
    this.unsubscriber.add(
      this.router.events
        .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
        .subscribe(() => this.checkActiveView())
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  toggleRow(navName: string): void {
    const navValue = this.rowMap.get(navName);
    if (navValue) {
      this.rowMap.set(navName, !navValue);
    } else {
      this.rowMap.set(navName, true);
    }
  }

  isActiveLink(currentView: string, linkUrl: string): boolean {
    return currentView === linkUrl;
  }

  navigateTo(url: string) {
    this.router.navigate([url]);
  }

  private checkActiveView(): void {
    const currentRoute = this.router.url.replace(/[?].*/, "");
    // current route can be deeper than the selectable nav links for example detailed pages. We still want those page to link to
    // the parent routes so looping through all the possible navs and see if any of those navs match the current route.
    let validCurrentNav = "";
    this.currentNavs.forEach(currentNav => {
      if (currentRoute.includes(currentNav)) {
        validCurrentNav = currentNav;
      }
    });
    if (validCurrentNav) {
      this.outsideNav.next(false);
      this.currentView.setValue(validCurrentNav);
      const el = document.getElementById(this.currentView.value);
      if (el) {
        this.inkBarTop = `translateY(${el.offsetTop}px)`;
        this.cdRef.markForCheck();
      }
    } else {
      const nestedNavs = this.shellNavigations.filter(nav => nav.nestedUrls);
      const activeNestedNav = nestedNavs.find(nav =>
        JSON.stringify(nav.nestedUrls).includes(currentRoute)
      );
      if (activeNestedNav?.name) this.rowMap.set(activeNestedNav.name, true);
      this.outsideNav.next(true);
    }
  }
}
