import { Component, HostBinding } from "@angular/core";

/**
 * a simple flex spacer component to avoid having duplicated css everywhere for a basic utility
 */
@Component({
  selector: "onsip-spacer",
  template: ``, // supposed to be empty
  standalone: false
})
export class SpacerComponent {
  @HostBinding("style.flex-grow") grow = "1";
  @HostBinding("style.flex-shrink") shrink = "0";
  @HostBinding("style.flex-basis") basis = "auto";
}
