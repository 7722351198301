import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
@Component({
  selector: "onsip-header-action-bar",
  templateUrl: "header-action-bar.component.html",
  styleUrls: ["header-action-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class HeaderActionBarComponent {}
