import { Component, Input, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

import { LogService } from "../../../common/services/logging";
import { CallAudioService } from "./call-audio.service";
import { AnalyticsService } from "../shared/components/analytics/analytics.service";
import { WebAudioService } from "../shared/services/webAudio/web-audio.service";
import { UserAgentService } from "../shared/components/userAgent/user-agent.service";
import { CallGroupService } from "../shared/controller/call-group.service";
import { CallControllerService } from "../../../common/services/call-controller.service";
import { CallTimerService } from "../../../common/services/call-timer.service";
import { VolumeService } from "../shared/components/volume/volume.service";
import { CallVolumeEvent } from "../shared/components/volume/volume-event";
import { SnackbarService } from "../shared/components/snackbar/snackbar.service";
import { AcceptedCallService } from "./accepted-call.service";
import { HubspotInfoService } from "../shared/components/hubspot/services/hubspot-info.service";
import { ScreenShareService } from "./screen-share.service";
import { FirestoreCallService } from "../../../common/services/sayso/firestore-call.service";
import { TranslateService } from "@ngx-translate/core";

import { Contact as HubspotContact } from "../shared/components/hubspot/services/lib/contact";
import { views } from "../../app/phone/views";

import {
  EndCallEventReason,
  isEndCallEvent,
  isTransferFailedEvent
} from "../../../common/libraries/sip/call-event";
import { CallState } from "../../../common/libraries/sip/call-state";
import { InvalidTargetCallError } from "../../../common/libraries/sip/call-controller-error";
import { Call } from "../../../common/services/sayso/lib/call";

import { SipAddressInputComponent } from "../shared/components/newCall/input/sip-address-input.component";
import { ModalMaterialComponent } from "../shared/components/modal/modal-material.component";
import { DialpadService, DtmfEvent } from "../shared/components/dialpad/dialpad.service";

@Component({
  selector: "onsip-base-call",
  template: "template to be overridden",
  standalone: false
})
export class BaseCallComponent implements OnInit, OnDestroy {
  @ViewChild("transferTarget") transferTarget!: SipAddressInputComponent;
  @ViewChild("conferenceTarget") conferenceTarget!: SipAddressInputComponent;

  @Input() callUuid!: string;

  callFromDisplayName = "";

  canConference = false;
  isConferencing = false;
  isTransferring = false;
  dtmfHistory = "";
  transferTargetModel = "";
  transferError = false;
  conferenceTargetModel = "";
  displayStatus = "";
  saysoCallMessage = "";
  endCallReason: EndCallEventReason | undefined;
  currentCall: CallState | undefined;
  conferenceCall: CallState | undefined;
  transferCall: CallState | undefined;
  volumeIcon: "volume_off" | "volume_up" | "volume_down" = "volume_up";
  callTimerMs = 0;
  volumeClick = new Subject<void>();

  /** Sasyo Call from firestore call service */
  saysoCall: Call | undefined;
  /** Boolean to show saysoCallMessage only if first sayso call */
  showSaysoConnectingTimer = false;

  protected unsubscriber = new Subscription();

  private conferenceTransferFastPass = false;
  private videoAvailSub: Subscription | undefined;
  private hubspotInfoSub: Subscription | undefined;

  constructor(
    protected log: LogService,
    protected router: Router,
    protected callAudioService: CallAudioService,
    protected userAgentService: UserAgentService,
    protected callControllerService: CallControllerService,
    protected callTimerService: CallTimerService,
    protected acceptedCallService: AcceptedCallService,
    public screenShareService: ScreenShareService,
    private callGroupService: CallGroupService,
    private snackbarService: SnackbarService,
    private analyticsService: AnalyticsService,
    private webAudioService: WebAudioService,
    private volumeService: VolumeService,
    private hubspotInfoService: HubspotInfoService,
    private firestoreCallService: FirestoreCallService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    protected dialpadService: DialpadService
  ) {}

  ngOnInit(): void {
    this.canConference = this.webAudioService.hasCapableAudioContext();

    this.reset();

    this.unsubscriber.add(
      this.callGroupService.state.subscribe(() => {
        if (
          this.currentCall &&
          ((!this.conferenceCall &&
            this.callGroupService.findThreeWayConferencePartner(this.currentCall.uuid)) ||
            (!this.transferCall &&
              this.callGroupService.findAttendedTransferPartner(this.currentCall.uuid)))
        ) {
          this.updateCurrentCall();
        }
      })
    );

    this.unsubscriber.add(
      this.callControllerService
        .getCallEventObservable()
        .pipe(
          filter(event => isEndCallEvent(event) || isTransferFailedEvent(event)),
          filter(event => !!this.currentCall && event.uuid === this.currentCall.uuid)
        )
        .subscribe(event => {
          // end any screensharing if ending call
          if (this.currentCall && this.screenShareService.calls[this.currentCall.uuid]) {
            this.screenShareService.stopCapture();
          }
          if (isEndCallEvent(event)) {
            this.endCallReason = event.reason;
          } else if (isTransferFailedEvent(event)) {
            this.snackbarService.openSnackBar(
              "Transfer attempt failed: the number dialed may not exist",
              "error"
            );
          }
        })
    );

    this.unsubscriber.add(
      this.callControllerService.state.subscribe(() => this.updateCurrentCall())
    );

    this.unsubscriber.add(
      this.volumeService.state.subscribe(state => {
        this.volumeIcon =
          state.defaultVolume === 0
            ? "volume_off"
            : state.defaultVolume > 50
            ? "volume_up"
            : "volume_down";
      })
    );

    this.unsubscriber.add(
      this.volumeService.event
        .pipe(filter(event => event.id === CallVolumeEvent.id))
        .subscribe(event => {
          if (this.currentCall && event.uuid === this.currentCall.uuid) {
            this.volumeIcon =
              event.volume === 0 ? "volume_off" : event.volume > 50 ? "volume_up" : "volume_down";
          }
        })
    );

    this.unsubscriber.add(
      this.dialpadService.event
        .pipe(filter(event => event.target === "call"))
        .subscribe(event => this.onDtmfKey(event))
    );

    // set sayso call state
    if (this.currentCall && this.currentCall.xData) {
      this.saysoCall = this.firestoreCallService.getCall(this.currentCall.xData.slice(4));
    }

    this.initTimer();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  genericOnChanges(): void {
    if (this.currentCall && this.currentCall.uuid === this.callUuid) return;
    this.reset();
  }

  initTimer(): void {
    if (this.callTimerService.getCallTimerFromUuid(this.callUuid) !== undefined) {
      this.unsubscriber.add(
        this.callTimerService.getCallTimerFromUuid(this.callUuid).subscribe(val => {
          // Show timer / sayso status msg on call component only for first sayso call not failover, not teampage, also don't want to display before first tick
          this.showSaysoConnectingTimer =
            !!this.saysoCall?.stateValue.delay &&
            Object.keys(this.saysoCall.stateValue.sessions).length === 1;
          this.callTimerMs = val;
          this.updateDisplayStatus();
        })
      );
    }
  }

  // When volume button is clicked, emit event to onsip-volume-popup to mute/unmute
  emitEventToVolume() {
    this.volumeClick.next();
  }

  hold(): void {
    if (!this.currentCall) {
      return;
    }

    if (this.currentCall.hold) {
      this.callControllerService.makeUnheldCall(
        this.currentCall.uuid,
        this.conferenceCall ? this.conferenceCall.uuid : undefined
      );
    } else {
      this.callControllerService
        .holdCall(this.currentCall.uuid)
        .catch(() => this.snackbarService.openSnackBar("Could not hold the call.", "error"));
      if (this.conferenceCall) {
        this.callControllerService
          .holdCall(this.conferenceCall.uuid)
          .catch(() => this.snackbarService.openSnackBar("Could not hold the call.", "error"));
      }
    }
  }

  transferToConferencedCall(confCall: CallState): void {
    if (!this.currentCall) {
      return;
    }
    this.callControllerService.endCall(confCall.uuid);
    this.callControllerService.blindTransfer(this.currentCall.uuid, confCall.remoteUri);
  }

  startTransfer(): void {
    if (!!this.currentCall && !!this.conferenceCall) {
      if (this.conferenceTransferFastPass) {
        this.transferToConferencedCall(this.conferenceCall);
      } else {
        const params: any = {
          target: this.conferenceCall.remoteDisplayName || this.currentCall.remoteUri.slice(4),
          referee: this.currentCall.remoteDisplayName || this.currentCall.remoteUri.slice(4)
        };
        const modal = this.dialog.open(ModalMaterialComponent, {
          panelClass: ["mat-typography", "onsip-dialog-universal-style"],
          data: {
            title: "Transfer Call?",
            message:
              "Do you want to blind transfer <b>" +
              params.target +
              "</b> to <b>" +
              params.referee +
              "</b>?",
            showFastPass: true,
            primaryBtnText: "TRANSFER",
            primaryBtnFlat: true
          }
        });

        this.unsubscriber.add(
          modal
            .afterClosed()
            .pipe(take(1))
            .subscribe(response => {
              if (response && response.doPrimaryAction && !!this.conferenceCall) {
                this.transferToConferencedCall(this.conferenceCall);
                if (response.fastPassRequest) {
                  this.conferenceTransferFastPass = response.fastPass;
                }
              }
            })
        );
      }
    } else {
      this.isTransferring = true;
      this.isConferencing = false;
      setTimeout(() => this.transferTarget && this.transferTarget.focus());
    }
  }

  cancelTransfer(navigate: boolean = true): void {
    this.isTransferring = false;

    if (!this.transferCall || !this.currentCall) {
      return;
    }
    const secondCall: CallState =
        (this.currentCall.connectedAt || 0) > (this.transferCall.connectedAt || 0)
          ? this.currentCall
          : this.transferCall,
      firstCall: CallState =
        (this.currentCall.connectedAt || 0) < (this.transferCall.connectedAt || 0)
          ? this.currentCall
          : this.transferCall;

    if (firstCall && secondCall && secondCall.connected && !secondCall.hold) {
      this.callControllerService.makeUnheldCall(firstCall.uuid);
      if (navigate) {
        this.router.navigate([views.CALL], {
          state: {
            callUuid: firstCall.uuid
          }
        });
      }
    }
    secondCall && this.callControllerService.endCall(secondCall.uuid);
  }

  blindTransfer(): void {
    if (!this.transferTargetModel || this.transferTargetModel === "") {
      this.transferError = true;
      return;
    }
    this.transferError = false;

    if (this.currentCall) {
      this.callControllerService.blindTransfer(this.currentCall.uuid, this.transferTargetModel);
    }
    this.isTransferring = false;
    this.transferTargetModel = "";
  }

  attendedTransfer(): void {
    if (!this.transferTargetModel || this.transferTargetModel === "") {
      this.transferError = true;
      return;
    }

    if (!this.currentCall) {
      return;
    }

    // end any screensharing if doing attended call transfer
    this.unsubscriber.add(
      this.screenShareService.state
        .pipe(
          take(1),
          filter(state => !!state.hasScreenShare)
        )
        .subscribe(() => {
          this.screenShareService.stopCapture();
        })
    );

    this.callGroupService
      .createTransferCall(this.currentCall, this.transferTargetModel)
      .then(newCallUuid => {
        this.isTransferring = false;
        this.transferTargetModel = "";
        this.router.navigate([views.CALL], {
          state: {
            callUuid: newCallUuid
          }
        });
      })
      .catch(error => {
        this.transferError = true;

        if (error instanceof InvalidTargetCallError) {
          console.error("Bad target used");
        }
      });
  }

  completeAttendedTransfer(): void {
    if (!this.transferCall || !this.currentCall) {
      return;
    }

    const secondCall: CallState =
        (this.currentCall.connectedAt || 0) > (this.transferCall.connectedAt || 0)
          ? this.currentCall
          : this.transferCall,
      firstCall: CallState =
        (this.currentCall.connectedAt || 0) < (this.transferCall.connectedAt || 0)
          ? this.currentCall
          : this.transferCall;

    this.callControllerService.attendedTransfer(firstCall.uuid, secondCall.uuid);
  }

  startConference(): void {
    this.isConferencing = true;
    this.isTransferring = false;
    setTimeout(() => this.conferenceTarget.focus());
  }

  cancelConference(): void {
    this.isConferencing = false;
  }

  conference(): void {
    const model: string = this.conferenceTargetModel;

    if (!model || model.length === 0 || !this.currentCall) {
      /* TODO error states
      if (this.remoteUri) {
        throw "callComponent - dial: remote uri is invalid";
      } else {
        setTargerError("Invalid Format.");
      }
      return;
      */
      return;
    }

    this.callGroupService
      .createThreeWayConferenceCall(this.currentCall, model)
      .then(() => {
        this.isConferencing = false;
        this.conferenceTargetModel = "";
      })
      .catch(error => {
        this.log.error("callComponent conference:", error, this.conferenceTargetModel);
        this.snackbarService.openSnackBar(error.message, "error");
      });
  }

  muteAudio(): void {
    if (!this.currentCall) {
      return;
    }

    const action: string = (this.currentCall.audio ? "M" : "Unm") + "ute Audio";

    if (this.currentCall.audio) {
      this.callAudioService.localMute(this.currentCall.uuid);
    } else {
      this.callAudioService.localUnmute(this.currentCall.uuid);
    }

    this.log.debug("call audio mute set to", this.currentCall.audio);

    this.analyticsService.sendCallEvent(action, this.currentCall, {
      audioMuted: this.currentCall.audio,
      videoMuted: !this.currentCall.video
    });
  }

  muteVideo(mute: boolean): void {
    if (!this.currentCall) {
      return;
    }

    const action: string = (mute ? "M" : "Unm") + "ute Video";

    this.log.debug("call video set to", mute);
    this.callControllerService.setCallVideo(this.currentCall.uuid, !mute);

    this.analyticsService.sendCallEvent(action, this.currentCall, {
      audioMuted: this.currentCall.audio,
      videoMuted: mute
    });
  }

  endCall(): void {
    if (
      !this.currentCall ||
      this.acceptedCallService.hasConnectingAcceptedCall(this.currentCall.uuid)
    ) {
      return;
    }
    this.isTransferring = false;
    this.isConferencing = false;

    if (this.transferCall) {
      if ((this.currentCall.connectedAt || 0) < (this.transferCall.connectedAt || 0)) {
        this.callControllerService.endCall(this.transferCall.uuid);
      }
    }

    this.callControllerService.endCall(this.currentCall.uuid);
    this.snackbar.open("Call ended", "", {
      duration: 5000
    });
  }

  protected updateCurrentCall(): void {
    this.currentCall = this.callControllerService.getCallStateByUuid(this.callUuid);
    if (!this.currentCall) return;

    this.updateDisplayStatus();

    if (this.currentCall && this.currentCall.xData) {
      this.saysoCall = this.firestoreCallService.getCall(this.currentCall.xData.slice(4));
    } else {
      this.saysoCall = undefined; // not a sayso call
    }

    const audioInfo = this.callAudioService.findInfo(this.currentCall.uuid);
    if (audioInfo) {
      this.volumeIcon =
        audioInfo.volume === 0 ? "volume_off" : audioInfo.volume > 50 ? "volume_up" : "volume_down";
    }

    this.transferCall = this.callGroupService.findAttendedTransferPartner(this.currentCall.uuid);
    if (this.transferCall) {
      this.isTransferring = false;
    }

    if (this.hubspotInfoSub) {
      this.hubspotInfoSub.unsubscribe();
    }
    const hubspotContact = this.hubspotInfoService.fetchHubspotContact(this.currentCall.uuid);
    if (hubspotContact) {
      this.callFromDisplayName = hubspotContact.firstname + " " + hubspotContact.lastname;
    } else {
      this.callFromDisplayName =
        this.currentCall.remoteDisplayName ||
        this.parseRemoteUri(this.currentCall.remoteUri.slice(4));
      const hubspotSub = this.hubspotInfoService.state
        .pipe(
          filter(
            () =>
              !!this.currentCall &&
              !!this.hubspotInfoService.fetchHubspotContact(this.currentCall.uuid)
          ),
          take(1)
        )
        // eslint-disable-next-line rxjs-angular/prefer-composition
        .subscribe(() => {
          const contact: HubspotContact | undefined = this.currentCall
            ? this.hubspotInfoService.fetchHubspotContact(this.currentCall.uuid)
            : undefined;
          if (contact) {
            this.callFromDisplayName = contact.firstname + " " + contact.lastname;
          }
        });

      this.unsubscriber.add(hubspotSub);
      this.hubspotInfoSub = hubspotSub;
    }

    if (this.videoAvailSub) {
      this.videoAvailSub.unsubscribe();
    }
    if (!this.currentCall.videoAvailable) {
      const videoAvailObserver = this.callControllerService.getCallObservableOnVideoAvailableChange(
        this.callUuid
      );

      if (videoAvailObserver) {
        // eslint-disable-next-line rxjs-angular/prefer-composition
        const sub = videoAvailObserver.subscribe(isVideoAvailable => {
          if (isVideoAvailable) {
            this.updateCurrentCall();
          }
        });

        this.unsubscriber.add(sub);
        this.videoAvailSub = sub;
      }
    }
  }

  onDtmfKey(event: DtmfEvent): void {
    if (!!this.currentCall && this.currentCall.connected && !this.currentCall.hold) {
      if (event.type === "up") {
        this.callControllerService.playDTMF(this.currentCall.uuid, event.key);
        if (this.conferenceCall) {
          this.callControllerService.playDTMF(this.conferenceCall.uuid, event.key);
        }
        this.log.debug("dtmf sent to call. ", event, this.currentCall);
      }
    }
  }

  private updateDisplayStatus() {
    if (!this.currentCall) return;
    if (this.currentCall.connected && this.currentCall.xData && this.callTimerMs < 0) {
      this.displayStatus = this.translate.instant("ONSIP_I18N.CALL_CONNECTING");
    } else if (this.currentCall.hold && !this.currentCall.audio) {
      this.displayStatus = this.translate.instant("ONSIP_I18N.CALL_ON_HOLD__MUTED");
    } else if (this.currentCall.hold) {
      this.displayStatus = this.translate.instant("ONSIP_I18N.ON_HOLD");
    } else if (!this.currentCall.audio && this.currentCall.connected) {
      this.displayStatus = this.translate.instant("ONSIP_I18N.ACTIVE_CALL__MUTED");
    } else if (this.currentCall) {
      this.displayStatus = this.userAgentService.getDisplayStatus(this.currentCall.uuid);
    }

    this.conferenceCall = this.callGroupService.findThreeWayConferencePartner(
      this.currentCall.uuid
    );
    if (this.conferenceCall) {
      this.isConferencing = false;
      this.displayStatus = "conferencing";
    }
  }

  // when doing transfer calls, remoteUri can be get really ugly and simplying removing the sip: is not enough
  // with this parse, we pull out the aor from the uri
  private parseRemoteUri(uri: string): string {
    const matchUri = uri.match(/[\w\d.*]*@[\w\d.]*\.com/);
    return matchUri ? matchUri[0] : uri;
  }

  private reset(): void {
    this.volumeIcon = "volume_up";
    this.endCallReason = undefined;
    this.conferenceCall = undefined;

    this.isTransferring = false;
    this.transferError = false;
    this.isConferencing = false;

    this.transferTargetModel = "";
    this.conferenceTargetModel = "";
    this.dtmfHistory = "";
    this.displayStatus = "";

    this.updateCurrentCall();
  }
}
