import { Directive, HostListener, Input, Output, EventEmitter } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, FormArray } from "@angular/forms";

@Directive({
  selector: "[onsipFormSubmit]",
  standalone: false
})

/** Marks all child FormControls of given FormGroup as "Touched" on the host's click event
 *  If the input FormGroup is valid, emits a "valid" event
 *  ### Usage Example:
 *  #### Common method for form submission:
 *  ```html
 *  <button (click)=onSubmit()>Submit</button>
 *  ```
 *  #### Using OnsipFormSubmitDirective:
 *  ```html
 *  <button [onsipFormSubmit]="myFormGroup" (valid)=onSubmit()>Submit></button>
 *  ```
 */
export class OnsipFormSubmitDirective {
  /** Form Group being submitted / checked */
  @Input() onsipFormSubmit!: FormGroup;
  /** Event fires when form has been "touched" and FormGroup is valid */
  @Output() valid = new EventEmitter<void>();

  @HostListener("click")
  handleClick() {
    this.markFieldsAsTouched(this.onsipFormSubmit);
    this.emitIfValid();
  }

  /** Mark every FormControl in the FormGroup touched (including nested formgroup trees) */
  private markFieldsAsTouched(ac: AbstractControl) {
    if (ac instanceof FormControl) {
      ac.markAsTouched();
    }
    if (ac instanceof FormGroup) {
      Object.values(ac.controls).forEach(control => {
        this.markFieldsAsTouched(control);
      });
    }
    if (ac instanceof FormArray) {
      ac.controls.forEach(control => {
        this.markFieldsAsTouched(control);
      });
    }
  }

  /** emit event to host's (valid) if form is valid */
  private emitIfValid() {
    if (this.onsipFormSubmit.valid) {
      this.valid.emit();
    }
  }
}
