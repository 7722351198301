import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "underscoresToSpaces",
  standalone: false
})
export class UnderscoresToSpacesPipe implements PipeTransform {
  transform(str: any): any {
    if (typeof str === "string") {
      return str.replace(/_/g, " ");
    } else {
      return str;
    }
  }
}
