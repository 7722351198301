import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit
} from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { WarningBhrs, WarningModel } from "../../queue-warnings.model";
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { PipesModule } from "../../../../shared/pipes/pipes.module";
import { timezoneArray } from "../../../warnings/timeZones";
import { Subscription } from "rxjs";
import {
  AdvQueueObject,
  AdvQueueService
} from "@onsip/common/services/api/resources/queue/adv-queue.service";
import { AdvQueueWarningService } from "@onsip/common/services/api/resources/advQueueWarning/adv-queue-warning.service";
import { SnackbarService } from "@onsip/web/features/shared/components/snackbar/snackbar.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { QueueWarningsService } from "../../queue-warnings.service";

const DAYS = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

@Component({
  selector: "onsip-queue-warnings-edit",
  templateUrl: "./queue-warnings-edit.component.html",
  styleUrl: "./queue-warnings-edit.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCardModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    PipesModule
  ]
})
export class QueueWarningsEditComponent implements OnInit, OnDestroy {
  fb = inject(NonNullableFormBuilder);
  queueService = inject(AdvQueueService);
  advQueueWarningService = inject(AdvQueueWarningService);
  changeDetection = inject(ChangeDetectorRef);
  snackbarService = inject(SnackbarService);
  dialogRef = inject(MatDialogRef<QueueWarningsEditComponent>);
  warning = inject<WarningModel | undefined>(MAT_DIALOG_DATA);
  translate = inject(TranslateService);
  queueWarningsService = inject(QueueWarningsService);
  thresholdTypes: Array<string> = ["too_few_agents", "too_many_callers", "too_long_waiting"];
  thresholdPrefix: Record<string, string> = {
    too_few_agents: "there are fewer than",
    too_many_callers: "there are more than",
    too_long_waiting: "a caller has been waiting for"
  };
  thresholdSuffix: Record<string, string> = {
    too_few_agents: "agents logged in",
    too_many_callers: "callers waiting",
    too_long_waiting: "seconds"
  };
  timeZones = timezoneArray;
  queues!: Array<AdvQueueObject>;
  mode: "edit" | "create" = "create";
  private unsubscriber = new Subscription();

  warningForm = this.fb.group({
    queueId: ["", [Validators.required]],
    thresholdType: ["too_few_agents", [Validators.required]],
    thresholdValue: ["", [Validators.required]],
    alertDestination: ["", [Validators.required]],
    mon: [true],
    tue: [true],
    wed: [true],
    thu: [true],
    fri: [true],
    sat: [true],
    sun: [true],
    allhours: [false],
    monStartTime: ["09:00"],
    monEndTime: ["17:00"],
    tueStartTime: ["09:00"],
    tueEndTime: ["17:00"],
    wedStartTime: ["09:00"],
    wedEndTime: ["17:00"],
    thuStartTime: ["09:00"],
    thuEndTime: ["17:00"],
    friStartTime: ["09:00"],
    friEndTime: ["17:00"],
    satStartTime: ["09:00"],
    satEndTime: ["17:00"],
    sunStartTime: ["09:00"],
    sunEndTime: ["17:00"],
    timeZone: ["", [Validators.required]]
  });

  ngOnInit(): void {
    this.mode = this.warning ? "edit" : "create";
    if (this.warning) {
      const bhrFormData = this.convertBhrToFormData(this.warning.bhrData);

      this.warningForm.patchValue({
        queueId: this.warning.queueId,
        thresholdType: this.warning.thresholdType,
        thresholdValue: this.warning.thresholdValue,
        alertDestination: this.warning.alertDestination,
        ...bhrFormData
      });
    }
    this.handleFormChanges();
    this.getQueuesList();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  submit() {
    if (this.warningForm.valid) {
      const formValues = this.warningForm.value;
      if (this.mode === "edit" && this.warning) {
        const apiObject = {
          AdvQueueId: parseInt(formValues.queueId!),
          ThresholdType: formValues.thresholdType!,
          ThresholdValue: JSON.stringify({ Value: formValues.thresholdValue! }),
          AlertType: this.warning.alertType,
          AlertDestination: formValues.alertDestination!,
          CooldownSeconds: parseInt(this.warning.cooldownSeconds),
          AdvQueueWarningId: this.warning.warningId,
          WarningBhr: this.convertBhrToApiData(formValues)
        };

        this.advQueueWarningService.advQueueWarningEdit(apiObject).then(response => {
          if (response.status === "success") {
            this.queueWarningsService.editWarning(Object.values(response.data)[0]);
            this.snackbarService.openSnackBar(
              this.translate.instant("ONSIP_I18N.YOUR_CHANGES_HAVE_BEEN_SAVED"),
              "success"
            );
            this.dialogRef.close();
          } else {
            this.snackbarService.openSnackBar(response.data.message, "error");
          }
        });
      } else {
        const apiObject = {
          AdvQueueId: parseInt(formValues.queueId!),
          ThresholdType: formValues.thresholdType!,
          ThresholdValue: JSON.stringify({ Value: formValues.thresholdValue! }),
          AlertType: "email",
          AlertDestination: formValues.alertDestination!,
          CooldownSeconds: 15 * 60,
          WarningBhr: this.convertBhrToApiData(formValues)
        };

        this.advQueueWarningService.advQueueWarningAdd(apiObject).then(response => {
          if (response.status === "success") {
            this.queueWarningsService.addWarning(Object.values(response.data)[0]);
            this.snackbarService.openSnackBar("Queue alert has been added.", "success");
            this.dialogRef.close();
          } else {
            this.snackbarService.openSnackBar(response.data.message, "error");
          }
        });
      }
    }
  }

  handleDelete() {
    if (this.warning) {
      this.advQueueWarningService.advQueueWarningDelete(this.warning.warningId).then(response => {
        if (response.status === "success") {
          this.queueWarningsService.deleteWarning(Object.values(response.data)[0]);
          this.snackbarService.openSnackBar("Queue alert has been deleted.", "success");
          this.dialogRef.close();
        } else {
          this.snackbarService.openSnackBar(response.data.message, "error");
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  get submitButtonText() {
    return this.mode === "edit" ? "Update" : "Add";
  }

  private getQueuesList() {
    this.queueService.getSmartQueuesWithoutOrgId().then(queues => {
      this.queues = queues;
      this.changeDetection.markForCheck();
    });
  }

  private convertBhrToFormData(bhrData: WarningBhrs) {
    const formData: Record<string, boolean | string> = {
      mon: !!bhrData[0],
      tue: !!bhrData[1],
      wed: !!bhrData[2],
      thu: !!bhrData[3],
      fri: !!bhrData[4],
      sat: !!bhrData[5],
      sun: !!bhrData[6]
    };
    Object.keys(formData).forEach((day, i) => {
      if (formData[day]) {
        formData[`${day}StartTime`] = bhrData[i as keyof WarningBhrs][0];
        formData[`${day}EndTime`] = bhrData[i as keyof WarningBhrs][1];
      }
    });

    const isAllHours = Object.values(bhrData)
      .filter(bhr => !!bhr && Array.isArray(bhr))
      .every(bhrs => {
        const startTime = bhrs[0];
        const endTime = bhrs[1];
        return startTime === "00:00" && endTime === "23:59";
      });
    formData["allhours"] = isAllHours;

    formData["timeZone"] = bhrData.TimeZone;

    return formData;
  }

  private handleFormChanges() {
    DAYS.forEach(day => {
      this.unsubscriber.add(
        (this.warningForm.controls as any)[day].valueChanges.subscribe((value: boolean) => {
          if (value) {
            (this.warningForm.controls as any)[`${day}StartTime`].enable();
            (this.warningForm.controls as any)[`${day}EndTime`].enable();
            (this.warningForm.controls as any)[`${day}StartTime`].updateValueAndValidity({
              onlySelf: true
            });
            (this.warningForm.controls as any)[`${day}EndTime`].updateValueAndValidity({
              onlySelf: true
            });
          } else {
            (this.warningForm.controls as any)[`${day}StartTime`].disable();
            (this.warningForm.controls as any)[`${day}EndTime`].disable();
            (this.warningForm.controls as any)[`${day}StartTime`].updateValueAndValidity({
              onlySelf: true
            });
            (this.warningForm.controls as any)[`${day}EndTime`].updateValueAndValidity({
              onlySelf: true
            });
          }
        })
      );
    });
    this.unsubscriber.add(
      this.warningForm.controls.allhours.valueChanges.subscribe(value => {
        if (value) {
          DAYS.forEach(day => {
            (this.warningForm.controls as any)[`${day}StartTime`].patchValue("00:00");
            (this.warningForm.controls as any)[`${day}EndTime`].patchValue("23:59");
          });
        } else {
          DAYS.forEach(day => {
            (this.warningForm.controls as any)[`${day}StartTime`].reset();
            (this.warningForm.controls as any)[`${day}EndTime`].reset();
          });
        }
      })
    );
  }

  private convertBhrToApiData(formValues: Record<string, boolean | string>) {
    const bhrObject: Record<string, string | boolean | Array<string | boolean>> = {
      TimeZone: formValues.timeZone
    };
    DAYS.forEach((day, i) => {
      if (formValues[day]) {
        bhrObject[i] = [formValues[`${day}StartTime`], formValues[`${day}EndTime`]];
      }
    });
    return JSON.stringify(bhrObject);
  }
}
