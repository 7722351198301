import { Component, ChangeDetectionStrategy, Input } from "@angular/core";

@Component({
  selector: "onsip-avatar-frame",
  templateUrl: "./avatar-frame.component.html",
  styleUrls: ["./avatar-frame.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AvatarFrameComponent {
  @Input() hasBreakpoints = false;
}
