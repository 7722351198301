import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from "@angular/core";
import * as Highcharts from "highcharts";
// FIX: this was added because highcharts would throw a not defined error.
// https://stackoverflow.com/questions/57220324/fixing-uncaught-referenceerror-highcharts-is-not-defined-in-rails-app
window.Highcharts = Highcharts;

const ONSIP_COLORS = {
  Inbound: "#012AA5",
  Outbound: "#7A9BFF"
};

export interface LineGraphData {
  name: string;
  data: Array<GraphData>;
  color?: string;
}

interface GraphData {
  x: Date;
  y: number;
}

@Component({
  selector: "onsip-highcharts-line-graph",
  templateUrl: "./line-graph.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ChartingLineGraphComponent implements OnInit, OnChanges {
  @Input() data!: Array<LineGraphData>;
  @Input() title!: string;
  @Input() graphWidth = 500;
  @Input() graphHeight = 230;
  /** Required for highcharts-angular, bound to <highcharts-chart [Highcharts].../> */
  Highcharts = Highcharts;
  /** Highcharts master options object, update this for any changes to chart, e.g. new data
   *  bound to <highcharts-chart [options].../>
   */
  chartOptions!: Highcharts.Options;
  /** Callback Function sets this.chart, bound to <highcharts-chart [callbackFunction].../> */
  chartCallback!: Highcharts.ChartCallbackFunction;
  /** Reference to the Chart Object's "this", make this.chart the same as "this" from within chart's scope */
  chart: any;

  ngOnInit() {
    // Initialize a callback for the chart reference, this is important for dealing with any chart events
    const self = this;
    this.chartCallback = chart => (self.chart = chart); // saving chart reference
    this.setChartOptions();
  }

  ngOnChanges(): void {
    this.setChartOptions();
  }

  private addColorToData(): void {
    this.data = this.data.map(d => {
      const color = d.name === "Inbound" ? ONSIP_COLORS["Inbound"] : ONSIP_COLORS["Outbound"];
      return { ...d, color };
    });
  }

  private setChartOptions() {
    this.addColorToData();
    const seriesAction = this.data.map((data: LineGraphData): Highcharts.SeriesOptionsType => {
      return {
        type: "spline",
        color: data.color,
        data: data.data.map(data => ({
          y: data.y,
          x: data.x.getTime()
        })),
        name: data.name,
        marker: {
          fillColor: "transparent"
        }
      };
    });

    this.chartOptions = {
      chart: {
        backgroundColor: "transparent",
        style: {
          fontFamily: "Roboto",
          fontWeight: "400"
        },
        height: this.graphHeight,
        width: this.graphWidth
      },
      title: {
        text: ""
      },
      credits: { enabled: false },
      xAxis: {
        labels: {
          format: "{value:%m/%e}"
        },
        type: "datetime",
        minTickInterval: 1000 * 60 * 60 * 24
      },
      yAxis: {
        title: {
          text: ""
        }
      },
      plotOptions: {
        spline: {
          lineWidth: 4
        }
      },
      legend: {
        enabled: true,
        verticalAlign: "bottom",
        align: "center",
        layout: "horizontal",
        itemStyle: {
          fontWeight: "400"
        },
        symbolRadius: 0,
        symbolPadding: 0,
        symbolWidth: 0.1,
        useHTML: true,
        labelFormatter: function () {
          return labelStyle(this);
        }
      },
      tooltip: {
        xDateFormat: "%b %e, %Y",
        shared: true,
        borderColor: "transparent",
        backgroundColor: "white"
      },
      series: seriesAction
    };
  }
}

function labelStyle(point: any): string {
  return `
    <div>
      <span
        style="background-color: ${point.color};
        display: inline-block;
        height: 9px;
        width: 9px;
        margin-right: 5px;
        border-radius: 100%;"></span>
      <span>${point.name}</span>
    </div>
  `;
}
