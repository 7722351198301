import { Component, Inject, ChangeDetectionStrategy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { ImageUploadModalReturnData } from "../image-upload-interface";

// an optional param can be added if component allows use of non-saved avatar
export interface DialogData {
  currentAvatar?: string;
  useTempAvatar?: boolean;
}

// To use the upload modal component, you will need the following setup in your component:
// const dialogRef = this.dialog.open(ImageUploadModalComponent)
// To retrieve the image from this uploader, use the following code:
// dialogRef.afterClosed().pipe(take(1)).subscribe(response => {
//  do stuff with your response data
// })

@Component({
  selector: "onsip-avatar-upload-modal",
  templateUrl: "./avatar-upload-modal.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AvatarUploadModalComponent {
  hasTempAvatar = false;
  currentAvatar = "";
  avatarChange: ImageUploadModalReturnData = {
    doSave: false,
    image: undefined
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) data: DialogData,
    private dialogRef: MatDialogRef<AvatarUploadModalComponent>
  ) {
    if (data && data.currentAvatar) this.currentAvatar = data.currentAvatar;
    if (data && data.useTempAvatar) this.hasTempAvatar = data.useTempAvatar;
  }

  dialogResponse($event: ImageUploadModalReturnData): void {
    this.avatarChange = $event;
    this.dialogRef.close(this.avatarChange);
  }
}
