import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";
import { WarningModel } from "../../queue-warnings.model";
import { MatCardModule } from "@angular/material/card";
import { PipesModule } from "../../../../shared/pipes/pipes.module";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";
import { QueueWarningsEditComponent } from "../edit/queue-warnings-edit.component";

@Component({
  selector: "onsip-queue-warnings-view",
  templateUrl: "./queue-warnings-view.component.html",
  styleUrl: "./queue-warnings-view.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatCardModule, PipesModule, MatButtonModule]
})
export class QueueWarningsViewComponent {
  @Input() warning!: WarningModel;
  private dialog = inject(MatDialog);

  toggleEdit() {
    this.dialog.open(QueueWarningsEditComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"],
      data: this.warning
    });
  }

  formatBhr(bhr: WarningModel["bhrData"]): string {
    const indexOfWeek = {
      Mon: bhr[0],
      Tue: bhr[1],
      Wed: bhr[2],
      Thu: bhr[3],
      Fri: bhr[4],
      Sat: bhr[5],
      Sun: bhr[6]
    };
    const openedDays: Array<string> = [];
    const timeRanges: Array<string> = [];

    let skipDay = false;
    Object.entries(indexOfWeek).forEach(([date, value]) => {
      if (!value) {
        skipDay = true;
        return;
      }
      const timeRangeValue = this.getTimeRange(value);
      if (!skipDay && timeRanges[timeRanges.length - 1] === timeRangeValue) {
        const lastDate = openedDays[openedDays.length - 1];
        openedDays[openedDays.length - 1] = lastDate + "-" + date;
      } else {
        openedDays.push(date);
        timeRanges.push(timeRangeValue);
      }
      skipDay = false;
    });

    const cleanuUpOpenedDays = openedDays.map(days => {
      const dayArray = days.split("-");
      return dayArray.length === 1
        ? dayArray[0]
        : dayArray[0] + "-" + dayArray[dayArray.length - 1];
    });

    const daysWithTimeString = cleanuUpOpenedDays.map((days, i) => days + ": " + timeRanges[i]);
    return daysWithTimeString.join(", ");
  }

  private getTimeRange = (timeValue: Array<string>): string => {
    if (timeValue) {
      const startTime = timeValue[0];
      const endTime = timeValue[1];
      if (startTime === "00:00" && endTime === "23:59") {
        return "24 hrs";
      } else {
        return startTime + "-" + endTime;
      }
    }
    return "";
  };
}
