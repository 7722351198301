import { Component, ViewChild, ElementRef } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FormControl } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

import { LogService } from "../../../../../../common/services/logging";
import { prephoneViews } from "../prephone-views";
import { EntryFormErrorStateMatcher } from "../../entryPage/entry-form-error-state-matcher";
import { SendEmailService } from "@onsip/common/services/api/resources/send-email.service";

@Component({
  selector: "onsip-password-reset-request-form",
  templateUrl: "./password-reset-request-form.component.html",
  styleUrls: ["./password-reset-request-form.scss"],
  standalone: false
})
export class PasswordResetRequestFormComponent {
  @ViewChild("resetUsername") resetUsername!: ElementRef;

  prephoneViews: any = prephoneViews;
  loading = new BehaviorSubject<boolean>(false);
  requestComplete = new BehaviorSubject<boolean>(false);

  usernameControl: FormControl;
  usernameErrorStateMatcher: EntryFormErrorStateMatcher;

  constructor(
    private snackBar: MatSnackBar,
    private log: LogService,
    private sendEmailService: SendEmailService,
    private translate: TranslateService
  ) {
    this.usernameControl = new FormControl("");
    this.usernameErrorStateMatcher = new EntryFormErrorStateMatcher();
  }

  submit(event: any): void {
    event.preventDefault();

    const username: string = this.resetUsername.nativeElement.value.trim();
    let message = "";

    if (username.length === 0) {
      message = this.translate.instant("ONSIP_I18N.SIP_ADDRESS_IS_REQUIRED");
    } else if (!/\S+[@]\S+[.\S]+/i.test(username)) {
      message = this.translate.instant("ONSIP_I18N.SIP_ADDRESS_IS_NOT_VALID");
    }

    if (message.length > 0) {
      this.usernameControl.setErrors({
        invalid: true
      });
      this.snackBar.open(message, "", {
        duration: 7000
      });
      this.resetUsername.nativeElement.focus();

      return;
    }

    this.loading.next(true);

    this.sendEmailService.sendPasswordResetEmail(username, "app").then(result => {
      if (result.status === "success") {
        this.loading.next(false);
        this.requestComplete.next(true);
      } else {
        this.loading.next(false);
        this.log.debug(
          "passwordResetRequestFormComponent - adminAPI:SendPasswordResetEmail error",
          result.data
        );
        this.usernameControl.setErrors({
          invalid: true
        });
        this.snackBar.open(
          this.translate.instant("ONSIP_I18N.SIP_ADDRESS_NOT_FOUND_PLEASE_TRY_AGAIN"),
          "",
          {
            duration: 7000
          }
        );
        this.resetUsername.nativeElement.focus();
      }
    });
  }

  resetErrors(): void {
    if (this.usernameControl.invalid) {
      this.usernameControl.updateValueAndValidity();
    }
  }
}
