import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { QueueWarningsModalComponent } from "./modal/queue-warnings-modal.component";

@Component({
  selector: "onsip-queue-warnings-settings",
  templateUrl: "./queue-warnings-settings.component.html",
  imports: [MatButtonModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueueWarningsSettingsComponent {
  dialog = inject(MatDialog);

  openQueueWarningModal() {
    this.dialog.open(QueueWarningsModalComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"]
    });
  }
}
