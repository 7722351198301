import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { AccountService } from "@onsip/common/services/api/resources/account/account.service";
import { UserService } from "@onsip/common/services/api/resources/user/user.service";
import { getHighestRolePriority, Role } from "@onsip/common/services/api/role";
import { SuperAdminNavigationService } from "@onsip/common/services/super-admin-navigation.service";
import { views } from "@onsip/web/app/phone/views";
import { combineLatest, Observable, Subscription } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";

@Component({
  selector: "onsip-super-admin-toolbar",
  templateUrl: "./super-admin-toolbar.component.html",
  styleUrls: ["./super-admin-toolbar.component.scss"],
  standalone: false
})
export class SuperAdminToolbarComponent implements OnInit, OnDestroy {
  smallOrLessBreakpoint!: Observable<boolean>;
  displayRole!: string;
  orgName!: string;
  goBackText = "Back to Search";
  views = views;

  private highestRole!: Role;
  private unsubscriber = new Subscription();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private apiSessionService: ApiSessionService,
    private userService: UserService,
    private accountService: AccountService,
    private superAdminNavService: SuperAdminNavigationService
  ) {}

  ngOnInit(): void {
    this.smallOrLessBreakpoint = this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .pipe(map(value => value.matches));

    this.unsubscriber.add(
      combineLatest({
        accountState: this.accountService.state.pipe(map(state => Object.values(state.state))),
        apiSession: this.apiSessionService.state
      })
        .pipe(withLatestFrom(this.userService.state.pipe(map(state => Object.values(state.state)))))
        .subscribe(([{ accountState, apiSession }, userState]) => {
          const currentAccountId = apiSession.accountId;
          const parentUserId = apiSession.parentUserId;

          const currentAccount = accountState.find(
            account => account.accountId === currentAccountId
          );
          const parentUser = userState.find(user => user.userId === parentUserId);

          if (currentAccount) {
            this.orgName = currentAccount.contact.organization;
          }

          if (parentUser) {
            const roles = parentUser.roles;
            this.highestRole = getHighestRolePriority(roles);
            if (this.highestRole === Role.SuperUser) {
              this.displayRole = "Super Admin";
              this.goBackText = "Back to Search";
            } else if (
              this.highestRole === Role.AgentAdmin ||
              this.highestRole === Role.SubAgentAdmin
            ) {
              this.displayRole = "Agent";
              this.goBackText = "Back to Accounts";
            }
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  backToAdmin(): void {
    this.superAdminNavService.goBackToAdminViewNav();
  }
}
