import { Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy } from "@angular/core";

import { BehaviorSubject, Subscription } from "rxjs";

import { RealtimeCallerTableService } from "./realtime-caller-table.service";
import { QueueCaller } from "../queue-realtime-types.component";
import { PageEvent } from "@angular/material/paginator";
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from "@onsip/web/features/shared/const";

@Component({
  selector: "onsip-realtime-caller-table",
  templateUrl: "./realtime-caller-table.component.html",
  styleUrls: ["./realtime-caller-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class RealtimeCallerTableComponent implements OnInit, OnDestroy {
  @Input() callerData: Array<QueueCaller> = [];
  @Input() displayType = "waiting";

  pageSize = PAGE_SIZE;
  pageSizeOptions = PAGE_SIZE_OPTIONS;
  currentPage: BehaviorSubject<number> = new BehaviorSubject(0);
  activeCallerData: Array<QueueCaller> = [];
  openTable = new BehaviorSubject<boolean>(false);
  onTableHover = new BehaviorSubject<boolean>(false);
  private unsubscriber = new Subscription();

  constructor(private callerTableService: RealtimeCallerTableService) {}

  ngOnInit(): void {
    this.unsubscriber.add(
      this.callerTableService.state.subscribe(state => {
        if (state.callerTableOpenState === "open" && this.openTable) {
          this.openTable.next(false);
          this.onTableHover.next(false);
        }
      })
    );
    this.unsubscriber.add(
      this.currentPage.subscribe(current => {
        if (this.callerData.length > 0) {
          const startIdx = current * this.pageSize;
          const endIdx = startIdx + this.pageSize;
          this.activeCallerData = this.callerData.slice(startIdx, endIdx);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  onTriggerClick(): void {
    if (this.callerData.length > 0 && !this.openTable.value) {
      this.activeCallerData = this.callerData.slice(0, this.pageSize);
      this.callerTableService.openCallerTable();
      this.openTable.next(true);
    } else {
      this.openTable.next(false);
      this.onTableHover.next(false);
      this.callerTableService.closeCallerTable();
      this.currentPage.next(0);
    }
  }

  openTableHover(): void {
    if (this.callerData.length > 0 && !this.onTableHover.value) {
      this.onTableHover.next(true);
      if (!this.openTable.value) this.activeCallerData = this.callerData.slice(0, this.pageSize);
    }
  }

  closeTableHover(): void {
    this.onTableHover.next(false);
  }

  onPageChanged(e: PageEvent): void {
    this.pageSize = e.pageSize;
    this.currentPage.next(e.pageIndex);
  }
}
