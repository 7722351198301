import {
  Component,
  ViewChild,
  Input,
  ElementRef,
  OnInit,
  AfterViewInit,
  OnChanges,
  OnDestroy
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import { UserCustomizationService } from "../../../../../../common/services/api/resources/userCustomization/user-customization.service";

import { ChatService } from "../chat.service";
import { CurrentContactService } from "../../../../contact/current-contact.service";
import { SubscriptionControllerService } from "../../../../../../common/services/subscription-controller.service";

import { Contact } from "../../../../../../common/interfaces/contact";
import { Presentity, RegInfo } from "../../../../../../common/interfaces/presentity";
import { ChatBody } from "../factories/chat-body.service";
import { IdentityService } from "../../../../../../common/modules/core";

@Component({
  selector: "onsip-chat-window",
  templateUrl: "./chat-window.component.html",
  styleUrls: ["./chat-window.scss"],
  standalone: false
})
export class ChatWindowComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() contact: Contact | undefined;

  @ViewChild("chatConversation", { read: ElementRef }) chatConversation!: ElementRef;
  @ViewChild("newMessage") newMessage!: ElementRef;

  isChatEnabled = false;
  selfName!: string;
  myAvatar: string | undefined;
  messageLimit = 5;
  isContactTyping = false;
  isContactAway = false;
  chatBody: ChatBody | undefined;
  isOnsipEnabled = true;
  isProviderConnected = false;

  private isUserTyping = false;
  private chatChangeId = -1;
  private currentContact: Contact | undefined;
  private providerConnectedId = -1;
  private providerDisconnectedId = -1;
  private regSubscriptions: Array<Presentity> = [];
  private unsubscriber = new Subscription();

  constructor(
    private router: Router,
    private identity: IdentityService,
    private chatService: ChatService,
    private subscriptionControllerService: SubscriptionControllerService,
    private currentContactService: CurrentContactService,
    private userCustomizationService: UserCustomizationService
  ) {}

  ngOnInit(): void {
    this.unsubscriber.add(
      this.identity.state
        .pipe(filter(state => state.addresses.length > 0))
        .subscribe(state => (this.selfName = state.addresses[0].name))
    );

    this.unsubscriber.add(
      this.currentContactService.state.subscribe(c => (this.currentContact = c))
    );

    this.unsubscriber.add(
      this.subscriptionControllerService.state.subscribe(state => {
        this.regSubscriptions = state.presentity.filter(pres => pres.event === "reg");
        const wasReg = this.isChatEnabled;
        this.checkChatAbility();
        if (this.isChatEnabled && wasReg !== this.isChatEnabled) {
          this.loadChatBody();
        }
      })
    );

    this.unsubscriber.add(
      this.userCustomizationService.selfUser.subscribe(state => {
        this.myAvatar = state.userAvatarUrl;
      })
    );

    this.providerDisconnectedId = this.chatService.onProviderDisconnected(() => {
      this.isProviderConnected = false;
    });
  }

  ngAfterViewInit(): void {
    this.unsubscriber.add(
      this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
        if (this.currentContact === this.contact) {
          this.scrollToBottom();
        }
      })
    );

    this.unsubscriber.add(
      this.chatService.event
        .pipe(filter(e => e.chatBody && !!this.chatBody && e.chatBody.id === this.chatBody.id))
        .subscribe(() => this.scrollToBottom())
    );

    this.chatChangeId = this.chatService.onChange(() => {
      if (this.chatBody) {
        this.isContactTyping = this.chatBody.isUserTyping;
        this.isContactAway = this.chatBody.isUserAway;
        this.scrollToBottom();
      }
    });

    this.providerConnectedId = this.chatService.onProviderConnected(() => {
      this.isProviderConnected = true;
      if (this.chatBody) {
        this.loadChatBody();
        this.chatBody.unreadCount = 0;
      }
    });
  }

  ngOnChanges(changesObj: any): void {
    this.isProviderConnected = this.chatService.isProviderConnected();

    if (!this.isProviderConnected) {
      if (this.chatBody) {
        this.chatBody.messages = [];
      }
      return;
    }

    // changesObj.contact is the only one possible, this is future-proofing check
    // changesObj.contact.currentValue is now this.contact (previousValue is old)
    if (changesObj.contact) {
      if (this.contact) {
        this.loadChatBody();
      }
    }
  }

  sendNewMessage(): void {
    if (this.newMessage.nativeElement.value.length > 0) {
      if (this.contact) {
        this.chatService.sendMessage(this.contact, this.newMessage.nativeElement.value);
      }
      this.newMessage.nativeElement.value = "";
    }
  }

  onKeyDown(ev: any): any {
    if (this.isProviderConnected) {
      if (ev.keyCode === 13) {
        this.sendNewMessage();
        ev.preventDefault();
      }
      if (!this.isUserTyping) {
        this.isUserTyping = true;
        this.chatService.sendTypingIndicator(this.chatBody);
        setTimeout(() => {
          this.isUserTyping = false;
        }, 3000);
      }
    }
  }

  trackByTimestamp(index: number, message: any): number {
    return message.timestamp;
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
    this.chatService.removeOnChange(this.chatChangeId);
    this.chatService.removeOnProviderConnected(this.providerConnectedId);
    this.chatService.removeOnProviderDisconnected(this.providerDisconnectedId);
  }

  private loadChatBody(): void {
    this.checkChatAbility().then(() => {
      this.chatBody = undefined;
      this.messageLimit = 5;

      if (this.isChatEnabled && this.contact) {
        this.chatService.getChatBody(this.contact).then(chatBody => {
          this.chatBody = chatBody;
          if (!chatBody) {
            return;
          }

          setTimeout(() => {
            this.messageLimit = 50;
            setTimeout(() => {
              if (this.chatConversation.nativeElement) {
                this.chatConversation.nativeElement.scrollTop =
                  this.chatConversation.nativeElement.scrollHeight;
              }
            }, 15);
          }, 15);
        });
      }
    });
  }

  private checkChatAbility(): Promise<void> {
    if (this.contact && this.isOnsipEnabled) {
      const contactSub: Presentity | undefined = this.regSubscriptions.find(
        pres => !!this.contact && pres.aor === this.contact.aors[0]
      );

      this.isChatEnabled = contactSub ? (contactSub.eventData as RegInfo).isWebRTC : false;
    } else {
      this.isChatEnabled = false;
    }
    return Promise.resolve();
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      if (this.chatConversation) {
        this.chatConversation.nativeElement.scrollTop =
          this.chatConversation.nativeElement.scrollHeight;
      }
    }, 15);
  }
}
