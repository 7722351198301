import { Directive, Input, forwardRef } from "@angular/core";
import { FormControl, NG_VALIDATORS, Validator } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Directive({
  selector: "[onsipStateValidator]",
  providers: [
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => OnsipStateValidatorDirective),
      multi: true
    }
  ],
  standalone: false
})
export class OnsipStateValidatorDirective implements Validator {
  @Input() notRequired = false;
  constructor(private translate: TranslateService) {}

  public validate(control: FormControl): { [key: string]: any } | null {
    return this.validateState(control.value);
  }

  private validateState(state: string): Object | null {
    return !state && !this.notRequired
      ? {
          required: {
            message: this.translate.instant("ONSIP_I18N.STATE_IS_REQUIRED"),
            order: 0
          }
        }
      : // eslint-disable-next-line no-null/no-null
        null;
  }
}
