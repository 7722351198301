import { CallControllerService } from "../../../../common/services/call-controller.service";

import { views } from "../../../app/phone/views";

import { Component, OnInit, OnDestroy, HostBinding } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { filter, map } from "rxjs/operators";

import { Contact } from "../../../../common/interfaces/contact";
import { WebRTCSupport } from "../../../../common/services/support/webrtc-support";
import { CallState } from "../../../../common/libraries/sip/call-state";
import { isEndCallEvent } from "../../../../common/libraries/sip/call-event";
import { Role } from "@onsip/common/services/api/role";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";

@Component({
  selector: "onsip-call-page",
  templateUrl: "./call-page.component.html",
  styleUrls: ["./call-page.scss"],
  standalone: false
})
export class CallPageComponent implements OnInit, OnDestroy {
  @HostBinding("class.onsip-grid-content")
  _dontUse = true;

  views: any = views;
  currentContact: Contact | undefined;
  callState: CallState | undefined;

  private unsubscriber = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private callControllerService: CallControllerService,
    private apiSession: ApiSessionService
  ) {}

  ngOnInit(): void {
    this.unsubscriber.add(
      this.callControllerService
        .getCallEventObservable()
        .pipe(
          filter(isEndCallEvent),
          filter(event => !!this.callState && event.uuid === this.callState.uuid)
        )
        .subscribe(() => {
          this.callState = undefined;
          if (
            this.apiSession.stateValue.parentUserId &&
            this.apiSession.stateValue.loggedInRole !== Role.SuperUser
          ) {
            this.router.navigate([views.ADMIN_PBX_OVERVIEW]);
          } else {
            this.router.navigate([views.HOME]);
          }
        })
    );

    this.unsubscriber.add(
      this.route.data.pipe(map(data => data.callUuid as string)).subscribe(callUuid => {
        this.callState = callUuid
          ? this.callControllerService.getCallStateByUuid(callUuid)
          : undefined;
        if (!this.callState) {
          throw new Error("Call Page: should not be here without a call or dialog");
        } else {
          this.setDocumentTitle(
            this.callState.remoteDisplayName || this.callState.remoteUri.slice(4)
          );
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  browser(userAgent: string): string {
    const result = WebRTCSupport.parseUserAgent(userAgent);
    if (result === undefined) {
      return "Unknown";
    }
    const browser = this.capitalizeFirstLetter(result.name);
    const version = result.version;
    return `${browser} ${version}`;
  }

  private capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  private setDocumentTitle(title: string): void {
    document.title = "OnSIP - Call With " + title;
  }
}
