import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input,
  HostBinding,
  ElementRef,
  Optional,
  Self,
  AfterContentInit,
  OnDestroy,
  ViewChild,
  NgZone
} from "@angular/core";
import { FormControl, ControlValueAccessor, NgControl } from "@angular/forms";
import { MatFormField, MatFormFieldControl } from "@angular/material/form-field";
import { MatListOption, MatSelectionList, SELECTION_LIST } from "@angular/material/list";

import { Subject, Subscription } from "rxjs";

@Component({
  selector: "onsip-selection-list-deluxe",
  template: `
    @if (showSearchField) {
    <mat-form-field appearance="outline">
      <input
        matInput
        [formControl]="searchControl"
        [placeholder]="'ONSIP_I18N.SEARCH' | translate"
        autocomplete="off"
      />
      <mat-icon matSuffix [color]="'primary'">search</mat-icon>
    </mat-form-field>
    } @if (showMasterToggle) {
    <mat-checkbox
      disableRipple
      [checked]="masterToggle"
      (change)="onMasterToggle($event)"
      [color]="color"
      [disabled]="disabled"
    >
      <span class="select-all">{{ "ONSIP_I18N.SELECT_ALL" | translate }}</span>
    </mat-checkbox>
    } @if (showMasterToggle || showSearchField) {
    <mat-divider></mat-divider>
    }
    <div
      class="list-option-container"
      [class.with-master-toggle]="showMasterToggle"
      [class.with-search-field]="showSearchField"
    >
      <mat-selection-list>
        <ng-content></ng-content>
      </mat-selection-list>
    </div>
  `,
  styleUrls: ["./selection-list-deluxe.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MatFormFieldControl, useExisting: SelectionListDeluxeComponent },
    { provide: SELECTION_LIST, useExisting: SelectionListDeluxeComponent }
  ],
  encapsulation: ViewEncapsulation.None, // eslint-disable-line @angular-eslint/use-component-view-encapsulation
  standalone: false
})
export class SelectionListDeluxeComponent
  extends MatSelectionList
  implements ControlValueAccessor, MatFormFieldControl<Array<string>>, AfterContentInit, OnDestroy
{
  static nextId = 0;
  readonly controlType = "onsip-selection-list-deluxe";
  @HostBinding() id = `onsip-selection-list-deluxe-${SelectionListDeluxeComponent.nextId++}`;
  @HostBinding("attr.aria-describedby") describedBy = "";
  @ViewChild(MatFormField) searchField: MatFormField | undefined;

  stateChanges = new Subject<void>();
  errorState = false;
  focused = false;
  placeholder = "";
  shouldLabelFloat = true;

  @Input() showSearchField = true;
  searchControl = new FormControl();
  @Input() showMasterToggle = true;
  masterToggle = false;

  @Input()
  get value(): Array<string> | null {
    return this._value;
  }
  set value(newValue: Array<string> | null) {
    this._value = newValue;
    this.stateChanges.next();
  }

  get empty() {
    return !!this._value?.length;
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = !!value;
    this.stateChanges.next();
  }

  private _required = false;

  private unsubscriber = new Subscription();

  constructor(
    _elementRef: ElementRef<HTMLElement>,
    _ngZone: NgZone,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super(_elementRef, _ngZone);

    // eslint-disable-next-line no-null/no-null
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
      setTimeout(() => {
        if (this.ngControl.control instanceof FormControl && this.ngControl.statusChanges) {
          this.unsubscriber.add(
            this.ngControl.statusChanges.subscribe(status => {
              this.errorState = status === "INVALID";
              this.stateChanges.next();
            })
          );
        }
      });
    }
  }

  ngAfterContentInit() {
    // Master toggle behavior - see below comment for more details
    this.unsubscriber.add(
      this.selectedOptions.changed.subscribe(() => {
        if (this.options.map(option => option.selected).every(selected => selected)) {
          this.masterToggle = true;
        }
        if (this.options.map(option => option.selected).every(selected => !selected)) {
          this.masterToggle = false;
        }
      })
    );

    this.unsubscriber.add(
      this.searchControl.valueChanges.subscribe(value => {
        if (typeof value !== "string") return;
        this.options.forEach(option => {
          const match = new RegExp(value.toLowerCase()).test(
            option.getLabel().toString().toLowerCase()
          );
          (option as MatListOption)._elementRef.nativeElement.style.display = match ? "" : "none";
        });
      })
    );

    // Overwrite the FocusKeyManager which is defined in super.ngAfterContentInit
    // Ours omits the "withTypeAhead()", which causes a UX collision with our searchbar
    // this._keyManager = new FocusKeyManager<MatListOption>(this.options)
    //   .withWrap()
    //   .withHomeAndEnd()
    //   // Allow disabled items to be focusable. For accessibility reasons, there must be a way for
    //   // screenreader users, that allows reading the different options of the list.
    //   .skipPredicate(() => false)
    //   .withAllowedModifierKeys(["shiftKey"]);
  }

  setDescribedByIds(ids: Array<string>) {
    this.describedBy = ids.join(" ");
  }

  // noop
  onContainerClick(/*event: MouseEvent*/) {}

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
    this.disabled = isDisabled;
  }

  /*
   * Behavior:
   * - If list is all checked - master should look checked and checking it makes the list all unchecked
   * - If list is all unchecked - master should look unchecked and checking it makes the list all checked
   * - If list is partially checked, master will look as it was, and change the list to what master is not
   */
  onMasterToggle({ checked }: { checked: boolean }): void {
    this.masterToggle = checked;
    if (checked) {
      this.selectAll();
    } else {
      this.deselectAll();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.stateChanges.complete();
    this.unsubscriber.unsubscribe();
  }
}
