import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from "@angular/core";
import { AdvQueueWarningService } from "@onsip/common/services/api/resources/advQueueWarning/adv-queue-warning.service";
import { SnackbarService } from "@onsip/web/features/shared/components/snackbar/snackbar.service";
import { WarningBhrs, WarningModel } from "../queue-warnings.model";
import { AdvQueueWarning } from "@onsip/common/services/api/resources/advQueueWarning/adv-queue-warning";
import { MatButtonModule } from "@angular/material/button";
import { BehaviorSubject, filter, map, Subscription } from "rxjs";
import { CommonModule } from "@angular/common";
import { QueueWarningsCardComponent } from "../card/queue-warnings-card.component";
import { QueueWarningsEditComponent } from "../card/edit/queue-warnings-edit.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "onsip-queue-warnings-modal",
  templateUrl: "./queue-warnings-modal.component.html",
  styleUrl: "./queue-warnings-modal.component.scss",
  imports: [MatButtonModule, CommonModule, QueueWarningsCardComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueueWarningsModalComponent implements OnInit, OnDestroy {
  queueWarnings$ = new BehaviorSubject<Array<WarningModel>>([]);
  mode: "edit" | "view" = "view";
  private advQueueWarningService = inject(AdvQueueWarningService);
  private snackbar = inject(SnackbarService);
  private dialog = inject(MatDialog);
  private unsubscriber = new Subscription();

  ngOnInit(): void {
    this.advQueueWarningService.advQueueWarningBrowse({ Limit: 25000 }).then(response => {
      if (response.status === "success") {
        this.updateQueueWarnings(Object.values(response.data));
      } else {
        const errMessage = response.data.message;
        this.snackbar.openSnackBar(errMessage, "error");
      }
    });
    this.unsubscriber.add(
      this.advQueueWarningService.state
        .pipe(
          filter(state => !state.loading),
          map(state => Object.values(state.state))
        )
        .subscribe(warnings => {
          this.updateQueueWarnings(warnings);
        })
    );
  }
  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  openNewAlert() {
    this.dialog.open(QueueWarningsEditComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"]
    });
  }

  private updateQueueWarnings(warnings: Array<AdvQueueWarning>) {
    const parsedWarningData = warnings.map(data => this.parseWarning(data));
    this.queueWarnings$.next(parsedWarningData);
  }

  private parseWarning(warning: AdvQueueWarning): WarningModel {
    const thresholdValue = JSON.parse(warning.ThresholdValue)?.Value || "";
    const bhrData = JSON.parse(warning.WarningBhr) as WarningBhrs;
    return {
      warningId: warning.AdvQueueWarningId,
      queueId: warning.AdvQueueId,
      queueName: warning.AdvQueueName,
      queueAddress: warning.AdvQueueAddress,
      thresholdType: warning.ThresholdType,
      thresholdValue,
      alertType: warning.AlertType,
      alertDestination: warning.AlertDestination,
      cooldownSeconds: warning.CooldownSeconds,
      bhrData
    };
  }
}
