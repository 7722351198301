import { Component, Input, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "onsip-password-change-modal",
  templateUrl: "./password-change-modal.component.html",
  standalone: false
})
export class PasswordChangeModalComponent {
  @Input() sipAddress = "";

  constructor(
    public dialogRef: MatDialogRef<PasswordChangeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { sipAddress: string }
  ) {}
}
