import { Component, ChangeDetectionStrategy, Input } from "@angular/core";

@Component({
  selector: "onsip-item-list",
  templateUrl: "./onsip-item-list.component.html",
  styleUrls: ["./onsip-item-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class OnSIPItemListComponent<T> {
  @Input() listItems: Array<{ name: string } & T> = [];
  @Input() itemNamePlural!: string;
}
