import { Component, ChangeDetectionStrategy } from "@angular/core";

import { ContactService } from "../../../../common/services/contact/contact.service";
import { OnSIPURI } from "../../../../common/libraries/onsip-uri";

@Component({
  templateUrl: "./queue-upsell.component.html",
  styleUrls: ["./queue-upsell.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class QueueUpsellComponent {
  /** the frame number that the carousel is at, updated via event fired from the carousel in the template */
  frame = 0;
  contact = ContactService.createCustomSIPContact(
    "OnSIP Sales",
    OnSIPURI.parseString("sip:sales@onsip.com") as OnSIPURI
  );
}
