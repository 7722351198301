import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
  OnDestroy,
  HostListener,
  ViewChild,
  ElementRef
} from "@angular/core";
import { FormControl } from "@angular/forms";

import { combineLatest, Subscription } from "rxjs";
import { map, startWith, filter } from "rxjs/operators";

import { ContactService } from "../../../../../../common/services/contact/contact.service";
import { DialpadService, DtmfEvent } from "../../dialpad/dialpad.service";
import { Contact } from "../../../../../../common/interfaces/contact";

const MAX_RESULTS = 5;

@Component({
  selector: "onsip-sip-address-input",
  templateUrl: "./sip-address-input.component.html",
  standalone: false
})
export class SipAddressInputComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("target") target!: ElementRef<HTMLInputElement>;
  @Input() card = false;
  @Input() placeholder = "";
  @Input() whiteText = false;
  @Output() value = new EventEmitter<string>();
  @Output() enter = new EventEmitter<void>();

  formControl = new FormControl();
  autocomplete = combineLatest([
    this.formControl.valueChanges.pipe(startWith("")),
    this.contactService.state.pipe(map(state => state.contacts))
  ]).pipe(map(([query, contacts]) => this._filter(query, contacts)));

  private maxResults = MAX_RESULTS;
  private unsubscriber = new Subscription();

  constructor(private contactService: ContactService, private dialpad: DialpadService) {}

  ngOnInit() {
    this.unsubscriber.add(this.formControl.valueChanges.subscribe(value => this.value.emit(value)));
  }

  ngAfterViewInit() {
    setTimeout(() => this.focus());
    this.unsubscriber.add(
      this.dialpad.event
        .pipe(
          filter(event => event.type === "press"),
          filter(event => event.source === "button"),
          filter(event => event.target === "newCall")
        )
        .subscribe((event: DtmfEvent) => {
          this.formControl.setValue(
            this.formControl.value ? this.formControl.value + event.key : event.key
          );
          setTimeout(() => this.focus()); // everyone's favorite hack
        })
    );
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
  }

  @HostListener("window:keypress", ["$event"]) onKeyPress(event: KeyboardEvent): void {
    if (event.key === "Enter") this.enter.emit();
  }

  clear(): void {
    this.setValue("");
  }

  setValue(val: string): void {
    this.formControl.setValue(val);
  }

  focus(): void {
    if (this.target && this.target.nativeElement) {
      this.target.nativeElement.selectionStart = this.target.nativeElement.value.length;
      this.target.nativeElement.focus();
    }
  }

  private _filter(query: string, pool: Array<Contact>): Array<Contact> {
    if (query === "") return [];

    let a: Array<Contact> = [],
      b: Array<Contact> = [],
      c: Array<Contact> = [];
    const cleanQuery = query.replace(/[^\w\s]/g, ""); // sanitize inputs;

    // find names with the query at the beginning of the name
    a = a.concat(pool.filter(contact => new RegExp("^" + cleanQuery, "i").test(contact.name)));

    // then find names with the query at the start of nonfirst words (ie last names)
    if (a.length < this.maxResults) {
      b = b.concat(
        pool.filter(contact => new RegExp(".+\\s" + cleanQuery, "i").test(contact.name))
      );
    }

    // then find names with the query anywhere else in the name (not including the previous list)
    if (query.length > 1 && a.length + b.length < this.maxResults) {
      c = c.concat(pool.filter(contact => new RegExp("\\S+" + cleanQuery, "i").test(contact.name)));
    }

    return a.concat(b).concat(c).slice(0, 5);
  }
}
