import { Component, Input, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "onsip-pulsing-dot",
  templateUrl: "./pulsing-dot.component.html",
  styleUrls: ["./pulsing-dot.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PulsingDotComponent {
  /** Connected true -> solid dot; Connected false -> pulsing dot */
  @Input() connected = false;
}
