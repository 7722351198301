import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  OnChanges,
  OnDestroy,
  ChangeDetectorRef
} from "@angular/core";
import { CallState } from "../../../../common/libraries/sip/call-state";
import { Subscription, Observable } from "rxjs";
import { filter, distinctUntilChanged, map } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CallMessageService } from "../../shared/controller/call-message.service";
import { CallControllerService } from "../../../../common/services/call-controller.service";
import { VideoConferenceService } from "../../videoConference/video-conference.service";
import { views } from "../../../app/phone/views";
import { ScreenShareService } from "../screen-share.service";

export interface VideoObject {
  remoteStream?: MediaStream;
  localStream?: MediaStream;
}

@Component({
  selector: "onsip-call-video-container",
  templateUrl: "./call-video-container.component.html",
  styleUrls: ["./call-video-container.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CallVideoContainerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() callUuid: string | undefined;
  @Input() isCallListItem = false;
  @Input() fullscreen = false;

  onHold = false;
  mutedVideo = false;
  isQueueVideo = false;
  hasScreenShare = false;
  statusString: string | undefined;
  queueVideoStream: string | undefined; // will be youtube link
  localStream: MediaStream | undefined;
  remoteStream: MediaStream | undefined;

  confCalls!: Observable<Array<VideoObject>>;

  private confCallStates: Array<CallState> | undefined;
  private isVideoConference: boolean | undefined;
  private isRendering = false;
  private unsubscriber = new Subscription();

  constructor(
    private callMessageService: CallMessageService,
    private callControllerService: CallControllerService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private videoConferenceService: VideoConferenceService,
    private changeDetectionRef: ChangeDetectorRef,
    public screenShareService: ScreenShareService
  ) {}

  ngOnInit(): void {
    this.unsubscriber.add(
      this.route.url.subscribe(url => {
        if ("/" + url.toString() === views.VIDEO_CONFERENCE) {
          this.isVideoConference = true;
        } else {
          this.isVideoConference = false;
        }
      })
    );

    this.unsubscriber.add(
      this.callMessageService.state.subscribe(() => {
        const queueVideo: any | undefined = this.callMessageService.findQueueVideo(
          this.callUuid || ""
        );

        if (queueVideo && queueVideo.isQueueVideo) {
          this.queueVideoStream = queueVideo.queueVideoStream;
          this.isQueueVideo =
            this.queueVideoStream && this.queueVideoStream.length > 0 ? true : false;
          this.stopRendering();
          return;
        } else if (this.isQueueVideo) {
          this.queueVideoStream = "";
          this.isQueueVideo = false;
        }

        this.updateCurrentCall();
      })
    );

    this.unsubscriber.add(
      this.callControllerService.state.subscribe(() => {
        if (!this.isVideoConference) {
          this.updateCurrentCall();
        }
      })
    );

    this.unsubscriber.add(
      this.screenShareService.state.pipe(distinctUntilChanged()).subscribe(state => {
        if (this.callUuid) {
          this.hasScreenShare = state.hasScreenShare
            ? this.screenShareService.calls[this.callUuid]
            : false;
          this.setupRendering(this.callUuid);
        }
        this.changeDetectionRef.markForCheck();
      })
    );

    this.confCalls = this.videoConferenceService.state.pipe(
      filter(
        state =>
          state.connected && !this.isCallListItem && (this.isVideoConference || !!state.isAnonymous)
      ),
      map(state => {
        const confCalls: Array<VideoObject> = [];
        if (!state.hold) {
          this.confCallStates = state.calls;
          this.confCallStates.forEach(callState =>
            confCalls.push(this.setupRendering(callState.uuid))
          );
        }
        this.remoteStream = undefined;
        this.localStream = this.videoConferenceService.getConferenceMediaStream();
        this.statusString = this.videoConferenceService.getStatusTranslation(state.status);
        return confCalls;
      })
    );

    this.changeDetectionRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
    this.stopRendering();
  }

  ngOnChanges(): void {
    if (this.callUuid) {
      this.hasScreenShare = this.screenShareService.getScreenShareFromUuid(this.callUuid);
      this.updateCurrentCall();
    }
  }

  private updateCurrentCall(): void {
    if (!this.callUuid) {
      return;
    }
    const call: CallState | undefined = this.callControllerService.getCallStateByUuid(
      this.callUuid
    );

    if (call) {
      if (this.onHold !== call.hold || this.mutedVideo !== call.video) {
        // get hold state of call
        this.onHold = call.hold;
        // get muted video state of call
        this.mutedVideo = call.video;
        if (this.onHold) {
          this.statusString = this.translate.instant("ONSIP_I18N.ON_HOLD");
        }
        this.changeDetectionRef.markForCheck();
      }
    }

    if (this.hasScreenShare && this.isRendering) {
      return;
    }

    if (call && !call.ended && call.videoAvailable && !this.onHold) {
      if (this.isRendering) {
        return;
      }
      this.setupRendering(this.callUuid);
      this.isRendering = true;
    } else {
      this.stopRendering();
      this.isRendering = false;
    }
    this.changeDetectionRef.markForCheck();
  }

  // similar to callAudioService
  private getPeerConnection(uuid: string): RTCPeerConnection | undefined {
    const sdh = this.callControllerService.findSessionDescriptionHandler(uuid);
    return sdh ? (sdh as any).peerConnection : undefined;
  }

  private setupRendering(uuid: string): any {
    // in case the call is onHold avoid setting the local and remote streams
    if (this.onHold) {
      this.stopRendering();
      this.isRendering = false;
      return;
    }

    const peerConnection: RTCPeerConnection | undefined = this.confCallStates
      ? this.videoConferenceService.getPeerConnection(uuid)
      : this.getPeerConnection(uuid);
    const retObj: VideoObject = {};

    if (!peerConnection) {
      return retObj;
    }

    if (peerConnection.getReceivers) {
      const tracks: Array<any> = [];
      peerConnection
        .getReceivers()
        .filter(receiver => receiver.track.kind === "video")
        .forEach(receiver => tracks.push(receiver.track));
      retObj.remoteStream = tracks.length > 0 ? new MediaStream(tracks) : undefined;
    } else {
      const streams: Array<any> = peerConnection.getRemoteStreams();
      retObj.remoteStream = streams.length > 0 ? streams[0] : undefined;
    }

    if (peerConnection.getSenders) {
      const tracks: Array<any> = [];
      peerConnection.getSenders().forEach(sender => {
        if (sender.track && sender.track.kind === "video") {
          tracks.push(sender.track);
        }
      });
      retObj.localStream = tracks.length > 0 ? new MediaStream(tracks) : undefined;
    } else {
      const streams: Array<any> = peerConnection.getLocalStreams();
      retObj.localStream = streams.length > 0 ? streams[0] : undefined;
    }
    if (retObj.localStream) {
      this.localStream = retObj.localStream;
    }
    if (retObj.remoteStream) {
      this.remoteStream = retObj.remoteStream;
    }
    return retObj;
  }

  private stopRendering(): void {
    this.localStream = undefined;
    this.remoteStream = undefined;
  }
}
