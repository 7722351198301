import {
  Directive,
  Input,
  OnInit,
  OnChanges,
  OnDestroy,
  Output,
  EventEmitter,
  HostListener,
  HostBinding,
  ChangeDetectorRef
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { filter, take, distinctUntilChanged, pluck, map } from "rxjs/operators";
import { LogService } from "../../../../../common/services/logging";
import { UserAgent } from "../../../../../common/libraries/sip/user-agent";
import { ConnectedUserAgentEvent } from "../../../../../common/libraries/sip/user-agent-event";
import { UserAddress } from "../../../../../common/services/api/resources/userAddress/user-address";
import { isEndCallEvent } from "../../../../../common/libraries/sip/call-event";
import { SupportService } from "../../../../../common/services/support/support.service";
import { SnackbarService } from "../snackbar/snackbar.service";
import { AppCallingService } from "../../services/appCalling/app-calling.service";
import { UserAgentService } from "../userAgent/user-agent.service";
import { CallSetupService } from "@onsip/common/services/api/resources/callSetup/call-setup.service";
import { CallControllerService } from "../../../../../common/services/call-controller.service";
import { CallMessageService } from "../../controller/call-message.service";
import { CallAudioService } from "../../../call/call-audio.service";
import { IdentityService } from "../../../../../common/services/identity.service";
import { SdhFactoryHelperService } from "../../controller/sdh-factory-helper.service";
import { UserService } from "../../../../../common/services/api/resources/user/user.service";
import { FreeTrialService, FreeTrialState } from "../freeTrial/free-trial.service";
import { AccountDetailsService } from "../../../../../common/services/api/resources/accountDetails/account-details.service";
import { FreeTrialExpiringModalComponent } from "../freeTrial/free-trial-expiring-modal.component";
import { ModalMaterialComponent } from "../modal/modal-material.component";
import { TranslateService } from "@ngx-translate/core";
import { Contact } from "../../../../../common/interfaces/contact";
import { InvalidTargetCallError } from "../../../../../common/libraries/sip/call-controller-error";
import { CallState } from "../../../../../common/libraries/sip/call-state";
import { views } from "../../../../app/phone/views";
import { Config } from "../../../../../common/config";
import { URI, Grammar } from "sip.js";
import { MatDialog } from "@angular/material/dialog";
import { E164PhoneNumber } from "../../../../../common/libraries/e164-phone-number";
import { isAtLeastAccountAdminRole } from "@onsip/common/services/api/role";

@Directive({
  selector: "[onsipNewCall]",
  exportAs: "onsipNewCall",
  standalone: false
})
export class NewCallDirective implements OnInit, OnChanges, OnDestroy {
  /** REQUIRED: The remote aor the call will be made to */
  @Input() remoteAor: string | undefined;
  /** OPTIONAL: The contact to make that the call will be made to setting this will */
  @Input() contact: Contact | undefined;
  /** OPTIONAL: false or ommitted for audio only; true for video */
  @Input() video = false;
  /** OPTIONAL: Remote display name if not provided, best guess.
   *  Caution: Setting this will trump contact.name or anything else that might serve as the remote display name
   */
  @Input() remoteDisplayName: string | undefined;
  /** OPTIONAL: Flag, must be set true for anonymous caller */
  @Input() anonymous = false;
  /** REQUIRED FOR anonymous calls: Local Display name to be seen by callee */
  @Input() customLocalDisplayName: string | undefined;
  /** OPTIONAL: Allow parent to custom disable element
   *  Caution: don't want do bind [disable] on the button itself bc we will be touching it in 2 places.
   */
  @Input() customDisable = false;
  /** OPTIONAL: making a call will not navigate the user to the call page */
  @Input() noCallNavigate = false;
  /** Emits string "started" when call has started */
  @Output() outputStartedCall: EventEmitter<void> = new EventEmitter();

  @HostBinding("style.display") display = "initial";
  @HostBinding("disabled") disabled = false;

  /** Flag used to ignore the first ngOnChanges */
  private viewInit = false;
  private gumPending = false;
  // Web RTC Support? Disable/hide video button if not
  private isCallSetupEnabled = false;
  private webrtcSupport = false;
  // PSTN validation related
  private isFreePlanAccount = false;
  private freeTrialState: FreeTrialState | undefined;
  private sufficientPSTNBalance = false;
  private noOutboundPSTN = false;
  private isAtLeastAccountAdmin = false;

  private unsubscriber = new Subscription();

  constructor(
    private dialog: MatDialog,
    private log: LogService,
    private router: Router,
    private supportService: SupportService,
    private snackbarService: SnackbarService,
    private appCallingService: AppCallingService,
    private userAgentService: UserAgentService,
    private callControllerService: CallControllerService,
    private callMessageService: CallMessageService,
    private callSetupService: CallSetupService,
    private identityService: IdentityService,
    private sdhFactoryHelperService: SdhFactoryHelperService,
    private callAudioService: CallAudioService,
    private freeTrialService: FreeTrialService,
    private userService: UserService,
    private accountDetailsService: AccountDetailsService,
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef
  ) {}

  @HostListener("click") onNewCallClick() {
    this.dial();
  }

  ngOnInit(): void {
    // Check webrtc support will hide button if not
    this.webrtcSupport = this.supportService.isWebrtcSupported();

    this.unsubscriber.add(
      this.appCallingService.state
        .pipe(pluck("enabled"), distinctUntilChanged())
        .subscribe(isEnabled => {
          this.isCallSetupEnabled = !isEnabled;
          this.setHidden();
        })
    );

    this.freeTrialService.state.subscribe(state => {
      this.freeTrialState = state;
    });

    // get noOutboundPSTN flag from userService
    this.unsubscriber.add(
      this.userService.selfUser.subscribe(user => {
        this.noOutboundPSTN = user.noOutgoingPstn;
        this.isAtLeastAccountAdmin = isAtLeastAccountAdminRole(user.roles);
      })
    );

    // Check PSTN balance
    this.unsubscriber.add(
      this.accountDetailsService.state
        .pipe(
          filter(({ loading }) => !loading),
          map(({ state }) => Object.values(state)),
          take(1)
        )
        .subscribe(accountDetails => {
          const result = accountDetails[0];
          const prepaidBalance: number = parseInt(result.prepaidBalance);
          const secondsBalance: number = parseInt(result.secondsBalance);
          const premiumAccount = result.primaryPackages;
          this.isFreePlanAccount = premiumAccount ? false : true;
          this.sufficientPSTNBalance = prepaidBalance > 0 || secondsBalance > 0;
        })
    );

    // Hack: mat-button will override disabled on it's init so need to wait for that.
    this.viewInit = true;
    setTimeout(() => {
      this.setDisabled();
      this.setHidden();
    });
  }

  ngOnChanges() {
    if (this.viewInit) {
      this.setDisabled();
      this.setHidden();
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  dial(): void {
    if (this.anonymous && !this.customLocalDisplayName) {
      // require local display name from anonymous calls
      return;
    }
    if (!this.remoteAor || !(this.display !== "hidden") || this.disabled) {
      // require remoteAor to call dial()
      return;
    }

    const options: any = {
      isVideoInvite: this.video,
      contact: this.contact,
      remoteDisplayName: this.remoteDisplayName || (this.contact ? this.contact.name : undefined),
      anonymous: this.anonymous
    };
    let target: string = this.remoteAor,
      prom: Promise<any> = Promise.resolve();

    this.callAudioService.approveAudio();

    if (!target) {
      return;
    }

    // target = this.callControllerService.parsePSTNString(target);
    target = new E164PhoneNumber(target).e164Uri;

    if (this.anonymous && !this.identityService.getOutboundIdentity()) {
      prom = prom.then(() => {
        const ua: UserAgent = this.callControllerService.makeAndAddUserAgent(
          {
            displayName: this.customLocalDisplayName,
            userAgentString: "OnSIP_App/" + Config.VERSION_NUMBER + "/" + Config.PLATFORM_STRING
          },
          undefined,
          {},
          {
            handleIncomingMessage: message => {
              /*
               * This used to match message remoteIdentity uri with call remoteIdentity uri.
               * This didn't work when calling the extension of the queue, so now it is unguarded (bad)
               * At the time, there did not seem to be a way to match MESSAGE to call without remoteIdentity.
               * Custom data and queue video are sent almost immediately after the call is set up, but if a situation
               * arises where this is not true, this must be dealt with.
               */
              if (!message.request.body.startsWith("{")) {
                // won't be json, just return;
                return;
              }

              const call: CallState | undefined = this.callControllerService.getUnheldCall();
              let jsonMessage: any;

              try {
                jsonMessage = JSON.parse(message.request.body || "");
              } catch (e) {
                this.log.error("Received message could not be parsed as JSON:", message);
                return;
              }

              if (
                Object.prototype.hasOwnProperty.call(jsonMessage, "onHold") &&
                call &&
                call.video
              ) {
                // queue video
                this.callMessageService.onQueueVideo(call, jsonMessage);
              }
            }
          },
          this.sdhFactoryHelperService.createFactory()
        );

        this.identityService.setDefaultIdentity(ua.aor, true);

        return new Promise(resolve => {
          this.callControllerService
            .getUserAgentEventObservable()
            .pipe(
              filter(event => event.id === ConnectedUserAgentEvent.id),
              take(1)
            )
            .subscribe(resolve);
        });
      });
    }

    if (this.anonymous) {
      prom = prom.then(() => {
        return this.userAgentService.createOutboundCall(target, options);
      });
    } else {
      prom = prom.then(() => {
        if (this.isCallSetupEnabled) {
          const outboundIdentity: UserAddress | undefined =
            this.identityService.getOutboundIdentity();
          if (!outboundIdentity) {
            return "done";
          }

          target = target.startsWith("sip:") ? target : "sip:" + target;
          if (target.indexOf("@") === -1) {
            target += "@" + outboundIdentity.domain;
          }

          const uri: URI | undefined =
            Grammar.URIParse(target) || Grammar.URIParse("sip:" + target);
          if (!uri) {
            return Promise.reject(new InvalidTargetCallError(target));
          }
          const aor: string = uri.aor.startsWith("+") ? uri.aor.slice(1) : uri.aor;

          return this.callSetupService
            .CallSetup({
              FromAddress: outboundIdentity.aor,
              ToAddress: aor
            })
            .then(res => {
              if (res.status === "error") {
                throw res.data.message;
              }

              this.identityService.restoreOutboundIdentity();
              return "done";
            });
        } else {
          return this.userAgentService.createOutboundCall(target, options);
        }
      });
    }
    prom
      .then(
        uuid => {
          this.gumPending = false;
          this.disabled = this.disabled && this.gumPending;
          if (uuid === "done") {
            // this is call setup, so return
            return;
          }

          if (!this.anonymous && !this.noCallNavigate) {
            this.router.navigate([views.CALL], {
              state: {
                callUuid: uuid
              }
            });
          }

          this.outputStartedCall.emit();
          /*
           * This piece of code was written to determine if a call has failed or not.
           * The purpose for finding these failed calls was to add descriptive custom error messages for users.
           * This code creates an observable that listens for when the dialed call has ended.
           * It filters for the current call using the returned uuid from promise and takes the first result.
           * The current call state is obtained using the getCallStateByUuid from the callControllerService
           * If the call state has an undefined connectedAt state, then it has never successfully connected and is a failed call.
           */
          this.callControllerService
            .getCallEventObservable()
            .pipe(
              filter(isEndCallEvent),
              filter(event => event.uuid === uuid),
              take(1)
            )
            .subscribe(event => {
              const callState = this.callControllerService.getCallStateByUuid(event.uuid);
              if (!callState) {
                throw new Error("CallState undefined");
              }
              if (!callState.connectedAt) {
                const outgoingName: string = callState.remoteUri.slice(4).replace(/@.*$/, "");
                this.handleFailedCallState(outgoingName);
              }
            });
        },
        () => {
          this.gumPending = false;
          this.disabled = this.disabled && this.gumPending;
        }
      )
      .catch(error => {
        this.log.error("newCallComponent - dial:", error, target);
        if (error instanceof InvalidTargetCallError) {
          if (this.remoteAor) {
            throw new Error("newCallComponent - dial: remote uri is invalid");
          }
        }
        this.gumPending = false;
        this.disabled = this.disabled && this.gumPending;
        this.snackbarService.openSnackBar(error.message, "error");
      });

    this.gumPending = true;
    this.disabled = this.disabled && this.gumPending;
  }

  private handleSnackbarMessage(option: string): void {
    let message = "";
    let action = "";
    if (option === "pstn") {
      if (this.isFreePlanAccount) {
        message = "Connecting with public numbers requires a premium account & calling credit";
        if (Config.isAppStore || !this.isAtLeastAccountAdmin) {
          message += ". Please contact your account's administrator.";
          action = "OK";
        } else {
          action = "UPGRADE";
        }
      } else {
        message = "Connecting with public numbers requires calling credit";
        if (Config.isAppStore || !this.isAtLeastAccountAdmin) {
          message += ". Please contact your account's administrator.";
          action = "OK";
        } else {
          action = "ADD CREDIT";
        }
      }
    }

    const snackbarRef = this.snackbarService.openSnackBar(message, "error", action, {
      duration: 8000,
      verticalPosition: "top"
    });
    if (!Config.isAppStore) {
      snackbarRef.onAction().subscribe(() => {
        if (this.isAtLeastAccountAdmin) {
          this.router.navigate([views.ADMIN_ACCOUNT_SETTINGS_CALLING_CREDITS]);
        }
      });
    }
  }

  private handleFailedCallState(outgoingName: string): void {
    const isValidNumber: boolean = Config.isAppStore
      ? false
      : new E164PhoneNumber(outgoingName).isValid;
    if (isValidNumber) {
      if (this.noOutboundPSTN) {
        this.triggerNoOutboundPSTNDialog();
      } else if (this.freeTrialState?.trialExpired && this.freeTrialState?.isFreeTrialAccount) {
        this.triggerFreeTrialExpiredDialog();
      } else if (!this.sufficientPSTNBalance) {
        this.handleSnackbarMessage("pstn");
      }
    }
  }

  private setDisabled(): void {
    if (this.customDisable) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
    if (this.remoteAor) {
      this.disabled = this.customDisable || this.remoteAor.endsWith("anonymous.invalid");
    }
    this.cdRef.markForCheck();
  }

  private setHidden(): void {
    if (this.video && !this.webrtcSupport && this.isCallSetupEnabled) {
      this.display = "none";
    } else {
      this.display = "initial";
    }
    this.cdRef.markForCheck();
  }

  /** Open Expiring Dialog, set dismissed flag and do action when closed */
  private triggerFreeTrialExpiredDialog() {
    this.dialog
      .open(FreeTrialExpiringModalComponent, {
        data: {
          expiring: false
        },
        panelClass: ["mat-typography", "onsip-dialog-universal-style"],
        width: "480px"
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(action => {
        if (action === "contact-sales") {
          this.router.navigate([views.CONTACT_SALES]);
        }
      });
  }

  private triggerNoOutboundPSTNDialog() {
    this.dialog.open(ModalMaterialComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"],
      data: {
        title: this.translate.instant("ONSIP_I18N.PSTN_CALLS_ARE_DISABLED"),
        message: this.translate.instant("ONSIP_I18N.CONTACT_YOUR_ACCOUNT_ADMINISTRATOR_TO_ENABLE"),
        primaryBtnText: this.translate.instant("ONSIP_I18N.CLOSE"),
        showOnlyPrimaryBtn: true,
        noSentenceCaseTitle: true,
        primaryBtnFlat: true
      }
    });
  }
}
