import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit
} from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Subscription } from "rxjs";
import { SystemStatusComponent } from "../shared/components/entryPage/systemStatus/system-status.component";
import { AdminSupportSecureCodeService } from "./admin-support-secure-code.service";

@Component({
  selector: "onsip-admin-support",
  templateUrl: "./admin-support.component.html",
  styleUrls: ["./admin-support.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AdminSupportComponent implements OnInit, OnDestroy {
  @HostBinding("class.onsip-grid-content")
  @HostBinding("class.onsip-admin-host-grid")
  _dontUse = true;

  isSmallOrMedBreakpoint = false;
  sysStatusComp = new SystemStatusComponent();
  curSystemOperational = true;
  secureIdCode = "";

  private unsubscriber = new Subscription();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private cdRef: ChangeDetectorRef,
    private adminSupportSecureCode: AdminSupportSecureCodeService
  ) {
    this.sysStatusComp.initStatus();
  }

  ngOnInit(): void {
    this.setBreakpoints();
    this.secureIdCode = this.adminSupportSecureCode.getSecureIdCode();
    this.setSystemStatus();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  openKnowledgeBase(): void {
    window.open("https://support.onsip.com", "_blank");
  }

  private setBreakpoints(): void {
    this.unsubscriber.add(
      this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.Medium]).subscribe(result => {
        this.isSmallOrMedBreakpoint = result.matches;
        this.cdRef.markForCheck();
      })
    );
  }

  private setSystemStatus(): void {
    this.unsubscriber.add(
      this.sysStatusComp.systemStatus.subscribe(status => {
        if (status !== "good" && status !== "bad") return;
        this.curSystemOperational = status === "good" ? true : false;
        this.cdRef.markForCheck();
      })
    );
  }
}
