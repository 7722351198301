import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectionStrategy
} from "@angular/core";

import { UserCustomizationService } from "../../../../../../common/services/api/resources/userCustomization/user-customization.service";

import { ImageUploadComponent } from "../image-upload.component";
import { ImageUploadModalReturnData } from "../image-upload-interface";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "onsip-avatar-upload",
  templateUrl: "./avatar-upload.component.html",
  styleUrls: ["./avatar-upload.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AvatarUploadComponent {
  /** input from the child image cropper. Gets the image in png;base64 form from the image cropper */
  @ViewChild("imageUpload") imageUpload!: ImageUploadComponent;

  /** flag if parent component is using the upload through a dialog or not */
  // the upload button is only shown when the component is using a dialog so for
  // non-dialog component using the upload, you will have to hook up the confirmUpload() function in
  // that parent component
  @Input() hasDialog = false;

  /** flag to let the image upload component whether to wrap the image in an avatar frame or not */
  @Input() hasFrame = true;

  /** flag to let the image upload component use the tempavatar state from the avatar service instead of the avatar state */
  // we use the tempavatar state for uploading any new user's avatar or any user that is not the account user
  @Input() useTempAvatar = false;

  /** current avatar or tempAvatar given by parent component */
  @Input() currentAvatar = "";

  /** Output emitter to send info to the parent component */
  @Output() avatarChange = new EventEmitter<ImageUploadModalReturnData>();

  /** flag if user has deleted their current avatar from the image uploader */
  hasDeletedAvatar = false;

  myAvatar!: Observable<string>;

  constructor(private userCustomizationService: UserCustomizationService) {
    this.myAvatar = this.userCustomizationService.selfUser.pipe(
      map(userCustomization => userCustomization.userAvatarUrl)
    );
  }

  deleteCurrent(): void {
    this.hasDeletedAvatar = true;
  }

  confirmUpload(): void {
    const imageResponse: ImageUploadModalReturnData = {
      doSave: true,
      image: ""
    };
    if (this.imageUpload && this.imageUpload.croppedImage) {
      imageResponse.image = this.imageUpload.croppedImage || "";
    } else {
      // save changes if user does delete avatar at the upload screen
      imageResponse.doSave = this.hasDeletedAvatar;
    }
    if (imageResponse.doSave && imageResponse.image !== undefined) {
      if (!this.useTempAvatar) {
        this.userCustomizationService.userCustomizationAvatarEdit({
          UserAvatar: imageResponse.image
        });
      }
    }
    this.avatarChange.emit(imageResponse);
  }
}
