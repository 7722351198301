import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs/operators";

@Component({
  selector: "onsip-test-shell-nav",
  templateUrl: "./test-shell-nav.component.html",
  styles: ["div {padding: 0 16px}"],
  standalone: false
})
export class TestShellNavComponent {
  constructor(private actRouter: ActivatedRoute) {}
  text = this.actRouter.data.pipe(map(data => data?.text));
}
