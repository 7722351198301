import {
  Directive,
  Input,
  HostListener,
  HostBinding,
  OnInit,
  OnChanges,
  OnDestroy
} from "@angular/core";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";

import { Contact } from "../../../common/interfaces/contact";

import { SnackbarService } from "../shared/components/snackbar/snackbar.service";
import { ContactService } from "@onsip/common/services/contact/contact.service";

@Directive({
  selector: "[onsipAddContact]",
  standalone: false
})
export class AddContactDirective implements OnInit, OnChanges, OnDestroy {
  @Input("onsipAddContact") contact: Contact | undefined;
  @HostBinding("attr.disabled") disabled: boolean | undefined = undefined;

  /** Flag used to ignore the first ngOnChanges */
  private viewInit = false;
  private unsubscribe = new Subject<void>();

  private get isBad(): boolean {
    return !this.contact || this.contact.aors.some(aor => /anonymous\.invalid$/.test(aor));
  }

  constructor(
    private translate: TranslateService,
    private contactService: ContactService,
    private snackbar: SnackbarService
  ) {}

  @HostListener("click") onClick() {
    if (!this.contact) return;
    if (this.contact.custom) {
      this.contactService.addCustomContact(this.contact.name, this.contact).then(() => {
        this.snackbar.openSnackBar(
          this.translate.instant("ONSIP_I18N.ADDED_TO_YOUR_CONTACTS"),
          "success"
        );
      });
    } else {
      this.contactService.addContact([this.contact]).then(() => {
        this.snackbar.openSnackBar(
          this.translate.instant("ONSIP_I18N.ADDED_TO_YOUR_CONTACTS"),
          "success"
        );
      });
    }
  }

  /* Need this LC Hook because mat-button sets disabled=false in constructor so if
    this directive "wins" the race it will potentially cause disabled to be unset */
  ngOnInit() {
    this.viewInit = true;
    setTimeout(() => {
      this.contactService
        .getContactList$()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => this.setDisabled());
    });
  }

  ngOnChanges() {
    this.viewInit && this.setDisabled();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private setDisabled(): void {
    if (this.isBad) {
      this.disabled = true;
      return;
    }
    if (this.contactService.findUsingName(this.contact ? this.contact.name : "")) {
      this.disabled = true;
    } else {
      this.disabled = undefined;
    }
  }
}
