import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { VideoObject } from "../call-video-container.component";

@Component({
  selector: "onsip-call-video",
  templateUrl: "./call-video.component.html",
  styleUrls: ["./call-video.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CallVideoComponent {
  @Input() callUuid: string | undefined;
  @Input() statusString: string | undefined;
  @Input() queueVideoStream: string | undefined;
  @Input() localStream: MediaStream | undefined;
  @Input() remoteStream: MediaStream | undefined;
  @Input() confCalls: Array<VideoObject> | undefined;
  @Input() isQueueVideo!: boolean;
  @Input() isCallListItem!: boolean;
}
