import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  OnChanges
} from "@angular/core";

@Component({
  selector: "onsip-queue-video-box",
  templateUrl: "./queue-video-box.component.html",
  styleUrls: ["./queue-video-box.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class QueueVideoBoxComponent implements AfterViewInit, OnDestroy, OnChanges {
  @ViewChild("queueVideoFeed") queueVideoFeed!: ElementRef;

  @Input() queueVideoStream!: string;

  ngAfterViewInit(): void {
    this.queueVideoFeed.nativeElement.contentWindow.location.replace(this.queueVideoStream);
  }

  ngOnChanges(): void {
    if (this.queueVideoFeed) {
      this.queueVideoFeed.nativeElement.contentWindow.location.replace(this.queueVideoStream);
    }
  }

  ngOnDestroy(): void {
    if (this.queueVideoFeed.nativeElement && this.queueVideoFeed.nativeElement.contextWindow) {
      this.queueVideoFeed.nativeElement.contentWindow.location.replace("about:blank");
    }
  }
}
