import { Component, Input, OnInit, OnDestroy, HostBinding } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Observable, Subscription, combineLatest } from "rxjs";
import { map, filter, take } from "rxjs/operators";

import { HubspotInfoService } from "./services/hubspot-info.service";
import { HubspotApiService } from "./services/hubspot-api.service";
import { Contact } from "./services/lib/contact";
import { CallState } from "../../../../../common/libraries/sip/call-state";

@Component({
  selector: "onsip-hubspot-embed",
  templateUrl: "hubspot-embed.component.html",
  styleUrls: ["hubspot-embed.component.scss"],
  standalone: false
})
export class HubspotEmbedComponent implements OnInit, OnDestroy {
  @HostBinding("class.onsip-hubspot-embed")
  _dontUse = true;

  @Input() callState!: CallState;

  authorized!: Observable<boolean>;
  noContactFound = false;
  safeResourceURL: SafeResourceUrl | undefined = undefined;

  private _hubspotAuthorized = false;
  private unsubscriber = new Subscription();

  constructor(
    private domSanitizer: DomSanitizer,
    private hubspotApi: HubspotApiService,
    private hubspotInfo: HubspotInfoService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  get hubspotAuthorized(): boolean {
    return this._hubspotAuthorized;
  }

  set hubspotAuthorized(enabled: boolean) {
    if (enabled) {
      this._hubspotAuthorized = true;
      this.hubspotApi.authorize().catch(error => {
        this._hubspotAuthorized = false;
        throw error;
      });
    } else {
      this._hubspotAuthorized = false;
      this.hubspotApi.revoke().catch(error => {
        this._hubspotAuthorized = false;
        throw error;
      });
    }
  }

  private init(): void {
    this.authorized = this.hubspotApi.state.pipe(map(state => state.authorized));
    this.unsubscriber.add(
      this.authorized.subscribe(authorized => (this._hubspotAuthorized = authorized))
    );

    this.unsubscriber.add(
      combineLatest([
        this.authorized.pipe(filter(authorized => authorized)),
        this.hubspotInfo.state.pipe(
          filter(() => !!this.hubspotInfo.fetchHubspotContact(this.callState.uuid))
        )
      ])
        .pipe(take(1))
        .subscribe(() => {
          const timeoutForContact = setTimeout(() => (this.noContactFound = true), 2000);

          clearTimeout(timeoutForContact);
          const contact: Contact | undefined = this.hubspotInfo.fetchHubspotContact(
            this.callState.uuid
          );

          if (contact) {
            const url = `https://app.hubspot.com/contact-timeline-embed/${contact.portalId}/login?id=${contact.vid}`;
            this.safeResourceURL = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
          } else {
            this.noContactFound = true;
          }
        })
    );
  }
}
