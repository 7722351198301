import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs";
import { distinctUntilChanged, filter, map, pluck, take } from "rxjs/operators";
import {
  CallHistoryCall,
  PhoneCallHistoryCall,
  RecentCallDirection,
  RecentCallDisposition
} from "@onsip/common/services/callHistory/call-history";
import * as callHistoryConfig from "@onsip/common/services/callHistory/call-history-config";
import { SdrService } from "@onsip/common/services/api/resources/sdr/sdr.service";
import { CallHistoryService } from "@onsip/common/services/callHistory/call-history.service";
import { ContactService } from "@onsip/common/services/contact/contact.service";
import { DateService } from "@onsip/common/services/date.service";
import { OnSIPURI } from "@onsip/common/libraries/onsip-uri";
import { E164PhoneNumber } from "@onsip/common/libraries/e164-phone-number";
import { Contact } from "@onsip/common/interfaces/contact";
import { ExpandedCallDetail } from "./expanded-call-detail";
import { CallHistoryDetailsService } from "./call-history-details.service";
import { PAGE_SIZE_OPTIONS } from "../../const";

const debug = false;

const BASE_COLUMNS = ["date", "time", "remote-identity", "status"];

@Component({
  selector: "onsip-recent-calls",
  templateUrl: "./recent-calls.component.html",
  styleUrls: ["./recent-calls.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class RecentCallsComponent implements OnInit, OnDestroy {
  @Input() isMobile = false;

  @Input() set search(searchQuery: string) {
    this._search = searchQuery ?? ""; // FormControl.valueChanges sometimes emits null
    // HACK the way angular passes inputs results in a race between async init of WebCallHistoryService
    // this code block results in an api call, which requires parameters that are init'd asynchronously
    // in order to avoid the problem we just early return here, however- the service shouldn't behave like
    // eslint-disable-next-line no-null/no-null
    if (searchQuery === null) return;
    this.applyFilter(this._search);
  }
  get search(): string {
    return this._search;
  }
  _search = "";

  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this.setPaginator(paginator);
  }

  displayedColumns: Array<string> = BASE_COLUMNS;
  disposition = RecentCallDisposition;
  dataSource = new MatTableDataSource<PhoneCallHistoryCall>([]);
  isLoading!: Observable<boolean>;
  pageSize = callHistoryConfig.pageSize;
  pageSizeOptions = PAGE_SIZE_OPTIONS;
  askToLoadMore = false;
  lastFetchedDate!: string;
  expandedDetail: ExpandedCallDetail | undefined = undefined;
  isExpandedDetailLoaded = new BehaviorSubject<boolean>(true);
  isDetailPhoneNumberCall = false;
  expandedRow!: PhoneCallHistoryCall;

  private totalCalls: Array<PhoneCallHistoryCall> = [];
  private unsubscriber = new Subscription();

  constructor(
    private sdrService: SdrService,
    private callHistoryService: CallHistoryService,
    private contactService: ContactService,
    private dateService: DateService,
    private callHistoryDetailService: CallHistoryDetailsService
  ) {}

  ngOnInit() {
    debug && console.warn("RecentCallsComponent.ngOnInit");
    this.isLoading = this.sdrService.state.pipe(map(state => state.loading));

    this.unsubscriber.add(
      combineLatest({
        contacts: this.contactService.state.pipe(
          filter(state => state.contacts.length > 0),
          take(1)
        ),
        calls: this.callHistoryService.state.pipe(pluck("callHistoryCall"))
      }).subscribe(({ calls }) => {
        this.totalCalls = this.formatCalls(calls);
        this.dataSource.data = this.totalCalls;
        this.applyFilter(this.search);
        const lastFetchedCall = this.totalCalls[this.totalCalls.length - 1];
        this.lastFetchedDate = lastFetchedCall
          ? new Date(lastFetchedCall.callTime).toDateString()
          : new Date().toDateString();
      })
    );
  }

  ngOnDestroy() {
    debug && console.warn("RecentCallsComponent.ngOnDestroy");
    this.unsubscriber.unsubscribe();
  }

  showMobileRow(): boolean {
    return this.isMobile;
  }

  showDesktopRow(): boolean {
    return !this.isMobile;
  }

  sendExpandedDetail(call: PhoneCallHistoryCall | undefined): void {
    if (call) {
      this.isExpandedDetailLoaded.next(false);
      const potentialPhoneNumber =
        call.remoteUri.indexOf("@") > -1
          ? call.remoteUri.slice(0, call.remoteUri.indexOf("@"))
          : call.remoteUri;
      this.isDetailPhoneNumberCall = new E164PhoneNumber(potentialPhoneNumber).isValid;
      this.callHistoryDetailService.getExpandedDetail(call).then(res => {
        this.expandedDetail = res;
        this.isExpandedDetailLoaded.next(true);
      });
    }
  }

  goToDetails(): void {
    if (this.expandedDetail?.hubspotPageUrl) {
      window.open(this.expandedDetail.hubspotPageUrl, "_blank");
    }
  }

  /** attempts a sdr refetch and then loads calls for current page  */
  keepSearching(): void {
    debug && console.warn("RecentCallsComponent.keepSearching");
    this.askToLoadMore = false;
    this.callHistoryService.loadMore();
  }

  private setPaginator(paginator: MatPaginator) {
    if (paginator && !this.dataSource.paginator) {
      this.dataSource.paginator = paginator;
      this.unsubscriber.add(
        paginator.page
          .pipe(distinctUntilChanged((a, b) => a.pageIndex === b.pageIndex))
          .subscribe(page => {
            this.canAskToLoadMore();
            if (
              this.search === "" &&
              page.pageIndex * page.pageSize >
                page.length - callHistoryConfig.pageSize * (callHistoryConfig.numPageLinks / 2)
            ) {
              this.callHistoryService.loadMore();
            }
          })
      );
    }
  }

  private applyFilter(filter: string) {
    this.dataSource.filter = filter.trim().toLowerCase();
    this.canAskToLoadMore();
  }

  private canAskToLoadMore() {
    if (this.dataSource.paginator) {
      this.askToLoadMore =
        this.dataSource.filteredData.length <=
        (this.dataSource.paginator.pageIndex + 1) * this.dataSource.paginator.pageSize;
    }
  }

  /** convert CallHistoryCall data into more user friendly readable text for the display */
  private formatCalls(calls: Array<CallHistoryCall>): Array<PhoneCallHistoryCall> {
    return calls.map(call => {
      let contact: Contact | undefined,
        displayName: string,
        displayNamePrefix = "",
        shouldBold = false;
      const callTimeAsDate = new Date(call.callTime);
      const displayDate = this.dateService.displayDate(callTimeAsDate);
      const displayTime = callTimeAsDate.toLocaleTimeString();
      const isIncoming = call.direction === RecentCallDirection.INBOUND;

      if (call.remoteUri.startsWith("sip:")) call.remoteUri = call.remoteUri.slice(4);
      contact =
        this.contactService.findUsingAOR(call.remoteUri) ||
        this.contactService.findUsingName(call.remoteName);

      if (call.remoteName.indexOf("@") > -1 || call.remoteUri.endsWith("@jnctn.net")) {
        const potentialPhoneNumber =
          call.remoteName.indexOf("@") > -1
            ? call.remoteName.slice(0, call.remoteName.indexOf("@"))
            : call.remoteUri.slice(0, call.remoteUri.indexOf("@"));

        const phoneNumber = new E164PhoneNumber(potentialPhoneNumber);
        if (phoneNumber.isValid) {
          call.remoteUri = phoneNumber.e164Uri;
          displayName = phoneNumber.e164DisplayNumber;
          call.remoteName = displayName;
          contact =
            contact ||
            this.contactService.findUsingE164(phoneNumber.e164Uri) ||
            ContactService.createCustomPhoneContact(displayName, phoneNumber);
        } else {
          const uri: OnSIPURI | undefined = OnSIPURI.parseString("sip:" + call.remoteName);
          if (uri && uri.isExtension()) {
            // Don't show Ext prefix for 911 calls
            if (uri.user !== "911") {
              displayNamePrefix = "Ext. ";
            }
            displayName = "" + uri.user;
          } else {
            displayName = call.remoteName;
          }
        }
      } else {
        displayName = call.remoteName;
      }

      if (contact) {
        shouldBold = true;
        if (!call.remoteName.startsWith("Conference ")) {
          call.remoteName = contact.name;
          displayName = contact.name;
        }
        call.remoteUri =
          contact.aors[0] ||
          (contact.e164PhoneNumbers && contact.e164PhoneNumbers[0]) ||
          contact.extensions[0];
      } else {
        const uri = OnSIPURI.parseString(`sip:${call.remoteUri}`);
        if (uri) contact = ContactService.createCustomSIPContact(displayName, uri);
      }
      if (displayName.startsWith("Conference ")) {
        const split = displayName.split("with");
        displayNamePrefix = split[0] + " with ";
        displayName = split[1];
      }

      if (call.localUri.startsWith("sip: ")) call.localUri = call.localUri.slice(4);

      return {
        callTime: call.callTime,
        contact,
        direction: call.direction,
        displayDate,
        displayTime,
        displayName,
        displayNamePrefix,
        disposition: call.disposition,
        duration: call.duration,
        isIncoming,
        localUri: call.localUri,
        ouid: call.ouid,
        remoteName: call.remoteName,
        remoteUri: call.remoteUri,
        shouldBold
      } as PhoneCallHistoryCall;
    });
  }
}
