import { Component, HostBinding } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  templateUrl: "./developer-grid.component.html",
  styleUrls: ["./developer-grid.component.scss"],
  standalone: false
})
export class DeveloperGridComponent {
  @HostBinding("class.onsip-grid-content")
  _dontUse = true;

  smallOrLess: Observable<boolean>;
  medOrLess: Observable<boolean>;

  constructor(private breakpoint: BreakpointObserver) {
    this.smallOrLess = this.breakpoint
      .observe([Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(map(state => state.matches));

    this.medOrLess = this.breakpoint
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(map(state => state.matches));
  }
}
