import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { views } from "@onsip/web/app/phone/views";
import { BehaviorSubject, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { ModalMaterialComponent } from "../shared/components/modal/modal-material.component";
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../shared/const";

interface ColumnsRecord {
  XSmall: Array<string>;
  Small: Array<string>;
  Medium: Array<string>;
  Large: Array<string>;
  XLarge: Array<string>;
}

@Component({
  template: "",
  standalone: false
})
export abstract class AdminTableAbstractComponent<TableItem> implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }
  @ViewChild(MatSort) set sort(sort: MatSort) {
    if (!this.dataSource?.sort && sort && this.defaultSortingColumn) {
      sort.sort({ id: this.defaultSortingColumn, disableClear: false, start: "asc" });
    }
    this.dataSource.sort = sort;
  }

  isDataObtained$ = new BehaviorSubject(false);
  search = new FormControl("");
  dataSource = new MatTableDataSource<TableItem>([]);
  gridTemplateColumns = "";
  displayedColumns: Array<string> = [];
  selectedRows: Array<string | undefined> = [];
  views = views;
  pageSize = PAGE_SIZE;
  pageSizeOptions = PAGE_SIZE_OPTIONS;
  protected defaultSortingColumn = "";
  protected abstract displayedColumnsRecord: ColumnsRecord;

  protected unsubscriber = new Subscription();

  constructor(
    protected breakpointObserver: BreakpointObserver,
    protected cdr: ChangeDetectorRef,
    protected dialog: MatDialog,
    protected translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initDataSource();
    this.onViewportChange();
    this.initSearch();
    this.initSorting();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  removeItem(item: TableItem, event: Event, title?: string): void {
    event.stopPropagation();

    const modal = this.dialog.open(ModalMaterialComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"],
      data: {
        title: this.translate.instant("ONSIP_I18N.REMOVE_VALUE", {
          value: title || this.getItemName(item)
        }),
        message: this.translate.instant("ONSIP_I18N.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_VALUE", {
          value: this.getItemName(item)
        }),
        primaryBtnText: "REMOVE",
        primaryBtnFlat: true
      }
    });

    this.unsubscriber.add(
      modal
        .afterClosed()
        .pipe(take(1))
        .subscribe(retObj => {
          if (retObj && retObj.doPrimaryAction) {
            this.performDeleteRequest(item);
          }
        })
    );
  }

  /**
    switch (property) {
      case "name":
        return item.name.toLowerCase();
      case "extensions":
        return item?.extensions.join(", ").toLowerCase();
      default:
        return item.name.toLowerCase();
    }
   */
  protected abstract getItemValueByColumn(item: TableItem, columnName: string): string | number;

  /** Put browse data and init stream to set data to DataSource here */
  protected abstract initDataSource(): void;

  /** return item.name or item.aor etc to display entity name in confirm delete modal */
  protected abstract getItemName(item: TableItem): string;

  /** this.entityService.delete() */
  protected abstract performDeleteRequest(item: TableItem): void;

  /** set data filter predicate for table */
  protected setDataFilter(): void {}

  protected setDataSourceFilter(searchQuery: string): void {
    this.dataSource.filter = searchQuery.trim().toLowerCase();
  }

  protected initSorting(): void {
    this.dataSource.sortingDataAccessor = (item: TableItem, columnName: string) =>
      this.getItemValueByColumn(item, columnName);
  }

  private onViewportChange(): void {
    this.unsubscriber.add(
      this.breakpointObserver
        .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large])
        .subscribe(result => {
          if (result.breakpoints[Breakpoints.Large]) {
            this.displayedColumns = this.displayedColumnsRecord.Large;
          } else if (result.breakpoints[Breakpoints.Medium]) {
            this.displayedColumns = this.displayedColumnsRecord.Medium;
          } else if (result.breakpoints[Breakpoints.Small]) {
            this.displayedColumns = this.displayedColumnsRecord.Small;
          } else if (result.breakpoints[Breakpoints.XSmall]) {
            this.displayedColumns = this.displayedColumnsRecord.XSmall;
          } else {
            this.displayedColumns = this.displayedColumnsRecord.XLarge;
          }
          this.cdr.markForCheck();
        })
    );
  }

  private initSearch(): void {
    this.unsubscriber.add(
      this.search.valueChanges.subscribe(value => {
        if (value) {
          this.setDataSourceFilter(value);
          this.dataSource.paginator?.firstPage();
        } else {
          this.dataSource.filter = "";
        }
      })
    );
  }
}
