import {
  ElementRef,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from "@angular/core";
import {
  CdkOverlayOrigin,
  Overlay,
  ConnectionPositionPair,
  FlexibleConnectedPositionStrategy,
  RepositionScrollStrategy
} from "@angular/cdk/overlay";
import { fromEvent, Subscription } from "rxjs";
import { filter } from "rxjs/operators";

/**
 * When using onsip-popup-container, it requires the overlayOrigin input.
 * The overlayOrigin must apply cdkOverlayOrigin directive.
 * OverlayComponent will create an overlay with position relative to the overlayOrigin.
 * The default trigger for the overlay is open on hover.
 * The onsip-popup-container must contain the component that will popup.
 * Example:
 * <div cdkOverlayOrigin
 *   #dialpadButton="cdkOverlayOrigin">
 *   <mat-icon [style.color]="'#ffffff'">dialpad</mat-icon>
 *   <onsip-popup-container [overlayOrigin]="dialpadButton">
 *     <onsip-dialpad></onsip-dialpad>
 *   </onsip-popup-container>
 * </div>
 */

@Component({
  selector: "onsip-popup-container",
  templateUrl: "./popup-container.component.html",
  styleUrls: ["./popup-container.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PopupContainerComponent implements OnInit, OnDestroy {
  @Input() overlayOrigin!: CdkOverlayOrigin;
  @Input() sidebar = false;
  @Input() trigger: "open" | "click" | "hover" = "click"; // default is open on click
  /** If hasBackdrop is true the popup will close when a user clicks anywhere outside of the popup */
  @Input() hasBackdrop!: boolean;
  @Output() dismissed = new EventEmitter<void>();
  @ViewChild("content") dialog!: ElementRef;

  arrowPosition!: "above" | "below" | "sidebar";
  arrowRightMargin: string | undefined = undefined;
  positionStrategy!: FlexibleConnectedPositionStrategy;
  scrollStrategy!: RepositionScrollStrategy;
  isOpened = false;

  protected unsubscriber = new Subscription();

  constructor(private changeDetectorRef: ChangeDetectorRef, private overlay: Overlay) {}

  ngOnInit(): void {
    this.hasBackdrop = this.hasBackdrop ?? this.trigger === "click";
    const CdkOverlayOriginEl: HTMLElement = this.overlayOrigin.elementRef.nativeElement;
    CdkOverlayOriginEl.style.cursor = "pointer";
    this.configureOverlay(CdkOverlayOriginEl);

    if (this.trigger === "open") {
      // keep popup open
      this.changeState(true);
    } else if (this.trigger === "click") {
      // toggle popup display on click
      this.unsubscriber.add(
        fromEvent(CdkOverlayOriginEl, "click").subscribe(() => {
          this.changeState(!this.isOpened);
        })
      );
    } else {
      // toggle popup display on hover
      this.unsubscriber.add(
        fromEvent(CdkOverlayOriginEl, "mouseenter")
          .pipe(filter(() => !this.isOpened))
          .subscribe(() => {
            this.changeState(true);
          })
      );

      this.unsubscriber.add(
        fromEvent(document, "mousemove")
          .pipe(
            filter(() => this.isOpened),
            filter(event => this.isMovedOutside(CdkOverlayOriginEl, event))
          )
          .subscribe(() => {
            this.changeState(false);
          })
      );
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  connectedOverlayDetach(): void {
    if (this.trigger !== "open") {
      this.changeState(false);
      this.dismissed.emit();
    }
  }

  getPositions(): Array<ConnectionPositionPair> {
    if (this.sidebar) {
      return [
        {
          originX: "end",
          originY: "bottom",
          overlayX: "end",
          overlayY: "top"
        }
      ];
    } else {
      return [
        {
          originX: "center",
          originY: "bottom",
          overlayX: "center",
          overlayY: "top"
        },
        {
          originX: "center",
          originY: "top",
          overlayX: "center",
          overlayY: "bottom"
        }
      ];
    }
  }

  private changeState(isOpened: boolean): void {
    this.isOpened = isOpened;
    this.changeDetectorRef.markForCheck();
  }

  private configureOverlay(origin: HTMLElement): void {
    this.getOverlayPosition(origin);
    this.scrollStrategy = this.overlay.scrollStrategies.reposition();
    if (this.sidebar) {
      this.arrowRightMargin =
        String(this.overlayOrigin.elementRef.nativeElement.clientWidth / 2 - 4) + "px";
    }
  }

  private getOverlayPosition(origin: HTMLElement): void {
    this.positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withPositions(this.getPositions())
      .withFlexibleDimensions(false)
      .withPush(false);

    // subscribe to positionStrategy to determine directon of arrow
    this.unsubscriber.add(
      this.positionStrategy.positionChanges.subscribe(positions => {
        if (this.sidebar) {
          this.arrowPosition = "sidebar";
        } else if (positions.connectionPair.originY === "bottom") {
          this.arrowPosition = "below";
        } else if (positions.connectionPair.originY === "top") {
          this.arrowPosition = "above";
        }
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  private isMovedOutside(CdkOverlayOriginEl: any, event: Event): boolean {
    return !(
      CdkOverlayOriginEl.contains(event.target) || this.dialog.nativeElement.contains(event.target)
    );
  }
}
