import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject
} from "@angular/core";
import { NotificationsService } from "../../shared/services/notifications/notifications.service";
import { SentryReportComponent } from "../sentry-report/sentry-report.component";
import { MatDialog } from "@angular/material/dialog";
import { ModalMaterialComponent } from "../../shared/components/modal/modal-material.component";
import { Subscription, defer, filter, from, map, take } from "rxjs";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { UserOauth2AccessTokenService } from "@onsip/common/services/api/resources/userOAuth2AccessToken/user-oauth2-access-token.service";
import { Config } from "../../../../common/config";
import { TranslateService } from "@ngx-translate/core";
import { FailoverInfo } from "../app-settings.interface";
import { AdvQueueService } from "@onsip/common/services/api/resources/queue/adv-queue.service";
import { AppsettingsService } from "../appsettings.service";
import { TelephoneNumberAddressService } from "@onsip/common/services/api/resources/telephoneNumberAddress/telephone-number-address.service";
import { UserAddressService } from "@onsip/common/services/api/resources/userAddress/user-address.service";
@Component({
  selector: "onsip-app-controls",
  templateUrl: "./app-controls.component.html",
  styleUrl: "./app-controls.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AppControlsComponent implements OnInit, OnDestroy {
  notificationsService = inject(NotificationsService);
  apiSessionService = inject(ApiSessionService);
  userOauth2AccessTokenService = inject(UserOauth2AccessTokenService);
  dialog = inject(MatDialog);
  translateService = inject(TranslateService);
  queueService = inject(AdvQueueService);
  appsettingsService = inject(AppsettingsService);
  telephoneNumberAddressService = inject(TelephoneNumberAddressService);
  userAddressService = inject(UserAddressService);
  cdrRef = inject(ChangeDetectorRef);

  unsubscriber = new Subscription();

  sipAddress = "";
  readonly foreverAddress = "notAddress";

  failoverSelection: string | undefined;
  failoverOptions: Array<FailoverInfo> = [];
  failoverTime = "Hangup after 2 minutes";
  showFailoverAddress = false;

  secondsArr: Array<string> = ["Hangup after 2 minutes"];

  readonly versionNumber: string = Config.VERSION_NUMBER;

  isNotificationsDisabled = !window.Notification || window.Notification.permission === "denied";

  thirdPartySignInService: string | undefined;
  tokenId: string | undefined;

  showQueueWarnings$ = defer(() => from(this.queueService.getSmartQueuesWithoutOrgId())).pipe(
    map(queues => queues.length > 0)
  );

  notificationTypes$ = this.notificationsService.state.pipe(
    map(state => Object.keys(state.notifications))
  );
  notifications$ = this.notificationsService.state.pipe(map(state => state.notifications));
  allNotificationsEnabled$ = this.notificationsService.state.pipe(
    map(state => Object.values(state.notifications).every(val => !!val))
  );

  info$ = this.apiSessionService.state.pipe(
    filter(state => !!state.thirdPartySignInInfo),
    map(state => state.thirdPartySignInInfo)
  );

  ngOnInit(): void {
    this.handleThirdPartySignInInfo();
    this.fillSecondsArr();
    this.handleAppUpdates();
    this.telephoneNumberAddressServiceHandle();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  setNotification(type: string, checked: boolean) {
    console.log(type, checked);
    this.notificationsService.setNotification(type, checked);
  }

  onProvideFeedbackClick(): void {
    this.dialog.open(SentryReportComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"]
    });
  }

  deleteThirdPartySignIn(): void {
    const thirdPartySignInService = this.thirdPartySignInService;
    const tokenId = this.tokenId;
    const serviceName: string = (thirdPartySignInService || "").replace("Login", "");

    const modal = this.dialog.open(ModalMaterialComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"],
      data: {
        title: this.translateService.instant("ONSIP_I18N.DISCONNECT_VALUE_ACCOUNT", {
          value: serviceName
        }),
        message: this.translateService.instant(
          "ONSIP_I18N.WOULD_YOU_LIKE_TO_DISCONNECT_YOUR_VALUE_ACCOUNT_FROM_YOUR_ONSIP_ACCOUNT_THIS_WILL_SIGN_YOU_OUT",
          { value: serviceName }
        ),
        primaryBtnText: this.translateService.instant("ONSIP_I18N.DISCONNECT"),
        primaryBtnFlat: true
      }
    });

    this.unsubscriber.add(
      modal
        .afterClosed()
        .pipe(take(1))
        .subscribe(response => {
          if (response && response.doPrimaryAction && tokenId) {
            this.userOauth2AccessTokenService.UserOAuth2AccessTokenDelete(tokenId).then(() => {
              this.thirdPartySignInService = undefined;
              this.platformCloseWindow();
            });
          }
        })
    );
  }

  updateFailover(newValue: string | undefined): void {
    this.failoverSelection = newValue || this.failoverSelection;
    if (!this.sipAddress) {
      throw new Error("updateFailover: no sip address set");
    }

    if (this.failoverTime === "Hangup after 2 minutes") {
      this.showFailoverAddress = false;
      this.failoverSelection = this.foreverAddress;

      this.userAddressService.userAddressEditDefaultAddress({
        Address: this.sipAddress,
        Timeout: 30,
        DefaultAddress: ""
      });
    } else {
      const timeParam: number = parseInt(this.failoverTime.replace(" seconds", ""));

      this.showFailoverAddress = true;
      if (!this.failoverSelection || this.failoverSelection === this.foreverAddress) {
        this.failoverSelection = this.foreverAddress;
        this.failoverOptions.unshift({
          Name: "Please select a backup sip address",
          Address: this.foreverAddress
        });

        this.checkFailoverOptionsForDupes();
      } else {
        this.userAddressService.userAddressEditDefaultAddress({
          Address: this.sipAddress,
          DefaultAddress: this.failoverSelection,
          Timeout: timeParam
        });
      }
    }
  }

  private handleThirdPartySignInInfo() {
    this.unsubscriber.add(
      this.apiSessionService.state
        .pipe(
          filter(state => !!state.thirdPartySignInInfo),
          map(state => state.thirdPartySignInInfo),
          take(1)
        )
        .subscribe(info => {
          if (info) {
            this.thirdPartySignInService = info.Service;
            this.tokenId = info.UserOAuth2AccessTokenId;
          }
        })
    );
  }

  private platformCloseWindow(): void {
    if (Config.IS_DESKTOP || Config.IS_WEB) {
      document.cookie =
        "sessionId=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; domain=.onsip.com;secure;";
      document.cookie =
        "userId=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; domain=.onsip.com;secure;";
      if (Config.IS_WEB) {
        window.location.href = ".";
      } else {
        window.electron.sendMessage("log-out");
      }
    }
  }

  private fillSecondsArr() {
    for (let i = 0; i < 24; i++) {
      const seconds: number = (i + 1) * 5;
      this.secondsArr.push(seconds + " seconds");
    }
  }

  private telephoneNumberAddressServiceHandle() {
    this.telephoneNumberAddressService
      .telephoneNumberAddressBrowseWithoutOrgId({
        Limit: 25000
      })
      .then(response => {
        if (response.status === "success") {
          const telephoneNumberAddresses = Object.values(response.data);
          for (const eachNumber of telephoneNumberAddresses) {
            const addressStr: string = eachNumber.username + "@" + eachNumber.domain;

            this.failoverOptions.push({
              Name: eachNumber.name + " (" + addressStr + ")",
              Address: addressStr
            });
          }

          this.checkFailoverOptionsForDupes();
        }
      });
  }

  private handleAppUpdates() {
    this.unsubscriber.add(
      this.appsettingsService.loadProfile$.subscribe(userSummary => {
        if (userSummary) {
          if (userSummary.sipAddress) this.sipAddress = userSummary.sipAddress;
          if (userSummary.defaultAddress?.username) {
            const failover = userSummary.defaultAddress,
              failoverTimeout = userSummary.timeout,
              failoverAddress = failover.username + "@" + failover.domain,
              failoverName = failover.name + " (" + failoverAddress + ")";

            if (failoverTimeout) {
              this.showFailoverAddress = true;
            }
            this.failoverTime = failoverTimeout + " seconds";

            this.failoverOptions.unshift({
              Name: failoverName,
              Address: failoverAddress
            });

            this.checkFailoverOptionsForDupes();

            this.failoverSelection = this.failoverOptions[0].Address;
          } else {
            this.failoverTime = "Hangup after 2 minutes";
            this.showFailoverAddress = false;
          }

          if (userSummary.previousDefaultAddress?.username) {
            const prevName: string = userSummary.previousDefaultAddress.name,
              prevDomain: string = userSummary.previousDefaultAddress.domain,
              prevUsername: string = userSummary.previousDefaultAddress.username,
              prevAddress: string = prevUsername + "@" + prevDomain,
              failoverCompleteName: string = prevName + " (" + prevAddress + ")";

            this.failoverOptions.unshift({
              Name: failoverCompleteName,
              Address: prevAddress
            });

            this.checkFailoverOptionsForDupes();
          }
          this.cdrRef.markForCheck();
        }
      })
    );
  }

  // Note: only removes one duplicate and then breaks out of loop, should be enough for our purposes
  private checkFailoverOptionsForDupes(): void {
    // dupe = duplicate
    const countObj: Record<string, number> = {};
    let removedDupeArr: Array<FailoverInfo> | undefined;

    for (let i = 0; i < this.failoverOptions.length; i++) {
      const option = this.failoverOptions[i];
      if (countObj[option.Address]) {
        const beginning: Array<FailoverInfo> = this.failoverOptions.slice(0, i),
          ending: Array<FailoverInfo> = this.failoverOptions.slice(
            i + 1,
            this.failoverOptions.length
          );

        removedDupeArr = beginning.concat(ending);
        break;
      } else {
        countObj[option.Address] = 1;
      }
    }

    if (removedDupeArr) {
      this.failoverOptions = removedDupeArr;
    }
  }
}
