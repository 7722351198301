import { Component, HostBinding, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "onsip-app-settings",
  templateUrl: "./app-settings.component.html",
  styleUrls: ["./app-settings.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AppSettingsComponent {
  @HostBinding("class.onsip-grid-content")
  _dontUse = true;
}
