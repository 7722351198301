import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewContainerRef,
  AfterViewInit
} from "@angular/core";
import Lottie from "lottie-web";

@Component({
  selector: "onsip-animation",
  template: "", // no template
  changeDetection: ChangeDetectionStrategy.OnPush,
  // no styles, delegate that to the parent
  standalone: false
})
export class AnimationComponent implements AfterViewInit {
  @Input() filename!: string;
  @Input() preserveAspectRatio = "xMidYMid meet"; // https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/preserveAspectRatio
  @Input() set loop(loop: boolean) {
    this._loop = typeof loop === "number" ? loop : loop !== undefined;
  }
  private _loop: boolean | number = false; // make animations only run once by default, otherwise they are extremely CPU intensive when idle

  constructor(private vcRef: ViewContainerRef) {}

  ngAfterViewInit(): void {
    Lottie.loadAnimation({
      container: this.vcRef.element.nativeElement,
      renderer: "svg",
      loop: this._loop,
      autoplay: true,
      path: `resources/anim/${this.filename}.json`,
      rendererSettings: {
        preserveAspectRatio: this.preserveAspectRatio
      }
    });
  }
}
