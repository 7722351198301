import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  HostBinding,
  ChangeDetectionStrategy
} from "@angular/core";
import { Router } from "@angular/router";

import { Subscription, BehaviorSubject } from "rxjs";

import { LogService } from "../../../../common/services/logging";

import { SupportService } from "../../../../common/services/support/support.service";
import { VideoConferenceService } from "../video-conference.service";

import { views } from "../../../app/phone/views";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { Role } from "@onsip/common/services/api/role";

@Component({
  selector: "onsip-conference-page",
  templateUrl: "./conference-page.component.html",
  styleUrls: ["./conference-page.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ConferencePageComponent implements OnInit, OnDestroy {
  @HostBinding("class.onsip-grid-content")
  _dontUse = true;

  @Input() anonymous = false;
  @Input() sidebar = false;
  hasStarted = new BehaviorSubject<boolean>(false);
  fromDisplay: string | undefined = undefined;
  subHeader: string | undefined;
  header: string | undefined;
  instructions: string | undefined;
  conferenceName = "N/A";
  conferenceUri = "N/A";
  private unsubscriber = new Subscription();

  constructor(
    private router: Router,
    private videoConferenceService: VideoConferenceService,
    private log: LogService,
    private supportService: SupportService,
    private apiSession: ApiSessionService
  ) {}

  ngOnInit(): void {
    if (this.anonymous) {
      this.conferenceUri = this.getParameterByName("a") || "N/A";
      this.conferenceName = this.getParameterByName("n") || this.conferenceUri;

      this.subHeader = "Want to join " + this.conferenceName + "?";
      this.instructions = "Enter your name below, and click the 'Conference' button to begin.";

      if (!this.supportService.isWebrtcSupported()) {
        this.log.error("WebRTC unsupported!");
        return;
      }
    } else {
      this.header = "Video Conference";
      this.subHeader =
        "Enter an existing or new conference name, and click the 'Conference' button to begin.";
    }

    this.unsubscriber.add(
      this.videoConferenceService.event.subscribe(event => {
        if (event.conferenceUri && event.userAgent && event.mediaStream) {
          this.whenConferenceStarts();
          this.fromDisplay = event.localDisplayName;
        } else if (
          !event.conferenceUri &&
          !event.userAgent &&
          !event.mediaStream &&
          this.hasStarted.value
        ) {
          this.whenConferenceEnds();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  private whenConferenceStarts(): void {
    this.hasStarted.next(true);
  }

  private whenConferenceEnds(): void {
    this.hasStarted.next(false);
    if (this.anonymous) {
      this.subHeader = "Want to join " + this.conferenceName + " again?";
      this.instructions = "Click 'Conference' to rejoin.";
    } else {
      if (this.videoConferenceService.getNewVideoConferenceState() !== "switch") {
        if (
          this.apiSession.stateValue.parentUserId &&
          this.apiSession.stateValue.loggedInRole !== Role.SuperUser
        ) {
          this.router.navigate([views.ADMIN_PBX_OVERVIEW]);
        } else {
          this.router.navigate([views.HOME]);
        }
      }
    }
  }

  private getParameterByName(name: string): string {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results: any = regex.exec(location.search);

    // eslint-disable-next-line no-null/no-null
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }
}
