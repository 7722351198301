import { Component, ChangeDetectionStrategy, Inject, OnInit, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";

import { Observable, BehaviorSubject, Subscription } from "rxjs";
import { filter, take, tap, switchMapTo } from "rxjs/operators";

import { AnalyticsService } from "../shared/components/analytics/analytics.service";
import { CallAudioService } from "./call-audio.service";
import { CallControllerService } from "../../../common/services/call-controller.service";
import { CallTimerService } from "../../../common/services/call-timer.service";
import { ContactService } from "../../../common/services/contact/contact.service";
import { LogService } from "../../../common/services/logging";
import { FirestoreCallService } from "../../../common/services/sayso/firestore-call.service";
import { HubspotInfoService } from "../shared/components/hubspot/services/hubspot-info.service";

import { Contact as HubspotContact } from "../shared/components/hubspot/services/lib/contact";

import { Call } from "../../../common/services/sayso/lib/call";
import { CallState } from "../../../common/libraries/sip/call-state";
import { Contact } from "../../../common/interfaces/contact";
import { isEndCallEvent } from "../../../common/libraries/sip/call-event";
import { OnSIPURI } from "../../../common/libraries/onsip-uri";
import { AcceptedCallService } from "./accepted-call.service";
import { E164PhoneNumber } from "../../../common/libraries/e164-phone-number";
import { views } from "../../app/phone/views";

@Component({
  selector: "onsip-incoming-call",
  templateUrl: "./incoming-call.component.html",
  styleUrls: ["./incoming-call.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class IncomingCallComponent implements OnInit, OnDestroy {
  // Call Status Params
  callToUri = "";
  callFromDisplayName: string | undefined;
  callFromUri = "";

  saysoCallDetails = new BehaviorSubject<boolean>(false);

  /** Main call state object */
  currentCall: CallState | undefined;

  /** Contact object, will be undefined for anon calls */
  contact: Contact | undefined;

  /** Sasyo Call from firestore call service */
  saysoCallState: Call | undefined;

  /** Formatted time left to answer call based off saysoConnectingAt and sasyoDelay */
  saysoDisplayTimer: Observable<number> | undefined;

  /** see CallItem.where */
  saysoCallFromWhere: string | undefined;

  /** see CallItem.link */
  saysoCallFromLink: string | undefined;

  /** "targetDisplay" property from sayso call */
  saysoTargetDisplay: string | undefined;

  /** failover attempt number from sayso call */
  saysoFailoverLabel: number | undefined;

  private unsubscriber = new Subscription();

  private static getParsedUri(uri: string | undefined): string {
    let parsedUri = "";
    if (uri) {
      const phoneNumber = new E164PhoneNumber(uri.slice(0, uri.indexOf("@")));
      if (phoneNumber.isValid) {
        // if a phone number was dialed
        parsedUri = phoneNumber.e164Uri;
      } else {
        const onsipUri: OnSIPURI | undefined = OnSIPURI.parseString(uri);
        if (onsipUri && onsipUri.isExtension()) {
          // if EXT was dialed
          parsedUri = "Ext. " + onsipUri.user;
        } else if (uri.endsWith("anonymous.invalid")) {
          parsedUri = "Anonymous Caller";
        } else if (onsipUri && onsipUri.aor) {
          // if there is an onsipUri aor, set parsedUri to the uri aor
          parsedUri = onsipUri.aor;
        } else {
          // if SIP Address was dialed
          parsedUri = uri.slice(4);
        }
      }
    }
    return parsedUri;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { call: CallState },
    private dialogRef: MatDialogRef<IncomingCallComponent>,
    private snackBar: MatSnackBar,
    private log: LogService,
    private callAudioService: CallAudioService,
    private callControllerService: CallControllerService,
    private callTimerService: CallTimerService,
    private contactService: ContactService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private firestoreCall: FirestoreCallService,
    private hubspotInfoService: HubspotInfoService,
    private acceptedCallService: AcceptedCallService
  ) {}

  ngOnInit(): void {
    this.currentCall = this.data.call;
    this.contact = this.currentCall
      ? this.contactService.findUsingAOR(this.currentCall.remoteUri.slice(4))
      : undefined;
    this.initCallStatus();
    this.initSaysoCall();

    // Subscribe to End of Call Events for currentCalls UUID close dialog on end call
    this.unsubscriber.add(
      this.callControllerService
        .getCallEventObservable()
        .pipe(
          filter(
            event =>
              isEndCallEvent(event) && !!this.currentCall && event.uuid === this.currentCall.uuid
          )
        )
        .subscribe(() => this.dialogRef.close())
    );
    // Subscribe to Accepted Call State to prevent accepting the same call multiple times
    this.unsubscriber.add(
      this.acceptedCallService.acceptedCallUUID.subscribe(() => {
        if (
          !!this.currentCall &&
          this.acceptedCallService.hasConnectingAcceptedCall(this.currentCall.uuid)
        ) {
          this.dialogRef.close();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  accept(isVideo: boolean = false): void {
    this.dialogRef.close(); // Close dialog on accept click
    if (
      this.currentCall &&
      !this.acceptedCallService.hasConnectingAcceptedCall(this.currentCall.uuid)
    ) {
      this.acceptedCallService.setAcceptedCall(this.currentCall.uuid);
      this.callAudioService.approveAudio();
      this.callControllerService.acceptCall(this.currentCall.uuid, { audio: true, video: isVideo });
      // do not navigate to call page if user is in the administrators tab
      if (this.router.url.includes("/administrators")) return;
      this.router.navigate([views.CALL], {
        state: {
          callUuid: this.currentCall.uuid
        }
      });
    }
  }

  endCall(): void {
    if (this.currentCall) {
      this.callControllerService.endCall(this.currentCall.uuid);
    }
    this.snackBar.open("Call declined", "", {
      duration: 5000
    });
    this.dialogRef.close(); // Close dialog on decline click
  }

  muteAudio(): void {
    if (this.currentCall) {
      const action: string = (this.currentCall.audio ? "M" : "Unm") + "ute Audio"; // analytics message
      if (this.currentCall.audio) {
        this.callAudioService.localMute(this.currentCall.uuid);
      } else {
        this.callAudioService.localUnmute(this.currentCall.uuid);
      }
      this.log.debug("call audio mute set to", this.currentCall.audio);
      this.analyticsService.sendCallEvent(action, this.currentCall, {
        "Audio Muted": this.currentCall.audio,
        "Video Muted": !this.currentCall.video
      });
    }
  }

  private initCallStatus() {
    let hubspotName: string | undefined;

    // Subscribe to call state set call status params (from and to fields)
    this.unsubscriber.add(
      this.callControllerService.state.subscribe(() => {
        if (this.currentCall) {
          this.callToUri = IncomingCallComponent.getParsedUri(this.currentCall.localUri); // Sets callToUri and callToDisplayName
          this.callFromUri = IncomingCallComponent.getParsedUri(this.currentCall.remoteUri);

          if (hubspotName) {
            this.callFromDisplayName = hubspotName;
          } else if (this.currentCall.remoteDisplayName) {
            this.callFromDisplayName = this.currentCall.remoteDisplayName;
          }
        }
      })
    );

    this.unsubscriber.add(
      this.hubspotInfoService.state
        .pipe(
          filter(
            () =>
              !!this.currentCall &&
              !!this.hubspotInfoService.fetchHubspotContact(this.currentCall.uuid)
          ),
          take(1)
        )
        .subscribe(() => {
          if (!this.currentCall) {
            return;
          }
          const contact: HubspotContact | undefined = this.hubspotInfoService.fetchHubspotContact(
            this.currentCall.uuid
          );
          if (contact) {
            hubspotName = contact.firstname + " " + contact.lastname;
            this.callFromDisplayName = hubspotName;
          }
        })
    );
  }

  private initSaysoCall() {
    if (this.currentCall && this.currentCall.xData) {
      this.saysoCallState = this.firestoreCall.getCall(this.currentCall.xData.slice(4));
      if (
        this.saysoCallState &&
        !!this.callTimerService.getCallTimerFromUuid(this.currentCall.uuid)
      ) {
        // else not a sayso call won't display
        this.saysoDisplayTimer = this.saysoCallState.state.pipe(
          tap(saysoCall => {
            this.saysoCallFromWhere =
              (saysoCall.context.browser && saysoCall.context.browser.title) || "Unknown";
            this.saysoCallFromLink =
              (saysoCall.context.browser && saysoCall.context.browser.href) || "";
            this.saysoTargetDisplay = saysoCall.targetDisplay;
            this.saysoCallDetails.next(true);
          }),
          filter(saysoCall => {
            this.saysoFailoverLabel =
              Object.keys(saysoCall.sessions).length > 1
                ? Object.keys(saysoCall.sessions).length
                : undefined;
            // HACK: seems to be the only way to tell the difference between a transfer call and a failover,
            // can't rely on a connectedAt because the first rep could hang up before the 15 sec countdown
            // should refactor on the saysoo side to raise the blindTransferInProgress flag or something like that
            const unendedSessions = Object.keys(saysoCall.sessions).filter(
              session => !saysoCall.sessions[session].endedAt
            );
            // no delay => teampage call, multiple unendedsession => transfer, don't show timer
            return !!saysoCall.delay && unendedSessions.length <= 1;
          }),
          switchMapTo(this.callTimerService.getCallTimerFromUuid(this.currentCall.uuid))
        );
      }
    }
  }
}
