import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ZendeskGetTickets } from "@onsip/common/services/api/resources/zendeskGetTickets/zendesk-get-tickets";
import { ZendeskGetTicketsService } from "@onsip/common/services/api/resources/zendeskGetTickets/zendesk-get-tickets.service";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";

@Component({
  selector: "onsip-support-tickets",
  templateUrl: "./support-tickets.component.html",
  styleUrls: ["./support-tickets.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class SupportTicketsComponent implements OnInit {
  supportTickets!: Array<ZendeskGetTickets>;

  constructor(
    private zendeskTicketService: ZendeskGetTicketsService,
    private snackbar: SnackbarService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.zendeskTicketService.zendeskGetTickets().then(result => {
      if (result.status === "success") {
        this.supportTickets = Object.values(result.data)
          .filter(ticket => ticket.status !== "closed" && ticket.isPublic)
          .sort(
            // sort in descending order based on date created
            (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        this.cdRef.markForCheck();
      } else {
        const errMsg = result.data.message;
        this.snackbar.openSnackBar(errMsg, "error");
        console.error(errMsg);
        this.zendeskTicketService.clearErrors();
      }
    });
  }
}
