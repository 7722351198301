import {
  Component,
  Input,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { VolumeSliderComponent } from "../slider/volume-slider.component";

@Component({
  selector: "onsip-volume-popup",
  templateUrl: "./volume-popup.component.html",
  styleUrls: ["volume-popup.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class VolumePopupComponent implements AfterViewInit, OnDestroy {
  @Input() call: string | undefined;
  @Input() voicemail = false;
  @Input() vertical = false;
  @Input() event!: Observable<void>;

  @ViewChild("slider") volumeSlider!: VolumeSliderComponent;

  volume = 80;

  private unsubscriber = new Subscription();

  ngAfterViewInit() {
    this.unsubscriber.add(this.event.subscribe(() => this.volumeSlider.mute()));
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
  }
}
