import { Component, OnInit, OnDestroy, HostBinding, ChangeDetectionStrategy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";

import { Contact } from "../../../common/interfaces/contact";
import { ContactService } from "../../../common/services/contact/contact.service";
import { OnSIPURI } from "../../../common/libraries/onsip-uri";

type Dept = "sales" | "support";

@Component({
  templateUrl: "contact-onsip.component.html",
  styleUrls: ["contact-onsip.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ContactOnSIPComponent implements OnInit, OnDestroy {
  @HostBinding("class.onsip-grid-content")
  _dontUse = true;

  dept: Dept | undefined;
  contact: Contact | undefined;
  mediumOrBigger!: Observable<boolean>;
  private unsubscriber = new Subscription();

  constructor(private route: ActivatedRoute, private breakpointObserver: BreakpointObserver) {}

  ngOnInit() {
    this.unsubscriber.add(
      this.route.data.subscribe(data => {
        function isValidDept(dept: unknown): dept is Dept {
          return dept === "sales" || dept === "support";
        }
        if (data.dept && isValidDept(data.dept)) {
          this.dept = data.dept;
          this.contact = this.makeDeptContact(data.dept);
        } else {
          throw new Error("Route data has no valid dept");
        }
      })
    );

    this.mediumOrBigger = this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(map(state => state.matches));
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
  }

  private makeDeptContact(username: Dept): Contact {
    const uri = OnSIPURI.parseString(`sip:${username}@onsip.com`);
    if (uri) {
      return ContactService.createCustomSIPContact(
        `OnSIP ${username === "sales" ? "Sales" : "Support"}`,
        uri
      );
    } else {
      throw new Error(
        "ContactOnSIPComponent.makeDeptContact: uri parse failed - should never happen"
      );
    }
  }
}
