import { Pipe, PipeTransform } from "@angular/core";
import { isBrightColor } from "@onsip/common/interfaces/get-contrast-color";

/** ### Strategy for picking foreground colors with custom background color
 *  Light background -> black text color | Dark background -> white text color
 */
@Pipe({
  name: "textColorForCustomBackground",
  standalone: false
})
export class TextColorForCustomBackground implements PipeTransform {
  transform(background: string | undefined | null): string {
    return background ? (isBrightColor(background) ? "#000000" : "#ffffff") : "initial";
  }
}
