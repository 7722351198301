import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
  forwardRef,
  ChangeDetectorRef
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { ModalMaterialComponent } from "../../modal/modal-material.component";
import { TranslateService } from "@ngx-translate/core";
import {
  FormControl,
  ValidatorFn,
  ValidationErrors,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from "@angular/forms";
import { E2eLocators } from "@onsip/e2e/configs/locators";

@Component({
  selector: "onsip-edit-item-list",
  templateUrl: "./edit-item-list.component.html",
  styleUrls: ["./edit-item-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => EditItemListComponent),
      multi: true
    }
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    "[style.--list-height]": "height"
  },
  standalone: false
})
export class EditItemListComponent implements ControlValueAccessor, OnDestroy, OnInit {
  @Input() listItems: Array<string> = [];
  @Input() itemType!: string;
  @Input() validators: Array<ValidatorFn> = [];
  @Input() hint!: string;
  @Input() suffixText!: string;
  @Input() prefixButton = false;
  @Input() getErrorMessage!: (errors: ValidationErrors | null) => string;
  @Input() itemNamePlural!: string;
  @Input() height = "112px";
  @Output() edit = new EventEmitter<Array<string>>();

  E2eLocators = E2eLocators;
  showAddForm = false;
  /** form control tied to input field for adding new items */
  newItemControl = new FormControl();
  /** Hidden formControl we interface to keep track of list items */
  _formControl = new FormControl(<Array<string>>[]);
  private unsubscriber = new Subscription();

  @Input() formatter = (v: string) => v;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef
  ) {}

  onChange: (val: any) => void = () => {};
  onTouched: () => void = () => {};

  /** Convenience for the current selected items */
  get currentSelections(): Array<string> {
    return this._formControl.value || [];
  }

  ngOnInit(): void {
    this.newItemControl.setValidators(this.validators);
    if (this.listItems) this._formControl.setValue(this.listItems);
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  toggleAddForm(): void {
    this.showAddForm = !this.showAddForm;
  }

  saveNewItem(event: Event): void {
    event.preventDefault();
    if (this.newItemControl.valid) {
      if (this.newItemControl.value) this.currentSelections.push(this.newItemControl.value);
      this._formControl.setValue([...this.currentSelections]);
      this.newItemControl.reset();
      this.edit.emit(this.currentSelections);
      this.toggleAddForm();
    }
  }

  delete(item: any): void {
    const modal = this.dialog.open(ModalMaterialComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"],
      data: {
        title: `${this.translate.instant("ONSIP_I18N.DELETE")} ${this.itemType || "Item"}`,
        message: this.translate.instant("ONSIP_I18N.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_VALUE", {
          value: `this ${this.itemType || "item"}`
        }),
        primaryBtnText: this.translate.instant("ONSIP_I18N.DELETE"),
        primaryBtnFlat: true
      }
    });

    this.unsubscriber.add(
      modal
        .afterClosed()
        .pipe(take(1))
        .subscribe(retObj => {
          if (retObj && retObj.doPrimaryAction) {
            this.currentSelections.splice(this.currentSelections.indexOf(item), 1);
            this._formControl.setValue(this.currentSelections);
            this.edit.emit(this.currentSelections);
            this.cdRef.markForCheck();
          }
        })
    );
  }

  // ControlValueAccessor Implementation

  registerOnChange(fn: (val: any) => void) {
    this.unsubscriber.add(
      this._formControl.valueChanges.subscribe(val => {
        fn(val);
      })
    );
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this._formControl.disable();
      this.newItemControl.disable();
    } else {
      this._formControl.enable();
      this.newItemControl.enable();
    }
  }

  writeValue(value: any) {
    this._formControl.setValue(value, { emitEvent: false });
  }
}
