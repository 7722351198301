import { Component, ViewChild, ElementRef, ChangeDetectionStrategy } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FormControl } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { AnalyticsService } from "../../analytics/analytics.service";
import { prephoneViews } from "../prephone-views";
import { EntryFormErrorStateMatcher } from "../../entryPage/entry-form-error-state-matcher";
import { SignupSendUserEmailService } from "../../../../../../common/services/api/resources/signupSendUserEmail/signup-send-user-email.service";

@Component({
  selector: "onsip-forgot-sip-address-form",
  templateUrl: "./forgot-sip-address-form.component.html",
  styleUrls: ["./forgot-sip-address-form.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ForgotSipAddressFormComponent {
  @ViewChild("email") email!: ElementRef;

  prephoneViews: any = prephoneViews;
  loading = new BehaviorSubject<boolean>(false);
  requestComplete = new BehaviorSubject<boolean>(false);

  emailControl: FormControl;
  emailErrorStateMatcher: EntryFormErrorStateMatcher;

  constructor(
    private snackBar: MatSnackBar,
    private analyticsService: AnalyticsService,
    private translate: TranslateService,
    private signupSendEmailService: SignupSendUserEmailService
  ) {
    this.emailControl = new FormControl("");
    this.emailErrorStateMatcher = new EntryFormErrorStateMatcher();
  }

  submit(event: any): void {
    event.preventDefault();

    const email: string = this.email.nativeElement.value.trim();
    let message = "";

    if (email.length === 0) {
      message = this.translate.instant("ONSIP_I18N.EMAIL_ADDRESS_IS_REQUIRED");
      this.email.nativeElement.focus();
    } else if (!/\S+[@]\S+[.\S]+/i.test(email)) {
      message = this.translate.instant("ONSIP_I18N.EMAIL_ADDRESS_IS_NOT_VALID");
    }

    if (message.length > 0) {
      this.emailControl.setErrors({
        invalid: true
      });
      this.snackBar.open(message, "", {
        duration: 7000
      });
      this.email.nativeElement.focus();

      return;
    }

    this.loading.next(true);

    this.signupSendEmailService
      .signupSendUserEmail({
        Email: email,
        Destination: "app"
      })
      .then(res => {
        if (res.status === "success") {
          this.loading.next(false);
          this.requestComplete.next(true);
          this.analyticsService.sendLoginPageEvent("Forgot SIP Address email sent", undefined);
        } else {
          this.loading.next(false);
          this.emailControl.setErrors({
            invalid: true
          });
          this.snackBar.open(
            this.translate.instant("ONSIP_I18N.EMAIL_ADDRESS_NOT_FOUND_PLEASE_TRY_AGAIN"),
            "",
            {
              duration: 7000
            }
          );
          this.email.nativeElement.focus();
        }
      });
  }

  resetErrors(): void {
    if (this.emailControl.invalid) {
      this.emailControl.updateValueAndValidity();
    }
  }
}
