import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { E2eLocators } from "@onsip/e2e/configs/locators";
import { SipAddressInputComponent } from "./input/sip-address-input.component";
import { NewCallDirective } from "./new-call.directive";

@Component({
  selector: "onsip-new-call",
  templateUrl: "./new-call.component.html",
  styleUrls: ["./new-call.component.scss"],
  standalone: false
})
export class NewCallComponent {
  @ViewChild("targetComp") targetComp!: SipAddressInputComponent;
  @ViewChild("onsipNewCallRef") onsipNewCallRef!: NewCallDirective;

  @Output() outputStartedCall: EventEmitter<void> = new EventEmitter();
  target = "";
  E2eLocators = E2eLocators;

  /** Clear input field. */
  clear(): void {
    this.setValue("");
  }

  setValue(val: string): void {
    this.targetComp && this.targetComp.setValue(val);
  }

  focus(): void {
    this.targetComp && this.targetComp.focus();
  }

  cancel(): void {
    this.clear();
  }

  /** Emit event to parent that call has started */
  emitStartedCall(): void {
    this.outputStartedCall.emit();
  }

  /** Trigger call on form submit i.e. hitting enter */
  triggerCall(): void {
    this.onsipNewCallRef.dial();
  }
}
