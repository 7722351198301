import { ChangeDetectionStrategy, Component, Input, OnChanges } from "@angular/core";
import { ContactService } from "../../../../common/services/contact/contact.service";
import { TranslateService } from "@ngx-translate/core";
import { PersonalDialog } from "../../../../common/interfaces/presentity";
import { CallHangupService } from "../../../../common/services/api/resources/callHangup/call-hangup.service";

@Component({
  selector: "onsip-dialog-list-item",
  templateUrl: "./dialog-list-item.component.html",
  styleUrls: ["./dialog-list-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class DialogListItemComponent implements OnChanges {
  @Input() dialog!: PersonalDialog;
  displayStatus: string = this.translate.instant("ONSIP_I18N.UNKNOWN");
  displayName = "";

  constructor(
    private contactService: ContactService,
    private translate: TranslateService,
    private callHangupService: CallHangupService
  ) {}

  ngOnChanges() {
    if (this.dialog === undefined) {
      throw new Error("DialogListItemComponent: dialog is required input");
    }
    this.displayName = this.getDisplayName(this.dialog);
    this.displayStatus = this.getDisplayStatus(this.dialog);
  }

  /** End btn (click) call back: Hangs up dialog */
  endDialogCall(): void {
    if (this.dialog) {
      const params = {
        CpIpAddress: this.dialog.coreProxyIp,
        FromTag: this.dialog.fromTag,
        ToTag: this.dialog.toTag,
        CallId: this.dialog.callId
      };
      this.callHangupService.CallHangup(params);
    }
  }

  /** Return "best" display name for dialog 1. contact, 2. remoteDisplay 3. remoteAor */
  private getDisplayName(dialog: PersonalDialog): string {
    // note: if directiion is "recipient" remote is you
    const aor = dialog.direction === "recipient" ? dialog.localAor : dialog.remoteAor;
    const displayName =
      dialog.direction === "recipient" ? dialog.localDisplayName : dialog.remoteDisplayName;
    const contact = aor ? this.contactService.findUsingAOR(aor) : undefined;
    return contact
      ? contact.name
      : displayName
      ? displayName
      : aor
      ? aor.substring(0, aor.indexOf("@"))
      : "";
  }

  /** Returns translated display status */
  private getDisplayStatus(dialog: PersonalDialog): string {
    switch (dialog.state) {
      case "proceeding":
      case "early":
        return this.translate.instant("ONSIP_I18N.CONNECTING");
      case "trying":
        return this.translate.instant("ONSIP_I18N.INCOMING_CALL");
      case "confirmed":
        return this.translate.instant("ONSIP_I18N.ON_CALL");
      default:
        // usually state is confirmed
        return this.translate.instant("ONSIP_I18N.CALL_ENDED");
    }
  }
}
