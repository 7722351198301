import { Component, OnInit, HostBinding, ChangeDetectionStrategy } from "@angular/core";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

import { views } from "../../../../app/phone/views";

type CMSOptions =
  | "generic"
  | "hubspot"
  | "squarespace"
  | "wordpress"
  | "weebly"
  | "wix"
  | "shopify"
  | "google tag manager";

@Component({
  selector: "onsip-sayso-installation",
  templateUrl: "./sayso-installation.component.html",
  styleUrls: ["./sayso-installation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class SaysoInstallationComponent implements OnInit {
  @HostBinding("class.onsip-grid-content")
  _dontUse = true;

  gridGutter = "24px";
  rowHeight = "260px";
  displayInstructions = false;
  selectedCMS: CMSOptions = "generic";
  views = views; // for template

  gridColumns!: Observable<number>;
  isMobile: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.XSmall)
    .pipe(map(result => result.matches));

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.gridColumns = this.breakpointObserver
      .observe([
        Breakpoints.XLarge,
        Breakpoints.Large,
        Breakpoints.Medium,
        Breakpoints.Small,
        Breakpoints.XSmall
      ])
      .pipe(
        filter(result => result.matches),
        map(result => {
          if (result.breakpoints["(min-width: 600px) and (max-width: 959.99px)"]) {
            // small view
            return 2;
          } else if (result.breakpoints["(min-width: 960px) and (max-width: 1279.99px)"]) {
            // medium view
            return 3;
          } else if (result.breakpoints["(min-width: 1280px) and (max-width: 1919.99px)"]) {
            // Large view
            return 5;
          } else if (result.breakpoints["(min-width: 1920px)"]) {
            // X-Large view
            return 6;
          } else {
            // X-Small view
            return 1;
          }
        })
      );
  }

  toggleInstructions(selected?: CMSOptions): void {
    if (selected) this.selectedCMS = selected;
    this.displayInstructions = !this.displayInstructions;
  }
}
