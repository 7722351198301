import { Component, Input, ContentChildren, QueryList } from "@angular/core";

import { HeaderActionBarComponent } from "../action-bar/header-action-bar.component";

@Component({
  selector: "onsip-header-main",
  templateUrl: "header-main.component.html",
  styleUrls: ["header-main.component.scss"],
  standalone: false
})
export class HeaderMainComponent {
  @Input() debug!: boolean;

  @ContentChildren(HeaderActionBarComponent) set actionBarChild(
    queryList: QueryList<HeaderActionBarComponent>
  ) {
    this.showActionBar = !!queryList.length;
  }

  showActionBar = false;
}
