import { Pipe, PipeTransform } from "@angular/core";
// if you are thinking why it's here please read the following article
// https://medium.com/showpad-engineering/why-you-should-never-use-function-calls-in-angular-template-expressions-e1a50f9c0496
// this pipe is an attempt to bring generic solution for such problem

@Pipe({
  name: "invokeFunction",
  standalone: false
})
export class InvokeFunctionPipe implements PipeTransform {
  transform(
    value: any,
    handler: (value: any, ...agruments: Array<any>) => any,
    ...rest: Array<any>
  ): any {
    return handler(value, ...rest);
  }
}
