import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit
} from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { BehaviorSubject, Subscription } from "rxjs";
import { filter, take, map } from "rxjs/operators";
import { VolumeService } from "../shared/components/volume/volume.service";
import { VoicemailVolumeEvent } from "../shared/components/volume/volume-event";
import {
  VoicemailService,
  AdvVoicemailbox
} from "../../../common/services/api/resources/voicemail/voicemail.service";
import { ContactService } from "../../../common/services/contact/contact.service";
import { DateService } from "../../../common/services/date.service";
import { Contact } from "../../../common/interfaces/contact";
import { ModalMaterialComponent } from "../shared/components/modal/modal-material.component";
import { TranslateService } from "@ngx-translate/core";
import { E164PhoneNumber } from "../../../common/libraries/e164-phone-number";
import { Voicemail } from "../../../common/services/api/resources/voicemail/voicemail";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { MatTableDataSource } from "@angular/material/table";

export interface MessageData extends Voicemail {
  displayDate: string;
  displayTime: string;
  shouldBeBold: boolean;
  contact?: Contact;
  id: string;
  fromName: string;
  fromAor: string;
  previouslyListened: boolean;
  url: string;
}

@Component({
  selector: "onsip-voicemail",
  templateUrl: "./voicemail.component.html",
  styleUrls: ["./voicemail.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class VoicemailComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isMobile = false;

  @Input() set search(searchQuery: string) {
    this._search = searchQuery ?? ""; // FormControl.valueChanges sometimes emits null
    this.applyFilter(this._search);
  }
  get search() {
    return this._search;
  }
  _search = "";

  @ViewChild("paginator") paginator!: MatPaginator;

  remoteDisplayName = "Voicemail";
  displaySubname = "0 Messages";
  messageAudioSource: string | undefined;
  duplicateMailbox = false;
  numUnread = 0;
  isLoading = false;

  messages: Array<MessageData> = []; // the messages from the voicemail service
  voicemailTableData = new MatTableDataSource<MessageData>([]);
  pageSize = new BehaviorSubject(12);
  pageSizeOptions = [4, 12, 24, 50];
  voicemailSelect: SelectionModel<MessageData> = new SelectionModel<MessageData>(true, []);
  displayedColumns: Array<string> = ["checked", "date", "unread", "time", "name", "duration"];
  expandedElement: MessageData | undefined;
  volume = 80;
  selectedMessage: MessageData | undefined;

  private numOfMessages = 0;
  private unsubscriber = new Subscription();

  private voicemailbox: AdvVoicemailbox = {} as AdvVoicemailbox;

  constructor(
    private volumeService: VolumeService,
    private voicemailService: VoicemailService,
    private contactService: ContactService,
    private dateService: DateService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver
  ) {}

  unselectAllMessages(): void {
    this.selectedMessage = undefined;
    this.remoteDisplayName = "Voicemail";
    this.displaySubname = this.numOfMessages + " Message" + (this.numOfMessages !== 1 ? "s" : "");
  }

  selectMessage(message: MessageData | undefined): void {
    // deselect message if clicking on selected message again
    if (!!message && message === this.selectedMessage) {
      this.voicemailSelect.deselect(message);
      this.unselectAllMessages();
      return;
    }
    // return if message is undefined and there is no selected message
    if (!message) {
      this.selectedMessage = undefined;
      return;
    }
    const wasNew = message.folder === "NEW";
    this.voicemailSelect.clear();
    this.voicemailSelect.select(message);
    this.selectedMessage = message;
    if (wasNew) this.setPreviouslyListened(message.metadataId);
    this.messageAudioSource = message.url;
    this.remoteDisplayName = this.selectedMessage.fromName;

    const foundMessage = (this.voicemailbox.voicemail?.inbox?.voicemail as any)[message.id];
    if (foundMessage) {
      const phoneNumber = new E164PhoneNumber(message.uri.slice(0, message.uri.indexOf("@")));
      this.displaySubname = phoneNumber.isValid ? phoneNumber.e164Uri : message.uri;
    } else {
      this.displaySubname = "unknown";
    }
    if (wasNew) this.numUnread--;
  }

  deleteSelectedMessages(): void {
    const modal = this.dialog.open(ModalMaterialComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"],
      data: {
        title: this.translate.instant("ONSIP_I18N.DELETE_SELECTED_VOICEMAIL"),
        message: `${this.translate.instant("ONSIP_I18N.DELETED_VOICEMAIL_CANNOT_BE_RECOVERED")}<br/>
        ${this.translate.instant("ONSIP_I18N.DO_YOU_WANT_TO_DELETE_ALL_SELECTED_VOICEMAILS")}`,
        secondaryBtnText: this.translate.instant("ONSIP_I18N.CANCEL"),
        primaryBtnFlat: true,
        primaryBtnText: this.translate.instant("ONSIP_I18N.DELETE_SELECTED")
      }
    });
    this.unsubscriber.add(
      modal
        .afterClosed()
        .pipe(take(1))
        .subscribe(retObj => {
          if (retObj && retObj.doPrimaryAction) {
            if (this.voicemailSelect.selected.length === this.messages.length) {
              this.deleteAllMessages();
              this.voicemailSelect.clear();
              this.unselectAllMessages();
            } else {
              this.voicemailSelect.selected.forEach(message => {
                this.deleteMessage(message);
              });
              this.voicemailSelect.clear();
              this.unselectAllMessages();
            }
          }
        })
    );
  }

  ngOnInit() {
    this.unsubscriber.add(
      this.volumeService.state.subscribe(volumeState => (this.volume = volumeState.defaultVolume))
    );

    this.unsubscriber.add(
      this.volumeService.event
        .pipe(filter(e => e.id === VoicemailVolumeEvent.id))
        .subscribe(e => (this.volume = e.volume))
    );

    this.unsubscriber.add(
      this.breakpointObserver
        .observe([Breakpoints.Large, Breakpoints.XLarge])
        .pipe(map(result => result.matches))
        .subscribe(result => {
          this.pageSize.next(result ? 12 : 4);
          this.voicemailTableData = new MatTableDataSource<MessageData>(this.messages);
          if (this.paginator) {
            this.paginator.pageSize = this.pageSize.value;
            this.paginator.firstPage();
            this.voicemailTableData.paginator = this.paginator;
          }
          this.changeDetectorRef.markForCheck();
        })
    );

    this.unsubscriber.add(
      this.voicemailService.selfUserVoicemailbox.subscribe(selfVmb => {
        if (!selfVmb) return;
        this.voicemailbox = selfVmb;
        const newMessages: Array<MessageData> = [];
        this.numUnread = 0;
        Object.values(selfVmb.voicemail?.inbox?.voicemail || [])
          .sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1))
          .filter(message => message.folder !== "DELETED")
          .forEach(message => {
            const existingMessage = this.messages.find(msg => msg.id === message.metadataId);

            if (existingMessage) {
              existingMessage.folder = message.folder;
              if (existingMessage.folder === "NEW") this.numUnread++;
              existingMessage.previouslyListened = existingMessage.folder !== "NEW";
              newMessages.push(existingMessage);
            } else {
              const contact: Contact | undefined = this.contactService.findUsingAOR(message.uri);
              const messageToPush: MessageData = {
                ...message,
                duration: (parseInt(message.duration) * 1000).toString(),
                displayDate: this.dateService.displayDate(new Date(message.createdAt)),
                displayTime: new Date(message.createdAt).toLocaleTimeString(),
                contact,
                id: message.metadataId,
                url: this.voicemailService.getVoicemailUrl(
                  selfVmb.advVoicemailboxId,
                  message.metadataId
                ),
                fromName: contact
                  ? contact.name
                  : message.callerId
                  ? message.callerId.indexOf('"') >= 0
                    ? message.callerId.split('"')[1]
                    : message.callerId
                  : message.uri,
                fromAor: contact ? contact.aors[0] : message.uri,
                previouslyListened: message.folder !== "NEW",
                shouldBeBold: !!contact
              };
              if (message.folder === "NEW") this.numUnread++;
              newMessages.push(messageToPush);
            }
          });

        // unneeded, hack because volume is only in tempalte
        if (this.volume === undefined) {
          this.volume = 40;
        }
        this.messages = newMessages;
        this.voicemailTableData.data = newMessages;
        this.changeDetectorRef.markForCheck();

        this.displaySubname =
          this.messages.length + " Message" + (this.messages.length !== 1 ? "s" : "");
        this.numOfMessages = this.messages.length;
      })
    );
  }

  ngAfterViewInit(): void {
    this.voicemailTableData.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  showDesktopRow(): boolean {
    return !this.isMobile;
  }

  showMobileRow(): boolean {
    return this.isMobile;
  }

  masterToggle(shouldUnselect: boolean = this.isAllSelected()) {
    this.expandedElement = undefined;
    if (shouldUnselect) {
      this.voicemailSelect.clear();
      this.unselectAllMessages();
    } else {
      this.messages.forEach(row => this.voicemailSelect.select(row));
    }
  }

  private applyFilter(filter: string) {
    this.voicemailTableData.filter = filter.trim().toLowerCase();
    // If there is no match, then show all voicemails
    if (!this.voicemailTableData.filteredData.length) this.voicemailTableData.filter = "";
    this.voicemailTableData.paginator?.firstPage();
  }

  private isAllSelected() {
    const numSelected = this.voicemailSelect.selected.length;
    const numRows = this.messages.length;
    return numSelected === numRows;
  }

  private deleteMessage(message: MessageData): void {
    this.voicemailService.advVoicemailboxInboxDelete(message.metadataId);
  }

  private deleteAllMessages(): void {
    this.voicemailService.advVoicemailboxInboxDeleteAll();
  }

  private setPreviouslyListened(metadataId: string): void {
    this.voicemailService.advVoicemailboxInboxEdit(metadataId, { Folder: "OLD" });
  }
}
