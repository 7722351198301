import { Directive, Input, forwardRef } from "@angular/core";
import { FormControl, NG_VALIDATORS, Validator } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Directive({
  selector: "[onsipZipCodeValidator]",
  providers: [
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => OnsipZipCodeValidatorDirective),
      multi: true
    }
  ],
  standalone: false
})
export class OnsipZipCodeValidatorDirective implements Validator {
  @Input() notRequired = false;

  constructor(private translate: TranslateService) {}

  public validate(control: FormControl): { [key: string]: any } | null {
    return this.validateZipCode(control.value);
  }

  private validateZipCode(zipCode: string): Object | null {
    return !zipCode && !this.notRequired
      ? {
          required: {
            message: this.translate.instant("ONSIP_I18N._VALUE__IS_REQUIRED", {
              value: this.translate.instant("ONSIP_I18N.ZIPCODE")
            }),
            order: 0
          }
        }
      : zipCode &&
        !/(^[0-9]{5}$)|(^[0-9]{5}[-][0-9]{4}$)|(^[A-Z][0-9][A-Z] [0-9][A-Z][0-9]$)/.test(zipCode)
      ? {
          pattern: {
            message: this.translate.instant(
              "ONSIP_I18N.MUST_BE_A_VALID_5_OR_9DIGIT_US_POSTAL_CODE"
            ),
            order: 2
          }
        }
      : // eslint-disable-next-line no-null/no-null
        null;
  }
}
