// @ts-ignore
import { saveAs } from "file-saver";
import { DateTime, DateTimeFormatOptions } from "luxon";

import { AdvQueueService } from "@onsip/common/services/api/resources/queue/adv-queue.service";
import { QueueSummaryService } from "@onsip/common/services/api/resources/queueSummary/queue-summary.service";
import { QueueAgentSummaryService } from "@onsip/common/services/api/resources/queueSummary/queue-agent-summary.service";
import { QueueCallerEventService } from "@onsip/common/services/api/resources/queueEvent/queue-caller-event.service";
import { QueueAgentEventService } from "@onsip/common/services/api/resources/queueEvent/queue-agent-event.service";
import { TranslateService } from "@ngx-translate/core";

import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { AdvQueueObject } from "@onsip/common/services/api/resources/queue/adv-queue.service";
import { Subscription, Subject } from "rxjs";
import { FormatDurationPipe } from "../../shared/pipes/format-duration.pipe";
import {
  QueueSummaryParams,
  QueueSummary
} from "@onsip/common/services/api/resources/queueSummary/queue-summary";
import { QueueAgentSummary } from "@onsip/common/services/api/resources/queueSummary/queue-agent-summary";
import { QueueAgentEvent } from "@onsip/common/services/api/resources/queueEvent/queue-agent-event";
import { QueueCallerEvent } from "@onsip/common/services/api/resources/queueEvent/queue-caller-event";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";

export interface QueueSummaryReport extends QueueSummary {
  totalCallsAnswered: string;
  callsToFailOver: string;
  callsRejected: string;
}

export interface QueueAgentSummaryReport extends QueueAgentSummary {
  percentActiveTime: string;
}
export interface QueueOptionsObject {
  value: number;
  label: string;
}

export interface TimeZoneOption {
  value: string;
  label: string;
}

export interface CellData {
  cellTitle: string;
  cellInfo: string;
}

export interface Csv {
  agent: DownloadInfo;
  caller: DownloadInfo;
}

export interface DownloadInfo {
  Action: "QueueAgentEventBrowse" | "QueueCallerEventBrowse";
  parameters: QueueSummaryParams;
  download: string;
  downloadEvents: string;
}

export interface Report {
  displayName: string;
  startDateTime: string;
  endDateTime: string;
  agents: Array<QueueAgentSummaryReport>;
  queue: QueueSummaryReport;
  addStrings: (list: Array<string>) => string;
  csv: Csv;
}

@Component({
  selector: "onsip-queue-historical",
  templateUrl: "./queue-historical.component.html",
  styleUrls: ["./queue-historical.scss"],
  providers: [FormatDurationPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class QueueHistoricalComponent implements OnInit, OnDestroy {
  queueControl = new FormControl("", [Validators.required]);
  queueOptions: Array<QueueOptionsObject> = [];
  queueOptionSelected: number | undefined;
  agentReportColumns: Array<keyof QueueAgentSummaryReport> = [
    "agentName",
    "loginDuration",
    "callsAnswered",
    "avgCallLength",
    "timeOnCalls",
    "timeOnHold",
    "percentActiveTime"
  ];
  queueTableInfo: Array<CellData> = [];

  startDateControl = new FormControl();
  endDateControl = new FormControl();
  maxEndDate = new Date();
  maxStartDate = new Date();

  timezoneControl = new FormControl("", [Validators.required]);
  timezoneOptions: Array<TimeZoneOption> = [
    { value: "UTC", label: "UTC" },
    { value: "US Eastern", label: "US Eastern" },
    { value: "US Central", label: "US Central" },
    { value: "US Mountain", label: "US Mountain" },
    { value: "US Pacific", label: "US Pacific" },
    { value: "US Arizona", label: "US Arizona" }
  ];
  timezoneOptionSelected = "US Eastern";

  reportState = new Subject<string>();

  historicalQueueReports!: Report;

  private observers: Array<Subscription> = [];
  private queues: Array<AdvQueueObject> = [];

  constructor(
    private queueService: AdvQueueService,
    private translate: TranslateService,
    private formatDuration: FormatDurationPipe,
    private queueSummaryService: QueueSummaryService,
    private queueAgentSummaryService: QueueAgentSummaryService,
    private queueCallerEvent: QueueCallerEventService,
    private queueAgentEvent: QueueAgentEventService,
    private snackbar: SnackbarService
  ) {
    const startDate = new Date();
    startDate.setDate(1); // first day of month
    startDate.setHours(0, 0, 0, 0); // start of day (midnight)
    this.startDateControl.setValue(startDate);

    const endDate = new Date();
    endDate.setHours(0, 0, 0, 0); // start of day (midnight)
    this.endDateControl.setValue(endDate);

    this.maxEndDate.setDate(this.maxEndDate.getDate() + 1); // tomorrow
    this.maxEndDate.setHours(0, 0, 0, 0); // start of day (midnight)
  }

  multiplyByThousand(key: number): number {
    return key * 1000;
  }

  percentActiveTime(agent: QueueAgentSummary): string {
    return ((100 * parseInt(agent.timeOnCalls)) / parseInt(agent.loginDuration)).toString() + "%";
  }

  historicalQueueFormSubmitted(): void {
    if (this.queueOptionSelected === undefined) {
      return;
    }
    const queueSelected = this.queues[this.queueOptionSelected];

    const timeZone = this.convertTimezone(this.timezoneOptionSelected),
      reportStartDate = this.startDateControl.value.toISOString(),
      reportEndDate = this.endDateControl.value.toISOString();

    this.reportState.next("loading");

    this.getHistoricalReports(queueSelected, reportStartDate, reportEndDate, timeZone).then(
      reports => {
        this.reportState.next("queue");
        this.historicalQueueReports = reports;
        if (reports.queue) this.getQueueTableInfo(reports.queue);
      }
    );
  }

  downloadTable(filename: string, tableType: string): void {
    let rowsCsv = "";
    if (tableType === "queue") {
      rowsCsv = this.stringify(this.queueTableInfo, "\r\n");
    } else if (tableType === "agent") {
      rowsCsv = this.agentTableInfo();
    }
    this.saveAsCsv(rowsCsv, filename);
  }

  agentTableInfo(): string {
    const allRows: Array<Array<string>> = [];

    const headerRow = [
      "Agent Name",
      "Active Time",
      "Calls Answered",
      "Avg. Call Duration",
      "Time On Call",
      "Calls On Hold",
      "% Active Time On Call"
    ];
    allRows.push(headerRow);

    const agents = this.historicalQueueReports.agents;
    for (const agent of agents) {
      const agentRowInfo: Array<string> = [];
      for (const key of this.agentReportColumns) {
        const value = agent[key];
        if (value) {
          agentRowInfo.push(value);
        }
      }
      allRows.push(agentRowInfo);
    }

    return allRows.map(row => row.join(",")).join("\r\n");
  }

  downloadEventsAsCsv(
    $event: MouseEvent,
    browseAction: "QueueAgentEventBrowse" | "QueueCallerEventBrowse",
    parameters: QueueSummaryParams,
    filename: string,
    timeZone: string
  ): void {
    const button: HTMLElement = $event.currentTarget as HTMLElement;

    if (button.getAttribute("disabled")) {
      return;
    }
    button.setAttribute("disabled", "disabled");

    const apiActionPromise =
      browseAction === "QueueAgentEventBrowse"
        ? this.queueAgentEvent.queueAgentEventBrowse(parameters)
        : this.queueCallerEvent.queueCallerEventBrowse(parameters);

    apiActionPromise
      .then(result => {
        if (result.status === "success") {
          // use Record<string, string> so we don't have to worry about type difference between queueCallerEvent and queueAgentEvent
          const items: Array<Record<string, string>> = this.csvFormatEventObject(
            Object.values(result.data),
            browseAction
          );

          if (items.length === 0) {
            this.snackbar.openSnackBar(
              "There are no results in this date range, please pick a larger range.",
              "error"
            );
            throw new Error("There are no results in this date range, please pick a larger range.");
          } else if (items.length === parameters.Limit) {
            this.snackbar.openSnackBar(
              "There are too many results in this date range, please pick a smaller range.",
              "error"
            );
            throw new Error(
              "There are too many results in this date range, please pick a smaller range."
            );
          }
          // values with comma break CSVs, so instead of switching separator we just swap it out
          items.forEach((item, idx) => {
            if (idx === 0) {
              return;
            }

            Object.keys(item).forEach(val => {
              items[idx][val] = items[idx][val].replace(/,/gi, "_");
              if (val === "HoldDuration") {
                items[idx][val] = Math.floor(Number(items[idx][val]) / 1000000).toString();
              }
            });
          });

          const headerRow: string = Object.keys(items[0]).join(","),
            valueRowsString: string = this.mapJoin(items, this.valueRow, "\r\n"),
            valueRowsSplit: Array<string> = valueRowsString.split(","),
            valueRows: Array<string> = [],
            rowLength: number = Object.keys(items[0]).length;
          let rowIdx = 0;
          timeZone = this.convertTimezone(timeZone);
          valueRowsSplit.forEach(value => {
            let maybeTime: string;
            if (rowIdx === rowLength - 1) {
              const endOfLineArray: Array<string> = value.split("\r\n");
              try {
                maybeTime = this.toFormat(new Date(endOfLineArray[0]).getTime(), timeZone, true);
              } catch {
                maybeTime = endOfLineArray[0];
              }

              valueRows.push(
                maybeTime + "\r\n" + (endOfLineArray.length > 1 ? endOfLineArray[1] : "")
              );
              rowIdx = 1;
              return;
            }
            try {
              if (
                (rowLength === 15 &&
                  (rowIdx === 6 || rowIdx === 7 || rowIdx === 8 || rowIdx === 14)) ||
                (rowLength === 8 && rowIdx === 5)
              ) {
                maybeTime = this.toFormat(new Date(value).getTime(), timeZone, true);
              } else {
                maybeTime = value;
              }
            } catch {
              maybeTime = value;
            }
            valueRows.push(maybeTime);
            rowIdx++;
          });

          const csvString: string = headerRow + "\r\n" + valueRows;
          this.saveAsCsv(csvString, filename);
        } else {
          const error = result.data;
          const message: string =
            (error && error.message) || "Sorry, but an unknown error occurred.";
          alert(message);
        }
      })
      .then(button.removeAttribute.bind(button, "disabled"));
  }

  ngOnInit() {
    const currentTimezone = Intl.DateTimeFormat("default", {
      timeZoneName: "short"
    })
      .format()
      .split(" ")
      .slice(-1)[0];
    let defaultTimezone: string;

    switch (currentTimezone.charAt(0)) {
      case "E":
        defaultTimezone = "US Eastern";
        break;
      case "C":
        defaultTimezone = "US Central";
        break;
      case "M":
        defaultTimezone = "US Mountain";
        break;
      case "P":
        defaultTimezone = "US Pacific";
        break;
      default:
        defaultTimezone = "UTC";
    }

    this.timezoneOptionSelected = defaultTimezone;

    this.queueService.getSmartQueues().then(smartQueues => {
      this.queues = smartQueues.slice(0); // clone it, so we can edit

      this.queues.forEach((queue, idx) => {
        this.queueOptions.push({ value: idx, label: queue.displayName });
      });
    });
  }

  ngOnDestroy() {
    this.observers.forEach(s => s.unsubscribe());
  }

  /** format the downloaded csv for events in a more organized format */
  private csvFormatEventObject(
    data: Array<QueueAgentEvent> | Array<QueueCallerEvent>,
    browseAction: "QueueAgentEventBrowse" | "QueueCallerEventBrowse"
  ): Array<Record<string, string>> {
    // we want data in reverse chronological order
    data = data.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
    if (browseAction === "QueueAgentEventBrowse") {
      const agentData = data as Array<QueueAgentEvent>;
      return agentData.map(event => {
        return {
          QueueAgentEventId: event.queueAgentEventId,
          AgentAddress: event.agentAddress,
          AgentName: event.agentName,
          QueueOrganizationId: event.queueOrganizationId,
          AppId: event.appId,
          EventTime: event.eventTime,
          Event: event.event,
          Created: event.created
        };
      });
    } else {
      const callerData = data as Array<QueueCallerEvent>;
      return callerData.map(event => {
        return {
          QueueCallerEventId: event.queueCallerEventId,
          CallId: event.callId,
          CallerName: event.callerName,
          CallerAddress: event.callerAddress,
          QueueOrganizationId: event.queueOrganizationId,
          AppId: event.appId,
          EnqueueTime: event.enqueueTime,
          EndTime: event.endTime,
          AgentAnswerTime: event.agentAnswerTime,
          AgentAddress: event.agentAddress,
          WaitDuration: event.waitDuration,
          ConfirmedDuration: event.confirmedDuration,
          HoldDuration: event.holdDuration,
          Disposition: event.disposition,
          Created: event.created
        };
      });
    }
  }

  // returns something like "Mon, Jun 15, 2020, 08:31 GMT-0500"
  private toFormat(utcDate: number, timeZone: string, alterTime = false): string {
    const dateTimeOptions: DateTimeFormatOptions & { hourCycle: "h23" } = {
      timeZone: alterTime ? timeZone : undefined,
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      hourCycle: "h23" // not in the type, but needed to make midnight 00: instead of 24:
    };
    return (
      Intl.DateTimeFormat("default", dateTimeOptions).format(new Date(utcDate)).replace(/,/g, "") +
      DateTime.fromObject({ zone: timeZone }).toFormat(" 'GMT'ZZZ")
    );
  }

  private convertTimezone(timezone: string): string {
    switch (timezone) {
      case "US Eastern":
        timezone = "America/New_York";
        break;
      case "US Central":
        timezone = "America/Chicago";
        break;
      case "US Mountain":
        timezone = "America/Denver";
        break;
      case "US Pacific":
        timezone = "America/Los_Angeles";
        break;
      default:
        timezone = "UTC";
    }

    return timezone;
  }

  private getQueueTableInfo(queueInfo: QueueSummaryReport): void {
    this.queueTableInfo = [
      {
        cellTitle: this.translate.instant("ONSIP_I18N.TOTAL_CALLS"),
        cellInfo: queueInfo.callsInQueue
      },
      {
        cellTitle: this.translate.instant("ONSIP_I18N.AVERAGE_WAIT_TIME"),
        cellInfo: queueInfo.avgWaitDuration
      },
      {
        cellTitle: this.translate.instant("ONSIP_I18N.MAXIMUM_WAIT_TIME"),
        cellInfo: queueInfo.maxWaitDuration
      },
      {
        cellTitle: this.translate.instant("ONSIP_I18N.CALLER_WITH_LONGEST_WAIT"),
        cellInfo: queueInfo.callWithMaxWaitDuration
      },
      {
        cellTitle: this.translate.instant("ONSIP_I18N.BUSIEST_TIME_OF_DAY"),
        cellInfo: queueInfo.busiestTimeOfDay
      },
      {
        cellTitle: this.translate.instant("ONSIP_I18N.CALLS_ANSWERED"),
        cellInfo: this.addStrings([queueInfo.callsAnswered, queueInfo.queueFailureAnsweredCalls])
      },
      {
        cellTitle: this.translate.instant("ONSIP_I18N.AVERAGE_CALL_TIME_WITH_AGENT"),
        cellInfo: queueInfo.avgAnsweredCallDuration
      },
      {
        cellTitle: this.translate.instant("ONSIP_I18N.CALLS_ABANDONED"),
        cellInfo: this.addStrings([queueInfo.callsAbandoned, queueInfo.queueFailureAbandonedCalls])
      },
      {
        cellTitle: this.translate.instant("ONSIP_I18N.AVERAGE_ABANDONED_WAIT_TIME"),
        cellInfo: queueInfo.avgAbandonWaitDuration
      },
      {
        cellTitle: this.translate.instant("ONSIP_I18N.MOST_ABANDONED_TIME_OF_DAY"),
        cellInfo: queueInfo.mostAbandonedTimeOfDay
      },
      {
        cellTitle: this.translate.instant("ONSIP_I18N.CALLS_TO_FAILOVER"),
        cellInfo: queueInfo.callsToFailOver
      },
      {
        cellTitle: this.translate.instant("ONSIP_I18N.CALLS_REJECTED"),
        cellInfo: queueInfo.callsRejected
      }
    ];
  }

  private formatDateTimeWithTZ(msDate: number, timeZone: string): string {
    if (!msDate) return msDate.toString();

    const timeFormatter = Intl.DateTimeFormat("default", {
      hour: "numeric",
      minute: "2-digit",
      timeZone
    });

    const dateFormatter = Intl.DateTimeFormat("default", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      timeZone
    });

    return (
      timeFormatter.format(msDate).replace(/\s+/g, "").toLowerCase() +
      " " +
      dateFormatter.format(msDate)
    );
  }

  private pretty(hour: number, timeZone: string): string {
    const datetime: number = Date.parse(
      "2015-04-20T" + (hour < 10 ? "0" : "") + hour.toString() + ":00:00Z"
    );

    return Intl.DateTimeFormat("default", {
      hour: "numeric",
      minute: "2-digit",
      timeZone
    })
      .format(datetime)
      .replace(/\s+/g, "")
      .toLowerCase();
  }

  private formatHourWithTZ(hour: string, timeZone: string): string {
    const hourNum: number = parseInt(hour, 10);

    if (isNaN(hourNum)) {
      return "N/A";
    }

    const timezoneAbbr = Intl.DateTimeFormat("default", {
      timeZoneName: "short",
      timeZone
    })
      .format()
      .split(" ")
      .slice(-1)[0];

    return (
      this.pretty(hourNum, timeZone) +
      " - " +
      this.pretty(hourNum + 1, timeZone) +
      " " +
      timezoneAbbr
    );
  }

  private addStrings(list: Array<string>): string {
    const result: number = list.reduce((innerResult, value) => {
      return innerResult + +(value || 0);
    }, 0);

    return result.toString();
  }

  private getDownloadInfo(
    typeString: string,
    parameters: QueueSummaryParams,
    advQueueDisplayName: string,
    startDate: string,
    endDate: string
  ): DownloadInfo {
    const csvParameters: QueueSummaryParams = JSON.parse(JSON.stringify(parameters)),
      action: "QueueAgentEventBrowse" | "QueueCallerEventBrowse" =
        typeString === "Agents" ? "QueueAgentEventBrowse" : "QueueCallerEventBrowse",
      filenamePieces: Array<string> = [advQueueDisplayName, typeString, startDate, "-", endDate],
      filename: string = filenamePieces.join("_");

    filenamePieces.splice(2, 0, "Events");
    const filenameEvents = filenamePieces.join("_");
    // default limit of 20 was too low, would cut some results
    csvParameters.Limit = 25000;

    return {
      Action: action,
      parameters: csvParameters,
      download: filename + ".csv",
      downloadEvents: filenameEvents + ".csv" // XXX gets overridden by response header
    };
  }

  // advQueue: {AppId, OrganizationId, DisplayName} (present in AdvQueue returned by AdvQueueBrowse)
  // startDate: YYYY-MM-DD
  // endDate: YYYY-MM-DD
  private getHistoricalReports(
    advQueue: AdvQueueObject,
    startDate: string,
    endDate: string,
    timeZone: string
  ): Promise<Report> {
    const parameters: QueueSummaryParams = {
      OrganizationId: advQueue.organizationId,
      AppId: advQueue.appId,
      StartDateTime: this.toFormat(new Date(startDate).setHours(0, 0, 0), timeZone),
      EndDateTime: this.toFormat(new Date(endDate).setHours(23, 59, 59), timeZone),
      Limit: 25000
    };

    return Promise.all([
      this.queueSummaryService.queueSummaryBrowse(parameters),
      this.queueAgentSummaryService.queueAgentSummaryBrowse(parameters)
    ]).then(responseLists => {
      if (responseLists[0].status === "success" && responseLists[1].status === "success") {
        const qSummaries: Array<QueueSummary> = Object.values(responseLists[0].data),
          queueAgentSummaries: Array<QueueAgentSummary> = Object.values(responseLists[1].data),
          qSummaryReport = qSummaries[0] as QueueSummaryReport;
        let queueAgentSummariesReport: Array<QueueAgentSummaryReport> = [];

        if (qSummaries[0]) {
          qSummaryReport.busiestTimeOfDay = this.formatHourWithTZ(
            qSummaries[0].busiestTimeOfDay,
            timeZone
          );
          qSummaryReport.mostAbandonedTimeOfDay = this.formatHourWithTZ(
            qSummaries[0].mostAbandonedTimeOfDay,
            timeZone
          );
          qSummaryReport.avgWaitDuration = this.formatDuration.transform(
            parseInt(qSummaries[0].avgWaitDuration) * 1000
          );
          qSummaryReport.maxWaitDuration = this.formatDuration.transform(
            parseInt(qSummaries[0].maxWaitDuration) * 1000
          );
          qSummaryReport.avgAnsweredCallDuration = this.formatDuration.transform(
            parseInt(qSummaries[0].avgAnsweredCallDuration) * 1000
          );
          qSummaryReport.avgAbandonWaitDuration = qSummaries[0].avgAbandonWaitDuration
            ? this.formatDuration.transform(parseInt(qSummaries[0].avgAbandonWaitDuration) * 1000)
            : this.formatDuration.transform(0);
          qSummaryReport.totalCallsAnswered = this.addStrings([
            qSummaries[0].callsAnswered,
            qSummaries[0].queueFailureAnsweredCalls
          ]);
          qSummaryReport.callsToFailOver = this.addStrings([
            qSummaries[0].callsManuallyFailedOver,
            qSummaries[0].maxCallersFailoverCalls,
            qSummaries[0].maxWaitCalls,
            qSummaries[0].noAgentFailoverCalls
          ]);
          qSummaryReport.callsRejected = this.addStrings([
            qSummaries[0].maxCallersRejectCalls,
            qSummaries[0].noAgentRejectCalls
          ]);
          // Changing date string to milliseconds representation of date
          if (qSummaries[0].callWithMaxWaitDuration) {
            // convert to ISO 8601 so Firefox will parse it
            qSummaryReport.callWithMaxWaitDuration =
              qSummaries[0].callWithMaxWaitDuration.replace(" ", "T") + "Z";
            qSummaryReport.callWithMaxWaitDuration = this.formatDateTimeWithTZ(
              Date.parse(qSummaries[0].callWithMaxWaitDuration),
              timeZone
            );
          }
        }

        queueAgentSummariesReport = queueAgentSummaries.map(agentSummary => {
          const agentSummaryReport = agentSummary as QueueAgentSummaryReport;
          // the Web API converts <AgentName/> to AgentName:{}, but we want AgentName:""
          if (agentSummary.agentName === "") {
            agentSummaryReport.agentName = "";
          }
          const activeTimeRatio =
            (100 * parseInt(agentSummary.timeOnCalls)) / parseInt(agentSummary.loginDuration);
          agentSummaryReport.percentActiveTime = Math.round(1000 * activeTimeRatio) / 1000 + "%";
          agentSummaryReport.loginDuration = this.formatDuration.transform(
            parseInt(agentSummary.loginDuration) * 1000
          );
          agentSummaryReport.avgCallLength = this.formatDuration.transform(
            parseInt(agentSummary.avgCallLength) * 1000
          );
          agentSummaryReport.timeOnCalls = this.formatDuration.transform(
            parseInt(agentSummary.timeOnCalls) * 1000
          );
          agentSummaryReport.timeOnHold = this.formatDuration.transform(
            parseInt(agentSummary.timeOnHold) * 1000
          );
          return agentSummaryReport;
        });

        const dateFormatter = Intl.DateTimeFormat("default", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit"
        });

        return {
          displayName: advQueue.displayName,
          startDateTime: startDate,
          endDateTime: endDate,
          agents: queueAgentSummariesReport,
          queue: qSummaryReport,
          addStrings: this.addStrings,
          csv: {
            agent: this.getDownloadInfo(
              "Agents",
              parameters,
              advQueue.displayName,
              dateFormatter.format(new Date(startDate)),
              dateFormatter.format(new Date(endDate))
            ),
            caller: this.getDownloadInfo(
              "Callers",
              parameters,
              advQueue.displayName,
              dateFormatter.format(new Date(startDate)),
              dateFormatter.format(new Date(endDate))
            )
          }
        };
      } else {
        return Promise.reject();
      }
    });
  }

  private mapJoin(nodes: any, mapper: (item: any) => string, delimiter: string): string {
    let allNodes: Array<any> = [];

    for (const node of nodes) {
      allNodes = allNodes.concat(mapper.bind(this, node)());
    }

    return allNodes.join(delimiter);
  }

  private stringify(nodes: Array<CellData>, delimiter: string): string {
    let allNodes: Array<any> = [];

    for (const node of nodes) {
      allNodes = allNodes.concat(`${node.cellTitle},${node.cellInfo}`);
    }

    return allNodes.join(delimiter);
  }

  private saveAsCsv(csvString: string, filename: string): void {
    const blob: Blob = new Blob([csvString], { type: "text/csv;charset=utf8;" });

    saveAs(blob, filename);
  }

  private valueRow(item: any): string {
    return this.mapJoin(
      Object.keys(item),
      (key: string) => {
        const value: any = item[key];
        let escaped = "",
          hasQuotes = false;

        for (const val of value) {
          escaped += val;

          if (val === "\n" || val === ",") {
            hasQuotes = true;
          }
          if (val === '"') {
            escaped += '"';
            hasQuotes = true;
          }
        }
        return hasQuotes ? '"' + escaped + '"' : escaped;
      },
      ","
    );
  }
}
