import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from "@angular/core";
import { FormControl, Validators, ValidatorFn, AbstractControl } from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";

import { Subscription } from "rxjs";

import { URI } from "sip.js";
import { UserAgent } from "../../../../common/libraries/sip/user-agent";

import { GumService } from "../../shared/services/gum/gum.service";
import { VideoConferenceService } from "../video-conference.service";
import { CallControllerService } from "../../../../common/services/call-controller.service";
import { SdhFactoryHelperService } from "../../shared/controller/sdh-factory-helper.service";
import { Config } from "../../../../common/config";
import { UUID } from "../../../../common/libraries/sip";

import { ModalMaterialComponent } from "../../shared/components/modal/modal-material.component";
import { views } from "../../../app/phone/views";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "onsip-new-conference",
  templateUrl: "./new-conference.component.html",
  styleUrls: ["./new-conference.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class NewConferenceComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("targetInput") targetInput!: ElementRef;
  @ViewChild("anonInput") anonInput!: ElementRef;
  @Input() subHeader: string | undefined;
  @Input() header: string | undefined;
  @Input() instructions: string | undefined;
  @Input() conferenceUri: string | undefined;
  @Input() conferenceName: string | undefined;
  @Input() fromDisplay: string | undefined;
  @Input() anonymous = false;
  @Input() sidebar = false;
  @Output() outputStartedCall: EventEmitter<string> = new EventEmitter();

  views = views;

  gumPending = false;
  localDisplayName = "";

  targetControl = new FormControl("", { nonNullable: true, validators: [this.uriValidator()] });

  localDisplayControl = new FormControl("", { nonNullable: true, validators: Validators.required });

  private attendeeUA: UserAgent | undefined;
  private target = "";
  private unsubscriber = new Subscription();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private videoConferenceService: VideoConferenceService,
    private callControllerService: CallControllerService,
    private gumService: GumService,
    private translate: TranslateService,
    private sdhFactoryHelperService: SdhFactoryHelperService
  ) {}

  ngOnInit(): void {
    this.localDisplayName = this.fromDisplay || "";
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.focus());
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  focus(): void {
    if (this.targetInput && this.targetInput.nativeElement) {
      this.targetInput.nativeElement.selectionStart = this.targetInput.nativeElement.value.length;
      this.targetInput.nativeElement.focus();
    } else if (this.anonInput && this.anonInput.nativeElement) {
      this.anonInput.nativeElement.selectionStart = this.anonInput.nativeElement.value.length;
      this.anonInput.nativeElement.focus();
    }
  }

  updateTarget(): void {
    this.target = this.targetControl.value;
  }

  updateLocalDisplayName(): void {
    this.localDisplayName = this.localDisplayControl.value;
  }

  onKeyUp(ev: any): void {
    if (ev.keyCode === 13) {
      this.startConference();
    }
  }

  startConference(): void {
    if (this.anonymous) {
      if (this.localDisplayName.length === 0) {
        this.anonInput.nativeElement.focus();
        return;
      }

      /** Listen for incoming SIP.js events */
      this.attendeeUA = this.callControllerService.makeAndAddUserAgent(
        {
          userAgentString: "OnSIP_App/" + Config.VERSION_NUMBER + "/" + Config.PLATFORM_STRING
        },
        undefined,
        {},
        undefined,
        this.sdhFactoryHelperService.createFactory()
      );

      // Confirm when user closes window during a call. Terminate the session when the window closes.
      window.addEventListener(
        "beforeunload",
        e => {
          if (this.videoConferenceService.hasVideoConference()) {
            e.preventDefault();
          }
        },
        false
      );

      window.addEventListener("unload", () => {
        this.attendeeUA && this.attendeeUA.stop();
      });

      if (!this.conferenceName || !this.conferenceUri) {
        throw new Error("no conference name or uri passed to start conference");
      }
      this.startGumConferenceCall(this.conferenceName, this.conferenceUri, this.attendeeUA);
    } else {
      if (!this.target) {
        this.target = UUID.randomUUID().slice(0, 10);
      }

      const outboundUserAgent = this.callControllerService.getOutboundUserAgent(),
        potentialUsername: string = this.target,
        uri: URI | undefined = outboundUserAgent
          ? outboundUserAgent.targetToURI(potentialUsername.toLowerCase().replace(/\s/g, "_"))
          : undefined;

      if (!outboundUserAgent) {
        throw new Error("no user agent to start conference with");
      }
      if (!uri) {
        this.targetInput.nativeElement.focus();
        return;
      }

      this.localDisplayName = outboundUserAgent
        ? outboundUserAgent.displayName
        : this.localDisplayName;
      if (this.videoConferenceService.getNewVideoConferenceState() === "open") {
        const modal = this.dialog.open(ModalMaterialComponent, {
          panelClass: ["mat-typography", "onsip-dialog-universal-style"],
          data: {
            title: this.translate.instant("ONSIP_I18N.DO_YOU_WANT_TO_LEAVE__CURRENT__FOR__NEW_", {
              current: this.videoConferenceService.getVideoConferenceName(),
              new: potentialUsername
            }),
            secondaryBtnText: this.translate.instant("NO"),
            primaryBtnText: this.translate.instant("YES"),
            primaryBtnFlat: true
          }
        });

        this.unsubscriber.add(
          modal.afterClosed().subscribe(response => {
            if (response && response.doPrimaryAction) {
              this.videoConferenceService.switchingNewVideoConferenceState();
              this.router.navigate([views.VIDEO_CONFERENCE]);
              this.startGumConferenceCall(potentialUsername, uri, outboundUserAgent);
            } else {
              this.outputStartedCall.emit("closed");
              return;
            }
          })
        );
      } else {
        this.router.navigate([views.VIDEO_CONFERENCE]);
        this.startGumConferenceCall(potentialUsername, uri, outboundUserAgent);
      }
    }
  }

  startGumConferenceCall(
    potentialUsername: string,
    uri: URI | string,
    outboundUserAgent: UserAgent
  ): void {
    this.gumService
      .getUserMedia({ video: true, audio: true })
      .then(stream => {
        this.gumPending = false;
        const data = {
          anonymous: this.anonymous,
          localDisplayName: this.localDisplayName,
          conferenceName: potentialUsername,
          conferenceUri: uri,
          userAgent: outboundUserAgent,
          mediaStream: stream
        };
        this.videoConferenceService.addNewVideoConferenceData(data);
        this.outputStartedCall.emit("started");
      })
      .catch(() => {
        this.gumPending = false;
      });
  }

  getErrorMessage(inputType: string) {
    if (inputType === "target") {
      return this.targetControl.hasError("required")
        ? this.translate.instant("ONSIP_I18N.CONFERENCE_NAME_REQUIRED")
        : this.targetControl.hasError("invaliduri")
        ? this.translate.instant("ONSIP_I18N.INVALID_CONFERENCE_NAME")
        : "";
    } else if (inputType === "anon") {
      return this.localDisplayControl.hasError("required")
        ? this.translate.instant("ONSIP_I18N.NAME_REQUIRED")
        : "";
    }
  }

  private uriValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const name = control.value.replace(" ", "_");
      if (name === "") {
        // eslint-disable-next-line no-null/no-null
        return null;
      }
      const outboundUserAgent: UserAgent | undefined =
        this.callControllerService.getOutboundUserAgent();
      const uri: URI | undefined = outboundUserAgent
        ? outboundUserAgent.targetToURI(name)
        : undefined;
      // eslint-disable-next-line no-null/no-null
      return uri ? null : { invaliduri: { value: control.value } };
    };
  }
}
