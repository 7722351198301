import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import {
  matchingPasswordValidator,
  atLeastOneNumberValidator,
  atLeastOneSymbolValidator,
  invalidCharacterValidator
} from "../password.validator";
import { ResetPasswordUsingEmailKeyService } from "@onsip/common/services/api/resources/resetPasswordUsingEmailKey/reset-password-using-email-key.service";
import { UserEditPortalPasswordService } from "@onsip/common/services/api/resources/userEditPortalPassword/user-edit-portal-password.service";

interface PasswordFormGroup extends FormGroup {
  controls: { [key: string]: FormControl };
} // typing hack

@Component({
  selector: "onsip-password-checker",
  templateUrl: "./password-checker.component.html",
  styleUrls: ["./password-checker.scss"],
  standalone: false
})
export class PasswordCheckerComponent implements OnInit, OnDestroy {
  @Input() emailKey: string | undefined;
  @Input() sipAddress = "";
  @Input() modalRef: any;

  @Output() errorEmitter: EventEmitter<any> = new EventEmitter();

  passwordMin = 8;
  passwordMax = 64;
  error = "";

  currentPassword: FormControl = new FormControl("");
  passwordFormGroup = new FormGroup(
    {
      newPassword: new FormControl("", [
        Validators.minLength(this.passwordMin),
        Validators.maxLength(this.passwordMax),
        // regex: any normal chars but cannot start or end with whitespace
        Validators.pattern("^[\\S][\\S\\s]*[\\S]+$"),
        atLeastOneNumberValidator(),
        atLeastOneSymbolValidator(),
        invalidCharacterValidator()
      ]),
      verifyPassword: new FormControl("") // Any validation other than "matching" here is overkill
    },
    matchingPasswordValidator("newPassword", "verifyPassword")
  ) as PasswordFormGroup;

  newPassword = this.passwordFormGroup.controls.newPassword as FormControl;
  verifyPassword = this.passwordFormGroup.controls.verifyPassword as FormControl;

  hideCurrentPassword = true;
  hideNewPassword = true;
  hideVerifyPassword = true;

  private loginUrl =
    window.location.protocol +
    "//" +
    window.location.host +
    location.pathname.substring(0, location.pathname.lastIndexOf("/"));
  private unsubscriber = new Subscription();

  constructor(
    private translate: TranslateService,
    private resetPasswordService: ResetPasswordUsingEmailKeyService,
    private userEditPasswordSerivce: UserEditPortalPasswordService
  ) {}

  ngOnInit() {
    this.unsubscriber.add(
      this.passwordFormGroup.statusChanges.subscribe(() => {
        if (this.passwordFormGroup.hasError("notMatchingPassword")) {
          this.verifyPassword.setErrors({ notMatchingPassword: true }, { emitEvent: false });
        } else {
          // eslint-disable-next-line no-null/no-null
          this.verifyPassword.setErrors(null, { emitEvent: false });
        }
      })
    );
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
  }

  reset(): void {
    if (this.passwordFormGroup.invalid) {
      // this bit is weird because we are displaying the same message twice
      // not going to make the decision to remove it so we will just get the error message from getErrorMessage()
      if (this.passwordFormGroup.controls.newPassword.invalid) {
        this.error = this.getErrorMessage();
      } else if (this.passwordFormGroup.hasError("notMatchingPassword")) {
        this.error = this.translate.instant("ONSIP_I18N.PASSWORDS_MUST_MATCH");
      }
      return;
    }

    if (this.emailKey) {
      this.resetPasswordService
        .resetPasswordUsingEmailKey({
          EmailKey: this.emailKey,
          Username: this.sipAddress,
          Password: this.newPassword.value
        })
        .then(data => {
          if (data.status === "success") {
            location.href = this.loginUrl + "/#/login?reset=1";
          } else {
            // need better error handling here
            this.error = this.translate.instant(
              "ONSIP_I18N.YOUR_ACTIVATION_LINK_HAS_EXPIRED_CLICK_ON_THE_BUTTON_BELOW_TO_REQUEST_A_NEW_LINK_TO_BE_SENT_TO_YOUR_EMAIL"
            );
            this.errorEmitter.emit(this.error);
          }
        });
    } else {
      this.userEditPasswordSerivce
        .userEditPortalPassword({
          CurrentPortalPassword: this.currentPassword.value,
          NewPortalPassword: this.newPassword.value,
          NewPortalPasswordConfirm: this.verifyPassword.value
        })
        .then(res => {
          if (res.status === "success") {
            // should always have this here
            this.modalRef.close && this.modalRef.close("reset-complete");
          } else {
            this.error = this.translate.instant(
              "ONSIP_I18N.PASSWORD_CHANGE_FAILED_CURRENT_PASSWORD_INCORRECT"
            );
            this.errorEmitter.emit(this.error);
          }
        });
    }
  }

  getErrorMessage() {
    const control = this.newPassword;
    return control.hasError("minlength")
      ? this.translate.instant("ONSIP_I18N.PASSWORD_MUST_BE_AT_LEAST__VALUE__CHARACTERS", {
          value: this.passwordMin
        })
      : control.hasError("maxlength")
      ? this.translate.instant("ONSIP_I18N.CHARACTER_LIMIT_REACHED")
      : control.hasError("atLeastOneNumber")
      ? this.translate.instant("ONSIP_I18N.PASSWORD_MUST_CONTAIN_AT_LEAST_ONE_NUMBER")
      : control.hasError("atLeastOneSymbol")
      ? this.translate.instant("ONSIP_I18N.PASSWORD_MUST_CONTAIN_AT_LEAST_ONE_SYMBOL")
      : control.hasError("invalidCharacter")
      ? this.translate.instant("ONSIP_I18N.PASSWORD_CONTAINS_AN_INVALID_CHARACTER")
      : control.hasError("pattern")
      ? this.translate.instant("ONSIP_I18N.PASSWORD_CANNOT_CONTAIN_SPACES_AT_THE_BEGINNING_OR_END")
      : "";
  }

  getMatchingErrorMessage() {
    return this.verifyPassword.hasError("notMatchingPassword")
      ? this.translate.instant("ONSIP_I18N.PASSWORDS_MUST_MATCH")
      : "";
  }
}
