import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { Validators, AbstractControl, NonNullableFormBuilder, FormGroup } from "@angular/forms";
import {
  E911AddressType,
  E911LocationParams,
  EditE911LocationParams
} from "../../../../common/services/api/resources/e911/e911-location";
import { listOfStates } from "../../../../common/interfaces/state";
import {
  InvalidOnsipAPIRequest,
  OnsipAPIError
} from "../../../../common/services/api/apiResponse/context";
import { views } from "../../../app/phone/views";
import { MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { LoadingModalComponent } from "../../shared/components/loadingModal/loading-modal.component";
import { TranslateService } from "@ngx-translate/core";
import { IApiError } from "../../../../common/services/api/onsip-api-action";
import { NAME_VALIDATOR_REGEX } from "@onsip/common/libraries/validators/name-validators";

// I mean, only e911 uses error messages like this. Could be a legacy way we stored messages in the past
interface AlternativeErrors extends OnsipAPIError {
  Alternatives?: {
    Alternative: string;
  };
}

@Component({
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AdminE911BaseFormComponent implements OnDestroy {
  e911FormGroup = this.fb.group({
    name: ["", [Validators.maxLength(60), Validators.pattern(NAME_VALIDATOR_REGEX)]],
    address: ["", [Validators.maxLength(50), Validators.pattern("^[0-9A-Za-z #'.-]+$")]],
    secondaryAddressType: [<E911AddressType>""],
    secondaryAddressNumber: [
      "",
      [Validators.maxLength(49), Validators.pattern("^[0-9A-Za-z -]+$")]
    ],
    city: ["", [Validators.maxLength(30), Validators.pattern("^[0-9A-Za-z '.-]+$")]],
    state: [""],
    zipcode: [
      "",
      [
        // patterns for 5 number zipcode, 9 number zip code, and canadian postal code
        Validators.pattern(
          "(^[0-9]{5}$)|(^[0-9]{5}[-][0-9]{4}$)|(^[A-Z][0-9][A-Z] [0-9][A-Z][0-9]$)"
        )
      ]
    ],
    email: ["", [Validators.email]],
    /** additional params that gets added at the component level */
    additionalParams: this.fb.group({})
  });

  listOfStates = listOfStates.sort(([, symbolA], [, symbolB]) => (symbolA < symbolB ? -1 : 1));

  addressTypesArray: Array<E911AddressType> = Object.values(E911AddressType);

  views = views;

  // flag to check if there is an api issue
  hasApiError = false;

  // helper error messages from the api that can suggest corrections to the user
  apiErrorMessages!: Record<"address" | "city" | "state" | "zipcode", Array<string>>;

  protected currentE911Params!: E911LocationParams | EditE911LocationParams;
  protected loadingModal!: MatDialogRef<LoadingModalComponent>;
  protected unsubscriber = new Subscription();

  constructor(protected translate: TranslateService, protected fb: NonNullableFormBuilder) {}

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
  }

  get additionalParams(): FormGroup {
    return this.e911FormGroup.controls.additionalParams;
  }

  // Error Message Handling
  getGenericNameError(control: AbstractControl, value: string): string {
    return control.hasError("required")
      ? this.translate.instant("ONSIP_I18N._VALUE__IS_REQUIRED", { value })
      : control.hasError("maxlength")
      ? this.translate.instant("ONSIP_I18N.VALUE_IS_LONGER_THAN_LENGTH_CHARACTERS", {
          value,
          length: control.errors?.maxlength.requiredLength
        })
      : control.hasError("pattern")
      ? this.translate.instant(
          "ONSIP_I18N._VALUE__CAN_ONLY_CONTAIN_LETTERS_SPACES_QUOTES_OR_DASHES",
          { value }
        )
      : "";
  }

  getAddressError(): string {
    const control = this.e911FormGroup.controls.address;
    const value = this.translate.instant("ONSIP_I18N.ADDRESS");
    return control.hasError("required")
      ? this.translate.instant("ONSIP_I18N._VALUE__IS_REQUIRED", {
          value
        })
      : control.hasError("maxlength")
      ? this.translate.instant("ONSIP_I18N.VALUE_IS_LONGER_THAN_LENGTH_CHARACTERS", {
          value,
          length: control.errors?.maxlength.requiredLength
        })
      : control.hasError("pattern")
      ? this.translate.instant("ONSIP_I18N.ONLY_LETTERS_NUMBERS_OR_THE_SYMBOLS__ACCEPTED")
      : control.hasError("apiAlternative")
      ? this.apiErrorMessages.address.join(" or ")
      : "";
  }

  getCityError(): string {
    const control = this.e911FormGroup.controls.city;
    return control.hasError("required")
      ? this.translate.instant("ONSIP_I18N._VALUE__IS_REQUIRED", {
          value: this.translate.instant("ONSIP_I18N.CITY")
        })
      : control.hasError("maxlength")
      ? this.translate.instant("ONSIP_I18N.CITY_NAME_IS_LONGER_THAN_30_CHARACTERS_LONG")
      : control.hasError("pattern")
      ? this.translate.instant("ONSIP_I18N.CITY_NAME_MAY_CONTAIN_ONLY_NUMBERS_OR_LETTERS")
      : control.hasError("apiAlternative")
      ? this.apiErrorMessages.city[0]
      : "";
  }

  getStateError(): string {
    const control = this.e911FormGroup.controls.state;
    return control.hasError("required")
      ? this.translate.instant("ONSIP_I18N._VALUE__IS_REQUIRED", {
          value: this.translate.instant("ONSIP_I18N.STATE")
        })
      : control.hasError("apiAlternative")
      ? this.apiErrorMessages.state[0]
      : "";
  }

  getPostalCodeError(): string {
    const control = this.e911FormGroup.controls.zipcode;
    return control.hasError("required")
      ? this.translate.instant("ONSIP_I18N._VALUE__IS_REQUIRED", {
          value: this.translate.instant("ONSIP_I18N.ZIPCODE")
        })
      : control.hasError("pattern")
      ? this.translate.instant("ONSIP_I18N.MUST_BE_A_VALID_5_OR_9DIGIT_US_POSTAL_CODE")
      : control.hasError("apiAlternative")
      ? this.apiErrorMessages.zipcode[0]
      : "";
  }

  // We can get address, city, and zipcode suggestion from the backend error messages
  // function here is to get those suggestion or alternative response to a more accessible variable aka apiErrorMessages
  protected handleApiErrors(apiErrors: IApiError): void {
    const errors = (apiErrors.Response.Context.Request as InvalidOnsipAPIRequest).Errors.Error as
      | AlternativeErrors
      | Array<AlternativeErrors>;
    // reset over error storing object
    this.apiErrorMessages = {
      address: [],
      city: [],
      state: [],
      zipcode: []
    };
    if (Array.isArray(errors)) {
      errors.forEach(error => this.getAlternativeErrors(error));
    } else {
      this.getAlternativeErrors(errors);
    }
  }

  private getAlternativeErrors(error: AlternativeErrors): void {
    if (error.Alternatives) {
      switch (error.Parameter) {
        case "StreetNumber":
          // eh the e911 alternative message isn't perfect and can suggest alternative which is the same as the input
          // let's just check if they are the same then skip the setting error step
          if (
            this.currentE911Params.StreetNumber.toLowerCase() ===
            error.Alternatives.Alternative.toLowerCase().trim()
          ) {
            break;
          }
          this.e911FormGroup.controls.address.setErrors({ apiAlternative: true });
          this.apiErrorMessages.address.push(`Alternatives: ${error.Alternatives.Alternative}`);
          break;
        case "StreetName":
          if (
            this.currentE911Params.StreetName.toLowerCase() ===
            error.Alternatives.Alternative.toLowerCase().trim()
          ) {
            break;
          }
          this.e911FormGroup.controls.address.setErrors({ apiAlternative: true });
          this.apiErrorMessages.address.push(`Alternatives: ${error.Alternatives.Alternative}`);
          break;
        case "City":
          if (
            this.currentE911Params.City.toLowerCase() ===
            error.Alternatives.Alternative.toLowerCase().trim()
          ) {
            break;
          }
          this.e911FormGroup.controls.city.setErrors({ apiAlternative: true });
          this.apiErrorMessages.city.push(`Alternatives: ${error.Alternatives.Alternative}`);
          break;
        case "State":
          if (
            this.currentE911Params.State.toLowerCase() ===
            error.Alternatives.Alternative.toLowerCase().trim()
          ) {
            break;
          }
          this.e911FormGroup.controls.state.setErrors({ apiAlternative: true });
          this.apiErrorMessages.state.push(`Alternatives: ${error.Alternatives.Alternative}`);
          break;
        case "Zipcode":
          if (
            this.currentE911Params.Zipcode.toLowerCase() ===
            error.Alternatives.Alternative.toLowerCase().trim()
          ) {
            break;
          }
          this.e911FormGroup.controls.zipcode.setErrors({ apiAlternative: true });
          this.apiErrorMessages.zipcode.push(`Alternatives: ${error.Alternatives.Alternative}`);
          break;
      }
    }
  }
}
