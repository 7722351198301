import { CurrentContactService } from "./current-contact.service";

import { views } from "../../app/phone/views";

import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

import { Contact } from "../../../common/interfaces/contact";
import { WebRTCSupport } from "../../../common/services/support/webrtc-support";

@Component({
  selector: "onsip-contact-page",
  templateUrl: "./contact-page.component.html",
  standalone: false
})
export class ContactPageComponent implements OnInit, OnDestroy {
  views: any = views;
  currentContact: Contact | undefined;

  private unsubscriber = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private currentContactService: CurrentContactService
  ) {}

  ngOnInit(): void {
    this.unsubscriber.add(
      this.currentContactService.state.subscribe(contact => {
        this.currentContact = contact;
        this.setTitle();
      })
    );

    this.unsubscriber.add(
      this.route.url.subscribe(() => {
        this.setTitle();
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  browser(userAgent: string): string {
    const result = WebRTCSupport.parseUserAgent(userAgent);
    if (result === undefined) {
      return "Unknown";
    }
    const browser = this.capitalizeFirstLetter(result.name);
    const version = result.version;
    return `${browser} ${version}`;
  }

  private capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  private setTitle() {
    if (this.currentContact) {
      this.setDocumentTitle(this.currentContact.name + "'s Info");
    }
  }

  private setDocumentTitle(title: string): void {
    document.title = title.length ? "OnSIP - " + title : "OnSIP";
  }
}
