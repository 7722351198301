import { Component, inject, OnDestroy } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { entryViews } from "../../entryPage/entry-views";
import { ActivatedRoute, RouterModule } from "@angular/router";
import { SnackbarService } from "../../snackbar/snackbar.service";
import { ApiAction } from "@onsip/common/services/api/api-actions";
import { genericApiAction } from "@onsip/common/services/api/onsip-api-action";
import { Subscription, take } from "rxjs";

@Component({
  selector: "onsip-confirm-mfa",
  templateUrl: "./confirm-mfa.component.html",
  styleUrl: "./confirm-mfa.component.scss",
  imports: [MatCardModule, MatButtonModule, RouterModule]
})
export class ConfirmMfaComponent implements OnDestroy {
  entryViews = entryViews;
  route = inject(ActivatedRoute);
  snackbar = inject(SnackbarService);
  unsubscriber = new Subscription();

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  handleVerify() {
    this.unsubscriber.add(
      this.route.queryParamMap.pipe(take(1)).subscribe(queryParamMap => {
        const UserId = queryParamMap.get("UserId");
        const Type = queryParamMap.get("Type");
        const AuthToken = queryParamMap.get("AuthToken");
        if (UserId && Type && AuthToken && Type === "MfaEmail") {
          genericApiAction<any>(ApiAction.MfaConfirm, { UserId, Type, AuthToken })
            .then(() => {
              this.snackbar.openSnackBar(
                "Success, you have verified your account and can now login.",
                "success"
              );
              window.location.href = ".";
            })
            .catch(err => {
              console.error(err);
              this.snackbar.openSnackBar(
                "Your account cannot be verified. Contact your administrator or OnSIP support.",
                "error"
              );
            });
        } else {
          this.snackbar.openSnackBar(
            "Your account cannot be verified. Contact your administrator or OnSIP support.",
            "error"
          );
        }
      })
    );
  }
}
