import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy
} from "@angular/core";
import { Router } from "@angular/router";

import { Subscription, Observable, BehaviorSubject, combineLatest } from "rxjs";
import { filter, take, pluck, map, distinctUntilChanged } from "rxjs/operators";

import { UserService } from "../../../../../../common/services/api/resources/user/user.service";
import { ContactService } from "../../../../../../common/services/contact/contact.service";
import { CurrentContactService } from "../../../../contact/current-contact.service";
import { SnackbarService } from "../../snackbar/snackbar.service";
import { AnalyticsService } from "../../analytics/analytics.service";
import { SubscriptionControllerService } from "../../../../../../common/services/subscription-controller.service";
import { ChatService } from "../../chat/chat.service";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { UsersStoreSubscriptionService } from "../../../services/userStoreSubscription/users-store-subscription.service";
import { CallControllerService } from "../../../../../../common/services/call-controller.service";
import { UserAvailability } from "../../../../../../common/services/sayso/users-store.service";

import { views } from "../../../../../app/phone/views";

import { Contact } from "../../../../../../common/interfaces/contact";
import {
  Presentity,
  DialogInfo,
  ExtendedDialogInfo,
  PersonalDialog
} from "../../../../../../common/interfaces/presentity";

// mat-dialog new imports
import { ModalMaterialComponent } from "../../modal/modal-material.component";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { DragDropCallService } from "../../../services/dragDropCall/drag-drop-call.service";

import { ContactListItem } from "./contact-list-interface";
import { E2eLocators } from "@onsip/e2e/configs/locators";

interface SidebarListDiff {
  removals: Array<Contact>;
  additions: Array<Contact>;
}

@Component({
  selector: "onsip-contact-list",
  templateUrl: "./contact-list.component.html",
  styleUrls: ["./contact-list.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ContactListComponent implements OnInit, OnDestroy {
  @ViewChild("searchContacts") searchContacts!: ElementRef;
  E2eLocators = E2eLocators;

  filteredItems = new BehaviorSubject<Array<ContactListItem>>([]);
  sidebarList: Array<ContactListItem> = [];
  views = views;
  displaySearchBar = false;
  searchString = "";
  /** Observable tracking user's busy state aka user is on a connected call */
  userBusy!: Observable<boolean>;
  /** Subject storing each contact's unread messages: key: contact.uuid, value: unread messages */
  contactsUnread = new BehaviorSubject<Record<string, number>>({});
  /** Subject storing each contact's global availabiliity: key: contact.uuid, value: "available" | "unavailable" | "undefined" */
  contactsAvailability = new BehaviorSubject<Record<string, UserAvailability>>({});
  /** Subject storing each contact's busyness based on dialogInfo: key: contact.uuid, value: bestDialogInfo.priority > 0 */
  contactsBusy = new BehaviorSubject<Record<string, boolean>>({});
  /** Subject storing an object of possible presentity for each contact: key: contact.uuid, value: key: presentity's aor, value: Presentity */
  contactsPresentities: Record<string, Record<string, Presentity>> = {};
  /** Subject storing each contact's best presentity based off its best dialogInfo: key : contact.uuid, value: presentity */
  contactsBestPresentity = new BehaviorSubject<Record<string, Presentity>>({});
  /** Subject storing each contact's best dialog info: the one of highest priority and/or oldest confirmed time: key: contact.uuid, value: dialogInfo */
  contactsBestDialogInfo = new BehaviorSubject<Record<string, DialogInfo>>({});

  private unsubscriber = new Subscription();
  private uaPresentities: Array<Presentity> = [];
  private currentContact: Contact | undefined;
  private contacts: Array<Contact> = [];

  private static compareByName(a: ContactListItem, b: ContactListItem): number {
    const aName: string = a.name.toLowerCase(),
      bName: string = b.name.toLowerCase();

    if (aName > bName) {
      return 1;
    } else if (aName < bName) {
      return -1;
    } else {
      return 0;
    }
  }

  constructor(
    private router: Router,
    private contactService: ContactService,
    private currentContactService: CurrentContactService,
    private snackbarService: SnackbarService,
    private analyticsService: AnalyticsService,
    private subscriptionControllerService: SubscriptionControllerService,
    private chatService: ChatService,
    private userService: UserService,
    private notificationsService: NotificationsService,
    private usersStoreSubscriptionService: UsersStoreSubscriptionService,
    private callControllerService: CallControllerService,
    private translate: TranslateService,
    private dialog: MatDialog,
    public dragDropCallService: DragDropCallService
  ) {}

  ngOnInit(): void {
    this.chatService.onProviderChange(() => {
      const isOnsipChat: boolean = this.chatService.isProviderEnabled("onsip");
      // temporary object to store kv pair for contact's unread count
      const storeContactUnread: Record<string, number> = {};

      this.contacts.forEach(contact => {
        if (isOnsipChat) {
          storeContactUnread[contact.uuid] = 0;
        }
      });

      this.contactsUnread.next(storeContactUnread);
    });

    this.unsubscriber.add(
      this.currentContactService.state.subscribe(cc => {
        if (
          cc &&
          this.currentContact?.contactId === cc?.contactId &&
          this.currentContact?.name !== cc?.name
        ) {
          this.updateItemSidebarList(cc);
        }
        this.currentContact = cc;
        if (this.currentContact && this.contactsUnread.value[this.currentContact.uuid]) {
          this.contactsUnread.next({
            ...this.contactsUnread.value,
            ...{ [this.currentContact.uuid]: 0 }
          });
        }
      })
    );

    this.unsubscriber.add(
      this.chatService.event.subscribe(e => {
        // has members onsipContact and chatBody
        // when chatService gets a message it tells contactListComponent who updates unread counts
        const onsipContact = e.onsipContact
          ? this.contactService.findUsingAOR(e.onsipContact.addresses[0].address)
          : undefined;
        if (
          this.router.url === views.CONTACT &&
          this.currentContact &&
          onsipContact &&
          onsipContact.uuid === this.currentContact.uuid
        ) {
          // the new message is visible, so it has been read
          if (e.chatBody.unreadCount && e.chatBody.unreadCount > 0) {
            e.chatBody.unreadCount = 0;
            this.chatService.platformSendReadCursorUpdate(e.chatBody);
          }
        } else {
          const properId: string = onsipContact ? onsipContact.uuid : e.chatBody.uuid,
            shouldNotify: boolean = onsipContact
              ? !!this.contacts.find(
                  contact => contact.name === (onsipContact && onsipContact.name)
                )
              : false;

          if (!properId) {
            return;
          }

          e.chatBody.unreadCount = e.chatBody.unreadCount ? e.chatBody.unreadCount : 1;
          this.contactsUnread.next({
            ...this.contactsUnread.value,
            ...{ [properId]: e.chatBody.unreadCount }
          });
          if (e.chatBody.unreadCount > 0 && shouldNotify) {
            this.notificationsService.notifyOnChat(e.chatBody.contact.displayName);
          }
        }
      })
    );

    this.unsubscriber.add(
      combineLatest([this.userService.selfUser, this.contactService.getContactList$()]).subscribe(
        ([user, contacts]) => {
          let directoryContacts: Array<Contact> = [];

          directoryContacts = contacts.filter(
            contact => !contact.userId || this.contactService.find(contact.uuid) !== undefined
          );

          const dirContactUuidSet = new Set();
          directoryContacts.forEach(contact => dirContactUuidSet.add(contact.uuid));
          const oldContactUuidSet = new Set();
          this.contacts.forEach(contact => oldContactUuidSet.add(contact.uuid));

          const diff: { removals: Array<Contact>; additions: Array<Contact> } = {
            removals: this.contacts.filter(oldItem => !dirContactUuidSet.has(oldItem.uuid)),
            additions: directoryContacts.filter(
              newItem =>
                !oldContactUuidSet.has(newItem.uuid) &&
                (!newItem.userId || newItem.userId !== user.userId)
            )
          };
          diff.removals.forEach(removedContact => {
            const removedIndex: number = this.contacts.indexOf(removedContact);

            if (removedIndex >= 0) {
              this.contacts.splice(removedIndex, 1);
            }
          });

          diff.additions.forEach(addedContact => {
            // start listening to newly added contact's availability
            const uid = parseInt(addedContact.userId);
            if (!uid) return; // custom contacts uid which is "", parseInt("") -> NaN which we don't want being sent out to firestore
          });

          this.contacts = this.contacts.concat(diff.additions);
          this.updateSidebarList(diff);
        }
      )
    );

    this.unsubscriber.add(
      this.usersStoreSubscriptionService.state.subscribe(() => {
        const diffContactsAvailability: Record<string, UserAvailability> = {};
        this.contacts.forEach(contact => {
          const contactUid = contact.userId;
          const contactAvailability =
            this.usersStoreSubscriptionService.getUserAvailabilityByUid(contactUid);
          if (contactAvailability) {
            diffContactsAvailability[contact.uuid] = contactAvailability;
          }
        });
        this.contactsAvailability.next({
          ...this.contactsAvailability.value,
          ...diffContactsAvailability
        });
      })
    );

    this.unsubscriber.add(
      this.subscriptionControllerService.state.subscribe(state => {
        this.uaPresentities = [];

        state.presentity.forEach(pres => {
          if (pres.event === "dialog") {
            this.processDialog(pres);
          }
        });
      })
    );
    this.searchString = this.translate.instant("ONSIP_I18N.SEARCH_CONTACTS");

    this.userBusy = this.callControllerService.state.pipe(
      pluck("groups"),
      filter(groups => groups.length > 0),
      map(groups => !!groups.find(call => call.connected)),
      distinctUntilChanged()
    );
  }

  applyFilter(filterTarget: EventTarget | null): void {
    const filterValue = (filterTarget as any).value.toLowerCase();
    this.filteredItems.next(
      this.sidebarList.filter(item => item.name.toLowerCase().includes(filterValue))
    );
  }

  completeQuickRemove(item: ContactListItem): void {
    const contact = this.contactService.find(item.uuid);

    if (contact) {
      this.contactService.removeContact([contact]);
    }
  }

  navigateToItem(item: ContactListItem): void {
    let dialog: PersonalDialog | undefined;

    this.uaPresentities.some(pres => {
      const activeDialogs: Array<PersonalDialog> = (
        pres.eventData as ExtendedDialogInfo
      ).dialogs.filter(innerDialog => {
        return innerDialog.priority > 0;
      });

      item.aors &&
        item.aors.some(aor => {
          dialog = activeDialogs.find(dlg => dlg.remoteAor === aor || dlg.localAor === aor);

          return !!dialog;
        });

      return !!dialog;
    });

    const contact = this.contactService.find(item.uuid);

    if (contact) {
      this.currentContactService.state.next(contact);
      this.router.navigate([views.CONTACT]);
    }
  }

  quickRemove(item: ContactListItem, ev: Event): void {
    ev.stopPropagation();
    // Don't show me again previously clicked. Don't display dialog.
    if (this.contactService.getRemoveFastPass()) {
      this.completeQuickRemove(item);
      return;
    }
    // open mat-dialog to remove contact or group chat
    const modal = this.dialog.open(ModalMaterialComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"], // global material styles on dialog
      data: {
        title: this.translate.instant("ONSIP_I18N.REMOVE_CONTACT_1"),
        message: this.translate.instant(
          "ONSIP_I18N.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_CONTACT_FROM_YOUR_CONTACT_LIST"
        ),
        showFastPass: true,
        primaryBtnText: this.translate.instant("ONSIP_I18N.REMOVE"),
        primaryBtnFlat: true
      }
    });

    this.unsubscriber.add(
      modal
        .afterClosed()
        .pipe(take(1))
        .subscribe({
          next: retObj => {
            if (retObj && retObj.doPrimaryAction) {
              if (item.isContact) {
                const contact = this.contactService.find(item.uuid);

                if (contact) {
                  this.analyticsService.sendContactEvent("Quick Remove", contact, undefined);
                }
              }
              this.completeQuickRemove(item);
              if (retObj.fastPass) {
                this.contactService.setRemoveFastPass(true);
              }
            }
          },
          error: (err: unknown) => {
            // enter here on cancel
            if (err && (err as any).message) {
              this.snackbarService.openSnackBar((err as any).message, "error");
            }
          }
        })
    );
  }

  toggleSearch(): void {
    this.displaySearchBar = !this.displaySearchBar;
    if (this.displaySearchBar) {
      this.searchContacts.nativeElement.value = "";
      this.searchContacts.nativeElement.focus();
    } else {
      this.filteredItems.next(
        this.sidebarList.filter(item => item.name.toLowerCase().includes(""))
      );
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  private processDialog(pres: Presentity): void {
    const contact: Contact | undefined = this.contactService.findUsingAOR(pres.aor);

    if (!contact) {
      if ((pres.eventData as ExtendedDialogInfo).dialogs) {
        this.uaPresentities.push(pres);
      }

      return;
    }
    this.contactsPresentities[contact.uuid] = this.contactsPresentities[contact.uuid] || {};

    // We need to keep track of the most interesting dialog for every address associated with a contact
    this.contactsPresentities[contact.uuid][pres.aor] = pres;

    // NOTE: ideally, each contact list item should handle their own presentity and this is coming in the near future
    // To prevent consistent refreshing of the subject after each presentity update,
    // we will have to modify the behavior subject value directly for the next state instead of creating a new object after each update
    const diffBestPresentities: Record<string, Presentity> = this.contactsBestPresentity.value;
    const diffBestDialogInfo: Record<string, DialogInfo> = this.contactsBestDialogInfo.value;
    const diffContactsBusy: Record<string, boolean> = {};
    contact.aors.forEach(() => {
      // For every call a particular entity is on
      // We want to find the most "interesting" call counting the notify update
      // (i.e. the longest running active call). If no active calls are available, take proceeding/early, or else terminated
      let bestPresentity: Presentity | undefined, bestDialogInfo: DialogInfo | undefined;

      Object.values(this.contactsPresentities[contact.uuid]).forEach(innerAddress => {
        const potentialBest: Presentity | undefined = innerAddress,
          potentialDialogInfo: DialogInfo | undefined = potentialBest
            ? (potentialBest.eventData as DialogInfo)
            : undefined;

        if (!potentialBest || !potentialDialogInfo) {
          return;
        }

        if (
          !bestPresentity ||
          !bestDialogInfo ||
          bestDialogInfo.priority < potentialDialogInfo.priority ||
          (potentialDialogInfo.priority === 4 &&
            (!bestDialogInfo.confirmedTime ||
              new Date(bestDialogInfo.confirmedTime).getTime() >
                new Date(potentialDialogInfo.confirmedTime).getTime()))
        ) {
          bestDialogInfo = potentialDialogInfo;
          bestPresentity = potentialBest;
        }
      });

      if (!bestPresentity || !bestDialogInfo) {
        return;
      }
      const currentBestPresentity = this.contactsBestPresentity.value[contact.uuid];
      const currentBestDialogInfo = this.contactsBestDialogInfo.value[contact.uuid];

      if (currentBestPresentity !== bestPresentity) {
        diffBestPresentities[contact.uuid] = bestPresentity;
      }
      if (currentBestDialogInfo !== bestDialogInfo) {
        diffBestDialogInfo[contact.uuid] = bestDialogInfo;
      }
      diffContactsBusy[contact.uuid] = bestDialogInfo.priority > 0;
    });
    this.contactsBestPresentity.next(diffBestPresentities);
    this.contactsBestDialogInfo.next(diffBestDialogInfo);
    this.contactsBusy.next({ ...this.contactsBusy.value, ...diffContactsBusy });
  }

  private updateSidebarList(diff: SidebarListDiff): void {
    diff.removals.forEach(removedContact => {
      const removedUuid = removedContact.uuid;
      const removedIndex = this.sidebarList.findIndex(
        contactListItem => contactListItem.uuid === removedUuid
      );
      this.sidebarList.splice(removedIndex, 1);
    });
    diff.additions.forEach(addedContact => {
      this.sidebarList.push({
        isContact: true,
        name: addedContact.name,
        avatarUrl: addedContact.avatarUrl,
        uuid: addedContact.uuid,
        aors: addedContact.aors,
        contactId: addedContact.contactId,
        dragDropTarget:
          (addedContact.aors && addedContact.aors[0]) ||
          (addedContact.e164PhoneNumbers && addedContact.e164PhoneNumbers[0])
      });
    });
    this.sidebarList.sort(ContactListComponent.compareByName);
    this.filteredItems.next(this.sidebarList);
  }

  private updateItemSidebarList(item: Contact) {
    this.sidebarList = this.sidebarList.map(sidebarItem => {
      if (sidebarItem.contactId === this.currentContact?.contactId) {
        return { ...sidebarItem, name: item.name };
      }
      return sidebarItem;
    });
    this.sidebarList.sort(ContactListComponent.compareByName);
    this.filteredItems.next(this.sidebarList);
  }
}
