import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from "@angular/core";
import { ContactService } from "@onsip/common/services/contact/contact.service";
import { Contact } from "@onsip/common/interfaces/contact";
import { OnSIPURI } from "@onsip/common/libraries/onsip-uri";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Breakpoints } from "@angular/cdk/layout";
import { Subscription } from "rxjs";

@Component({
  selector: "onsip-admin-support-contact-support",
  templateUrl: "./admin-support-contact-support.component.html",
  styleUrls: ["./admin-support-contact-support.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AdminSupportContactSupportComponent implements OnInit, OnDestroy {
  isSmallBreakpoint = false;

  contact: Contact | undefined;

  private unsubscriber = new Subscription();

  constructor(private breakpointObserver: BreakpointObserver, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.contact = this.makeSupportContact();
    this.setBreakpoints();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  private setBreakpoints(): void {
    this.unsubscriber.add(
      this.breakpointObserver.observe(Breakpoints.Small).subscribe(result => {
        this.isSmallBreakpoint = result.matches;
        this.cdRef.markForCheck();
      })
    );
  }

  private makeSupportContact(): Contact {
    const uri = OnSIPURI.parseString(`sip:support@onsip.com`);
    if (uri) {
      return ContactService.createCustomSIPContact("OnSIP Support", uri);
    } else {
      throw new Error(
        "ContactOnSIPComponent.makeDeptContact: uri parse failed - should never happen"
      );
    }
  }
}
