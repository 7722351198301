import { Component, Input, OnChanges } from "@angular/core";
import { timer, Observable, of } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "onsip-timer",
  template: "<span>{{timer | async | duration: hoursZeroes}}</span>",
  standalone: false
})

/** Handle timer subscriptions for simple cases
 *  For call timers see call-timer.service.ts
 */
export class TimerComponent implements OnChanges {
  /** Input Reference Point: display secons since this
   *  Date, unix timestamp, or ISO String. Defaults to Date.now()
   */
  @Input() referencePoint: Date | number | string | undefined | null = new Date();
  @Input() hoursZeroes = true;

  /** timer observable wrt to reference pnt */
  timer: Observable<number> = of(0);

  ngOnChanges() {
    // treat undefined or null as we don't have a reference point, count up. note: 0 will be treated as epoch time
    const ref1 =
      // eslint-disable-next-line no-null/no-null
      this.referencePoint !== undefined && this.referencePoint !== null
        ? new Date(this.referencePoint)
        : new Date();
    if (isNaN(ref1.getTime())) {
      throw new Error("Timer Component: input must be Date object or valid ISO string");
    }
    this.timer = timer(0, 1000).pipe(
      map(() => {
        const ref2 = new Date(ref1);
        return Math.abs(Date.now() - ref2.getTime());
      })
    );
  }
}
