import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "duration",
  standalone: false
})
export class FormatDurationPipe implements PipeTransform {
  transform(msStr: any, hoursZeroes = true): any {
    // Formats milliseconds into a pretty time duration string
    const msNum: number = Math.abs(parseInt(msStr));
    if (isNaN(msNum)) return "";
    const secNum: number = msNum / 1000;
    const hours: number = Math.floor(secNum / 3600);
    const minutes: number = Math.floor((secNum - hours * 3600) / 60);
    const seconds: number = Math.floor(secNum - hours * 3600 - minutes * 60);

    const hoursString: string = (hours < 10 ? "0" : "") + hours;
    const minutesString: string = (minutes < 10 ? "0" : "") + minutes;
    const secondsString: string = (seconds < 10 ? "0" : "") + seconds;

    return hoursZeroes || hours > 0
      ? hoursString + ":" + minutesString + ":" + secondsString
      : minutesString + ":" + secondsString;
  }
}
