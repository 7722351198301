import { Injectable } from "@angular/core";

import { OAuth2Service } from "../../../services/oauth2/oauth2.service";

import { TimelineEventData } from "../hubspot-timeline-event";

import { OAuth } from "./lib/oauth";
import {
  Contact,
  contactRequestByEmail,
  contactRequestByQuery,
  contactRequestByUserToken,
  contactRequestByVistorId,
  contactFromJSON,
  contactsFromJSON
} from "./lib/contact";
import { UUID } from "../../../../../../common/libraries/sip/uuid";
import { CallItem } from "../../../helpers/call-item.helper";

@Injectable({ providedIn: "root" })
export class HubspotApiService extends OAuth {
  constructor(oAuth2: OAuth2Service) {
    super(oAuth2);
  }

  //
  // HubSpot Contact API
  //

  getContactByE164(e164: string): Promise<Contact> {
    const queries: Array<Promise<Array<Contact>>> = [];
    // full e164 number
    queries.push(
      this.fetch(contactRequestByQuery(e164), "GET")
        .then(this.checkForStatusError)
        .then(response => response.json())
        .then(this.checkForReponseError)
        .then(contactsFromJSON)
    );
    // strip leading '+'
    if (e164.length && e164[0] === "+") {
      queries.push(
        this.fetch(contactRequestByQuery(e164.slice(1)), "GET")
          .then(this.checkForStatusError)
          .then(response => response.json())
          .then(this.checkForReponseError)
          .then(contactsFromJSON)
      );
    }
    // strip leading '+1'
    if (e164.length === 12 && e164[1] === "1") {
      queries.push(
        this.fetch(contactRequestByQuery(e164.slice(2)), "GET")
          .then(this.checkForStatusError)
          .then(response => response.json())
          .then(this.checkForReponseError)
          .then(contactsFromJSON)
      );
    }
    return Promise.all(queries).then(contacts => {
      if (contacts.length > 0 && contacts[0].length) {
        return contacts[0][0];
      }
      if (contacts.length > 1 && contacts[1].length) {
        return contacts[1][0];
      }
      if (contacts.length > 2 && contacts[2].length) {
        return contacts[2][0];
      }
      throw new Error("Not valid.");
    });
  }

  getContactByEmail(email: string): Promise<Contact> {
    return this.fetch(contactRequestByEmail(email), "GET")
      .then(this.checkForStatusError)
      .then(response => response.json())
      .then(this.checkForReponseError)
      .then(contactFromJSON);
  }

  getContactByUserToken(utk: string): Promise<Contact> {
    return this.fetch(contactRequestByUserToken(utk), "GET")
      .then(this.checkForStatusError)
      .then(response => response.json())
      .then(this.checkForReponseError)
      .then(contactFromJSON);
  }

  getContactByVisitorId(vid: string): Promise<Contact> {
    return this.fetch(contactRequestByVistorId(vid), "GET")
      .then(this.checkForStatusError)
      .then(response => response.json())
      .then(this.checkForReponseError)
      .then(contactFromJSON);
  }

  getHubspotOwnerFromContact(contact: Contact): Promise<string | undefined> {
    if (!contact.hubspotOwnerId) {
      return Promise.resolve(undefined);
    }
    return this.fetch(`/crm/v3/owners/${contact.hubspotOwnerId}`, "GET")
      .then(this.checkForStatusError)
      .then(response => response.json())
      .then(this.checkForReponseError)
      .then((json: any) => {
        let name = "";
        if (json.type === "PERSON") {
          if (json.firstName && json.firstName.length > 0) {
            name += json.firstName;
          }
          if (json.lastName && json.lastName.length > 0) {
            if (name.length > 0) {
              name += " ";
            }
            name += json.lastName;
          }
          if (name.length === 0 && json.email && json.email.length > 0) {
            name = json.email;
          }
        }

        if (name.length === 0) {
          name = json.lastName;
        }

        return name;
      });
  }

  //
  // HubSpot Timeline API
  //
  addTimelineEvent(
    utk: string,
    name: string,
    callItem: CallItem,
    numSeconds: number | undefined,
    outcome: string
  ): Promise<void> {
    let durationDisplay;
    if (numSeconds) {
      const numMinutes = Math.floor(numSeconds / 60);
      if (numMinutes > 0) {
        durationDisplay =
          numMinutes +
          " minute" +
          (numMinutes === 1 ? "" : "s") +
          ", " +
          (numSeconds % 60) +
          " second" +
          (numSeconds % 60 === 1 ? "" : "s");
      } else {
        durationDisplay = numSeconds + " seconds";
      }
    } else {
      durationDisplay = "none";
    }

    const timelineEventData: TimelineEventData = {
      id: UUID.randomUUID(),
      utk,
      eventTypeId: "382713",
      extraData: {
        version_2: true,
        durationDisplay
      },
      topic: callItem.whatDisplay,
      repName: name,
      page: callItem.where,
      pageURL: callItem.link,
      outcome,
      duration: numSeconds || 0
    };

    return this.fetch(
      `/integrations/v1/183515/timeline/event`,
      `PUT`,
      JSON.stringify(timelineEventData)
    ).then(() => {});
  }

  // https://developers.hubspot.com/docs/faq/api-error-responses
  //
  // Unless otherwise specified, most HubSpot APIs will return a 200 OK response on success.
  // Any endpoints that return a different status code will specify the returned response on the individual documentation page for that endpoint.
  //
  // In addition, HubSpot has several error responses that are common to multiple APIs.
  //
  //   401 Unauthorized - Returned when the authentication provided is invalid.
  //   403 Forbidden - Returned when the authentication provided does not have the proper permissions to access the specific URL.
  //       As an example, an OAuth token that only has Content access would get a 403 when accessing the Deals API (which requires Contacts access).
  //   429 Too many requests - Returned when your portal or app is over the API rate limits.
  //       Please see this document for details on those rate limits and suggestions for working within those limits.
  //   502/504 timeouts - HubSpot has processing limits in place to prevent a single client causing degraded performance,
  //       and these responses indicate that those limits have been hit. You'll normally only see these timeout responses
  //       when making a a large number of requests over a sustained period. If you get one of these responses, you should
  //       pause your requests for a few seconds, then retry.
  //
  // Aside from those general errors, HubSpot error responses are intended to be human readable.
  // Most endpoints do not return error codes, but return a JSON formatted response with details about the error.
  //
  //   {
  //    "status": "error",
  //    "message": "This will be a human readable message with more details of the problem",
  //    "correlationId": "a2d3acb6-f78c-476e-9811-860509ae9e20",
  //    "requestId": "a43683b0-5717-4ceb-80b4-104d02915d8c"
  //   }

  private checkForStatusError(response: Response): Response {
    if (!response.ok) {
      throw new Error("Not ok.");
    }
    return response;
  }

  private checkForReponseError(json: any): Response {
    if (json.status && json.status === "error") {
      const message = json.message ? json.message : "Error";
      throw new Error(message);
    }
    return json;
  }
}
