import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  ChangeDetectionStrategy
} from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";

import { TranslateService } from "@ngx-translate/core";
import { UserService } from "@onsip/common/services/api/resources/user/user.service";

@Component({
  selector: "onsip-installation-instructions",
  templateUrl: "./cms-instructions.component.html",
  styleUrls: ["./cms-instructions.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CMSInstructionsComponent implements OnInit {
  @Input() selectedCMS = "generic";
  @Output() closeEvent = new EventEmitter<void>();
  orgId!: Observable<number>;

  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.orgId = this.userService.selfUser.pipe(map(user => parseInt(user.organizationId)));
  }

  autoCopy(event: MouseEvent): void {
    const codeEl = (
      ((event.target as HTMLElement).parentElement as HTMLElement).parentElement as HTMLElement
    ).nextElementSibling as HTMLElement;
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(codeEl.innerText);
      this.openSnackBar(this.translate.instant("ONSIP_I18N.CODE_SNIPPET_COPIED"));
    } else {
      const willCopy: HTMLTextAreaElement = document.createElement("textarea");
      const getCodeEl: (element: any) => any = element =>
        element.tagName === "code" ? element : getCodeEl(element.parentElement);
      willCopy.value = getCodeEl(codeEl).innerText;
      document.body.appendChild(willCopy);
      willCopy.select();
      // NOTE: revisit later and use clipboard API
      // eslint-disable-next-line deprecation/deprecation
      document.execCommand("copy");
      willCopy.remove();
      this.openSnackBar(this.translate.instant("ONSIP_I18N.CODE_SNIPPET_COPIED"));
    }
  }

  openSnackBar(msg: string) {
    this.snackBar.open(msg, "", {
      duration: 1500
    });
  }

  close(): void {
    this.closeEvent.emit();
  }
}
