import { Pipe, PipeTransform } from "@angular/core";

const timezoneAbbreviation = Intl.DateTimeFormat("default", {
  timeZoneName: "short"
})
  .format()
  .split(" ")
  .slice(-1)[0];

const timeFormatter = Intl.DateTimeFormat("default", {
  hour: "numeric",
  minute: "2-digit"
});

const dateFormatter = Intl.DateTimeFormat("default", {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit"
});

@Pipe({
  name: "datetime",
  standalone: false
})
export class FormatDateTimePipe implements PipeTransform {
  transform(msDate: any): any {
    // Given a time in milliseconds, formats it and then separately wraps the
    // date and time in spans with the class names from the function arguments.
    // This should be used with `data-ng-bind-html`, which will sanitize the
    // HTML and then insert it unqouted so that the markup is actually markup.
    if (!msDate) return msDate;
    // used to have arguments passed in for classes, but was unnecessary.
    // If needed, please update agentTableDirective to have a special case
    // for this filter.
    const timeSpanOpen = '<span class="report-table__val__time">';
    const dateSpanOpen = '<span class="report-table__val__date">';
    return (
      timeSpanOpen +
      timeFormatter.format(msDate).replace(/\s+/g, "").toLowerCase() +
      " " +
      timezoneAbbreviation +
      "</span>" +
      " " +
      dateSpanOpen +
      dateFormatter.format(msDate) +
      "</span>"
    );
  }
}
