import { Directive, Input, forwardRef } from "@angular/core";
import { FormControl, NG_VALIDATORS, Validator } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Directive({
  selector: "[onsipCityValidator]",
  providers: [
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => OnsipCityValidatorDirective),
      multi: true
    }
  ],
  standalone: false
})
export class OnsipCityValidatorDirective implements Validator {
  @Input() notRequired = false;

  constructor(private translate: TranslateService) {}

  public validate(control: FormControl): { [key: string]: any } | null {
    return this.validateCity(control.value);
  }

  private validateCity(city: string): Object | null {
    return !city && !this.notRequired
      ? {
          required: {
            message: this.translate.instant("ONSIP_I18N._VALUE__IS_REQUIRED", {
              value: this.translate.instant("ONSIP_I18N.CITY")
            }),
            order: 0
          }
        }
      : city.length >= 30
      ? {
          maxlength: {
            message: this.translate.instant(
              "ONSIP_I18N.CITY_NAME_IS_LONGER_THAN_30_CHARACTERS_LONG"
            ),
            order: 1
          }
        }
      : city && !/^[0-9A-Za-z '.-]+$/.test(city)
      ? {
          pattern: {
            message: this.translate.instant(
              "ONSIP_I18N.CITY_NAME_MAY_CONTAIN_ONLY_NUMBERS_OR_LETTERS"
            ),
            order: 2
          }
        }
      : // eslint-disable-next-line no-null/no-null
        null;
  }
}
