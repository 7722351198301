import { Component, ChangeDetectionStrategy, Input, OnInit } from "@angular/core";
import { ScreenShareService } from "../../screen-share.service";
import { CallControllerService } from "../../../../../common/services/call-controller.service";
import { CallState } from "../../../../../common/libraries/sip/call-state";

@Component({
  selector: "onsip-screen-share",
  templateUrl: "./screen-share.component.html",
  styleUrls: ["./screen-share.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ScreenShareComponent implements OnInit {
  @Input() callUuid: string | undefined;
  @Input() localStream: MediaStream | undefined;
  @Input() remoteStream: MediaStream | undefined;
  @Input() mutedVideo!: boolean;
  @Input() isCallListItem!: boolean;

  callToDisplayName!: string;
  callFromDisplayName!: string;

  constructor(
    public screenShareService: ScreenShareService,
    private callControllerService: CallControllerService
  ) {}

  ngOnInit(): void {
    if (!this.callUuid) {
      return;
    }

    const call: CallState | undefined = this.callControllerService.getCallStateByUuid(
      this.callUuid
    );

    if (call) {
      // get display name text for local and remote streams
      if (!this.callFromDisplayName) this.callFromDisplayName = call.remoteDisplayName;
      if (!this.callToDisplayName) this.callToDisplayName = call.localDisplayName || "";
    }
  }
}
